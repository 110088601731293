import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from '../../constants/Config';
import { registerFaceId, registerDocumentIdBack } from "../../services/ApiService";
import { AuthContext } from "../../services/AuthContext";
import { get, useForm } from "react-hook-form";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';


const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const PictureErrors = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { mystate } = React.useContext(AuthContext);
    const [disableButton, setDisableButton] = useState(false);
	const [numberOfErrors, setNumberOfErrors] = useState(0);
	const { register, handleSubmit, trigger, watch, reset, control, setValue, getValues, formState } = useForm({
		mode: "onChange"
	});
	//console.log(watch());
	watch();

	useEffect(() => {
		//console.log(getValues('front').hasOwnProperty('0'));
		setValue('front2', getValues('front').hasOwnProperty('0') ? getValues('front')[0].name : '');
		return () => { }
	}, [getValues('front')])

	useEffect(() => {
		//console.log(getValues('front').hasOwnProperty('0'));
		setValue('back2', getValues('back').hasOwnProperty('0') ? getValues('back')[0].name : '');
		return () => { }
	}, [getValues('back')])

	useEffect(() => {
		reset()
		return () => { }
	}, [])


	const changeFileName = (file) => {
		
		let blob = file.slice(0, file.size, file.type);
		let newFile = new File([blob], 'document-id-' + mystate.user.id, { type: file.type });
		return newFile
	}


	const onSubmit = async () => {
		try {
			//console.log(getValues());
			setDisableButton(true);
			let frontFile = changeFileName(getValues('front')[0]);
			let backFile = changeFileName(getValues('back')[0]);
			//changing file name

			/*console.log(getValues('front')[0]);
			console.log(frontFile);
			console.log(getValues('back')[0]);
			console.log(backFile);*/

			//adding to formdata in order to be able to send image through http request
			let frontFileData = new FormData();
			frontFileData.append('document_id_image', frontFile);
			let backFileData = new FormData();
			backFileData.append('document_id_image_back', backFile);

			let registerFaceIdResponse = await registerFaceId(mystate.user.id, frontFileData);
			let registerDocumentIdBackResponse = await registerDocumentIdBack(mystate.user.id, backFileData);
			//console.log(registerFaceIdResponse.data);
			//console.log(registerDocumentIdBackResponse.data);
			navigate('/advanced-access/face/requirements', { state: { from: 'document-id-registration' } });

		} catch (e) {
			if (numberOfErrors >= 3) {
				navigate('/advanced-access/face/incomplete-validations', { state: { from:state.from } });
			}

			if (e.response.data.key === 'DOCUMENT_ID_IMAGE_ALREADY_UPLOADED') {
                Toast.fire({
                    icon: 'error',
                    title: t('picture-errors-alert-already'),
                })
            }
			//console.log(e);
			if (e.response.data.key === 'DOCUMENT_ID_IMAGE_IS_NOT_A_DOCUMENT_ID') {
                Toast.fire({
                    icon: 'error',
                    title: t('document-id-registration-alert-document-id-image-is-not-dni'),
                })
            }

            if (e.response.data.key === 'USER_DNI_NOT_FOUND_IN_DOCUMENT_ID') {
                Toast.fire({
                    icon: 'error',
                    title: t('document-id-registration-alert-document-id-image-dont-match'),
                })
            }

			setNumberOfErrors(numberOfErrors + 1);
			//console.log(e);
		} finally {
			setDisableButton(false);
		}
		
	}

	const goBack = () => {
		navigate('/your-security-is-first');
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-6 mt-9" >

			<div className="">
				<svg xmlns="http://www.w3.org/2000/svg" onClick={() => goBack()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
				</svg>
			</div>

			<div className="mt-5 mb-14 ml-2">
				<h1 className="text-2xl font-bold ">{t('picture-errors-title')}</h1>
			</div>

			<div id="front-section" className={'mb-5'}>
				<label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="account">
					{t('picture-errors-label-front')}
				</label>

				<input
					id={'input_file'}
					defaultValue={''}
					{...register("front", {
						required: true,
						validate: {
							lessThan10MB: files => files[0]?.size < 10000000,
							acceptedFormats: files => ['image/jpeg', 'image/png'].includes(files[0]?.type)
						},
					})}
					type="file"
					className='mb-8'
					hidden />

				<div style={{ position: 'relative', height: '45px' }}>
					<input
						onClick={() => document.getElementById('input_file').click()}
						type="text"
						onKeyDown={(e) => { e.preventDefault() }}
						defaultValue={getValues('front2')}
						style={{ position: 'absolute', zIndex: 1 }}
						placeholder={t('picture-errors-label-upload-picture')}
						className={`mb-0 border-2 cursor-pointer outline-none ${formState.errors.front && 'border-red-500'}`}
						readOnly
					//style={{...styles.inputBorder}}
					/>

					<div onClick={() => document.getElementById('input_file').click()} style={{ position: 'absolute', zIndex: 2, right: 13, top: 10 }}  >
						<img
							src="/images/icon-upload.svg"
							alt="icon-upload"
							className="cursor-pointer"
						/>
					</div>
				</div>

				{formState.errors.front && !formState.isValid &&

					<span className="text-red-500 mt-0 ml-2 text-xs mb-2">
						{formState.errors.front.type === 'required' && t('picture-errors-alert-front-required')}
						{formState.errors.front.type === 'lessThan10MB' && t('picture-errors-alert-less')}
						{formState.errors.front.type === 'acceptedFormats' && t('picture-errors-alert-format')}
					</span>
				}
			</div>

			<div id="back-section" className={'mb-7'}>
				<label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="account">
					{t('picture-errors-label-back')}
				</label>

				<input
					id={'input_file2'}
					defaultValue={''}
					{...register("back", {
						required: true,
						validate: {
							lessThan10MB: files => files[0]?.size < 15000000,
							acceptedFormats: files => ['image/jpeg', 'image/png'].includes(files[0]?.type)
						},
					})}
					type="file"
					className='mb-8'
					hidden />

				<div style={{ position: 'relative', height: '45px' }}>
					<input
						onClick={() => document.getElementById('input_file2').click()}
						type="text"
						onKeyDown={(e) => { e.preventDefault() }}
						defaultValue={getValues('back2')}
						style={{ position: 'absolute', zIndex: 1 }}
						placeholder={t('picture-errors-label-upload-picture')}
						className={`mb-0 border-2 cursor-pointer outline-none ${formState.errors.back && 'border-red-500'}`}
						readOnly
					//style={{...styles.inputBorder}}
					/>

					<div onClick={() => document.getElementById('input_file2').click()} style={{ position: 'absolute', zIndex: 2, right: 13, top: 10 }}  >
						<img
							src="/images/icon-upload.svg"
							alt="icon-upload"
							className="cursor-pointer"
						/>
					</div>
				</div>

				{formState.errors.back && !formState.isValid &&

					<span className="text-red-500 mt-0 ml-2 text-xs mb-2">
						{formState.errors.back.type === 'required' && t('picture-errors-alert-back-required')}
						{formState.errors.back.type === 'lessThan10MB' && t('picture-errors-alert-less')}
						{formState.errors.back.type === 'acceptedFormats' && t('picture-errors-alert-format')}
					</span>
				}
			</div>


			<div className="mb-3">
				<p className="text-gray-500 text-xs ml-2">{t('picture-errors-label-validations-1')}</p>
			</div>

			<div className="mb-8">
				<p className="text-gray-500 text-xs ml-2">{t('picture-errors-label-validations-2')}</p>
			</div>


			<button type="submit" disabled={!formState.isValid || disableButton} className={`mb-0.5 ${(!formState.isValid || disableButton) && 'opacity-50 cursor-not-allowed'}`}>
				{t('picture-errors-button-continue')}
			</button>

		</form>
	);
}

export default PictureErrors;