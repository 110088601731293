import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getQuestions } from '../../services/ApiService';
import i18n from '../../i18n';


const InvestorQuestions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [questions, setQuestions] = useState([]);
    const [mystate, setMyState] = useState({question_number:0,points:0}); //actual question wich the user is answering
    const [inputValue, setInputValue] = useState(null);

    useEffect(() => {
        //console.log(i18n.language)

        const init = async () => {
            let ApiResponse = await getQuestions();

            ApiResponse.data.map((data) => {
                data.myquestion = i18n.language === 'es' ? data.question.es : data.question.en;
                let option = i18n.language === 'es' ? data.options_es : data.options_en;
                data.myoption = JSON.parse(option);
            })
            setQuestions(ApiResponse.data);
            //console.log(ApiResponse.data);
        }

        init();

        return () => { }
    }, [])

    /*useEffect(() => {
        console.log(inputValue)
        return () => { }
    }, [inputValue])

    useEffect(() => {
        console.log(mystate)
        return () => { }
    }, [mystate])*/

    const goNext = () => {
        
        if (mystate.question_number < questions.length-1) {
            setMyState({question_number:mystate.question_number+1,points:mystate.points+Number(inputValue)});
            setInputValue(null);
            return;
        }
        let last = {points:mystate.points+Number(inputValue)};

        navigate('/know-your-investor-profile/investor-fits-with', { state: { ...last } });
    }

    const Back = () => {
        navigate('/know-your-investor-profile')
    }

    return (
        <div className="flex flex-col px-6 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            {questions.length !== 0 &&

                <div className="mt-5 mb-24 ml-2">

                    <h1 className="text-xl font-bold mb-16 ">
                        {questions[mystate.question_number].myquestion}
                    </h1>

                    {questions[mystate.question_number].myoption.map((option, i) => {
                        return (
                            <div key={i+option.key} className='mb-5 bg-white rounded-lg p-2.5 text-gray-700'>

                                <div className='flex'>
                                    <div className='self-center'>
                                        <input
                                            name={'option'}
                                            defaultValue={option.attributes.points} 
                                            onChange={()=>setInputValue(option.attributes.points)}
                                            style={{ appearance: 'auto' }}
                                            type="radio"
                                        />
                                    </div>
                                    <div className='flex-1 ml-3 text-sm' >
                                        {option.attributes.option}
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            }

            <button onClick={() => goNext()} type="button" disabled={inputValue === null} className={` mb-0.5 ${inputValue === null && 'opacity-50 cursor-not-allowed'} ` } >
                {t('investor-questions-button-submit')}
            </button>

        </div>
    );
}

export default InvestorQuestions;