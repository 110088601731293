import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const OnboardingCongratulations = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [content, setContent] = useState({ title: 'null', image: '/images/icon-hand-success.svg' })

	useEffect(() => {

		//let state = {from: 'your-security-is-first'};

		if (state.from === 'document-id-registration') {
			setContent({ title: 'onboarding-congratulations-validate-identity-title',description:'onboarding-congratulations-description-for-document-registration', image: '/images/icon-congratulations-check.svg' })
			return;
		}

		if (state.from === 'add-bank-account') {
			setContent({ title: 'onboarding-congratulations-bank-account-title', image: '/images/icon-congratulations-check.svg' })
			return;
		}

		if (state.from === 'pep-family') {
			setContent({ title: 'onboarding-congratulations-additional-information-title', image: '/images/icon-congratulations-check.svg' })
			return;
		}

		/*if(state.from === 'validate-pep-participation' || state.from === 'pep-participations'){
			setContent({title:'onboarding-congratulations-additional-information-title',image:'/images/icon-congratulations-check.svg'})
			return;
		}*/

		if (state.from === 'pep-participations') {
			setContent({ title: 'onboarding-congratulations-additional-information-title', image: '/images/icon-congratulations-check.svg' })
			return;
		}

		if (state.from === 'pep') {
			setContent({ title: 'onboarding-congratulations-additional-information-title', image: '/images/icon-congratulations-check.svg' })
			return;
		}

		if (state.from === 'do-you-have-related-pep') {
			setContent({ title: 'onboarding-congratulations-additional-information-title', image: '/images/icon-congratulations-check.svg' })
			return;
		}

		if (state.from === 'your-security-is-first') {
			setContent({ title: 'onboarding-congratulations-all-steps-completed-title', image: '/images/icon-all-steps-completed.svg' })
			return;
		}

		if (state.from === 'subscription') {

			return;
		}

		if (state.from === 'withdrawal') {

			return;
		}

		return () => { }
	}, [])

	const goNext = () => {

		//let state = {from: 'your-security-is-first'};

		if (state.from === 'your-security-is-first') {
			navigate('/know-your-investor-profile', { state: { ...state } });
			return;
		}

		/*if (state.from === 'validate-pep-participation' || state.from === 'pep-participations') {
			navigate('/onboarding/additional-information/family-of-political-exposed-person', { state: { ...state } });
			return;
		}*/

		if (state.from === 'pep-participations') {
			navigate('/your-security-is-first', { state: { ...state } });
			return;
		}

		if (state.from === 'pep') {
			navigate('/your-security-is-first');
			return;
		}

		if (state.from === 'do-you-have-related-pep') {
			navigate('/your-security-is-first');
			return;
		}

		if (state.from === 'subscription') {
			navigate('/operations/subscription/new', { state: { ...state } });
			return;
		}

		if (state.from === 'withdrawal') {
			navigate('/operations/withdrawal/type', { state: { ...state } });
			return;
		}

		navigate('/your-security-is-first', { state: { ...state } });

	}

	return (
		<div className="flex flex-col px-6 mt-9" >

			<div className="mt-11 mb-5 ml-2">
				<h1 className="text-3xl font-bold ">{t(content.title)}</h1>
			</div>

			<div className="mb-16">
				{state.from === 'document-id-registration' &&
                	<p className="text-gray-500 text-sm ml-2">{t(content.description)}</p>
				}
			</div>

			<div className="mb-28 self-center">
				<img
					src={content.image}
					alt="icon-hand-success"
					className="object-contain h-36 w-full"
				/>
			</div>


			<button onClick={() => goNext()} type="button" >
				{t('onboarding-congratulations-button')}
			</button>


		</div>
	);
}

export default OnboardingCongratulations;