import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { getFunds } from '../../services/ApiService';
import Footer from '../home/Footer';

const KnowAllOurFunds = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [funds, setFunds] = useState([])
    const { from } = useParams();

    useEffect(() => {

        const init = async () => {
            let ApiResponse = await getFunds();
            setFunds(ApiResponse.data);
            //console.log(ApiResponse.data);
        }

        init();

        return () => { }
    }, [])

    const goToFund = (id) => {
        navigate('/fund-information', { state: {id:id} });
    }

    const Back = () => {
        navigate(from === 'home' ? '/home' : '/know-your-investor-profile/investor-recommended-funds', { state: { ...state } })
    }

    return (
        <div className="flex flex-col" >
            <div className="flex flex-col px-6 mt-9" >

                <div className="">
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </div>

                <div className="mt-5 mb-6 ml-2">
                    <h1 className="text-xl font-bold ">{t('know-all-our-funds-title')}</h1>
                </div>

                {funds.map((fund, i) => {
                    return (
                        <div key={i} className='mb-5 bg-white rounded-lg py-4 px-6'>

                            <div className='font-semibold text-base'>
                                {i18n.language === 'es' ? fund.name.es : fund.name.en}
                            </div>

                            <button onClick={() => goToFund(fund.id)}
                                type='button'
                                className="ml-0 pl-0 underline pb-1 text-left bg-transparent text-asparagus-500 text-base  hover:bg-transparent">
                                {t('investor-recommended-funds-button-more-details')}
                            </button>
                        </div>
                    )
                })}

            </div>
            <div className='mt-20'>
                {from === 'home' &&
                    <Footer />

                }
            </div>

        </div>
    );
}

export default KnowAllOurFunds;