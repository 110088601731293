import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import { getInvestorPEPCompany, storeInvestorPEPCompany, deleteInvestorPEPCompany, getInvestorPepCompanyDocumentTypes } from '../../../services/ApiService'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../i18n';


const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const InformationPEPParticipation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [business, setBusiness] = useState([]);
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [disableButton, setDisableButton] = useState({ add: false, delete: false });
    const [documentType, setDocumentType] = useState({ RUC: 'RUC' });
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    //console.log(watch())

    useEffect(() => {

        const init = async () => {
            //console.log(mystate)

            let ApiResponse2 = await getInvestorPepCompanyDocumentTypes(i18n.language === 'es' ? 'es' : 'en');
            //console.log(ApiResponse2.data)
            setDocumentType(ApiResponse2.data)
            let ApiResponse = await getInvestorPEPCompany(mystate.user.investor_id, mystate.pep_id);
            Object.keys(ApiResponse.data).length !== 0 && setBusiness(ApiResponse.data)
        }

        init();
        return () => { }
    }, [])

    const onSubmit = async () => {
        try {
            setDisableButton({ ...disableButton, add: true });
            let data = {
                name: getValues('name'),
                document_type: getValues('document'),
                document_number: getValues('document_number'),
            }

            let ApiResponse = await storeInvestorPEPCompany(mystate.user.investor_id, mystate.pep_id, data)
            //console.log(ApiResponse.data);
            //console.log(business)
            let new_business = business.length === 0 ? [{ ...ApiResponse.data }] : [...business, { ...ApiResponse.data }];
            //console.log(new_business)
            setBusiness(new_business);
        } catch (error) {
            //console.log(error);
        }
        setDisableButton({ ...disableButton, add: false });
        reset();
    }


    const deleteBusiness = async (i, id) => {
        if (disableButton.delete) {
            return;
        }

        try {
            setDisableButton({ ...disableButton, delete: true });

            let ApiResponse = await deleteInvestorPEPCompany(mystate.user.investor_id, mystate.pep_id, id)
            //console.log(ApiResponse.data);

            let new_business_array = [...business];
            new_business_array.splice(i, 1);
            setBusiness(new_business_array);

            setDisableButton({ ...disableButton, delete: false });
        } catch (error) {

        }

    }

    const goNext = () => {
        navigate('/onboarding/additional-information/political-exposed-person/do-you-have-related-pep', { state: { from: 'pep-participations' } });
    }

    const Back = () => {
        navigate('/your-security-is-first')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('pep-information-participations-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('pep-information-participations-description-label')}</p>
            </div>

            <div className='card-information-pep-participation-section flex flex-col mb-32'>

                <div id="business-name-section" className={'mb-5 flex-1'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="business-name">
                        {t('pep-information-participations-input-business-name-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('pep-information-participations-input-business-name-placeholder')}
                        defaultValue=''
                        {...register("name", { required: true })}
                        className={`mb-0 border-2 ${formState.errors.name && 'border-red-500'}`}
                    />
                    {formState.errors.name && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.name.type === 'required' && t('pep-information-participations-alert-business-name-required')}
                        </span>
                    }
                </div>


                <div id="register-dni-data-document-section" className={'mb-5'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="document">
                        {t('register-dni-data-input-document-label')}
                    </label>
                    <div className='flex'>
                        <div className='flex-1 mr-1'>
                            <select {...register("document")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                                {Object.entries(documentType).map((item, i) => {
                                    return(
                                        <option key={i} className="mt-3" value={item[0]}>{item[1]}</option>
                                    ) 
                                })}
                            </select>
                        </div>

                        <div className='flex-1'>
                            <input
                                type="text"
                                defaultValue=''
                                name="document_number"
                                {...register("document_number", { required: true })}
                                className={` border-2 ${formState.errors.document_number && 'border-red-500'}`}
                                placeholder={t('register-dni-data-input-document-input')}
                            />
                        </div>

                    </div>

                    {formState.errors.document_number && !formState.isValid &&
                        <span style={{ marginLeft: '51%' }} className=" text-red-500 mt-0 text-xs" htmlFor="document">
                            {formState.errors.document_number.type === 'required' && t('register-dni-data-alert-document-required')}
                        </span>
                    }

                </div>

                <button type="submit" disabled={!formState.isValid || disableButton.add} className={`my-10 information-pep-save-business-button self-end mt-5 w-1/2 ${(!formState.isValid || disableButton.add) && 'opacity-50 cursor-not-allowed'}`} >
                    {t('pep-information-participations-button-save-business')}
                </button>
            </div>


            <div className="flex justify-center mb-8">
                <div className='flex-1  self-center pr-2'>
                    <hr />
                </div>

                <div className='flex-1'>
                    <div className='flex justify-center'>
                        {t('pep-information-participations-label-business')}
                    </div>
                </div>

                <div className='flex-1 self-center pl-2'>
                    <hr />
                </div>

            </div>

            {business.length > 0 &&
                <div className='mb-3'>
                    {business.map((item, i) => {
                        return (
                            <div key={i} className="flex mb-3 pep-information-business-shadow bg-white rounded-xl py-2 px-3 ">
                                <div className='flex-1'>
                                    <div className='mb-2'> {item.name}</div>
                                    <div className='text-gray-400 text-sm'>{item.document_type} - {item.document_number}</div>
                                </div>
                                <div className='flex-1'>
                                    <div className='flex h-full justify-end items-center'>
                                        <div className={`cursor-pointer ${disableButton.delete && 'opacity-50 cursor-not-allowed'}`} onClick={() => deleteBusiness(i, item.id)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }

            <button disabled={business.length === 0} type="button" onClick={() => goNext()} className={`my-10 ${business.length === 0 && 'opacity-50 cursor-not-allowed'}`} >
                {t('pep-information-participations-submit-button')}
            </button>

        </form>
    );
}

export default InformationPEPParticipation;