import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import { updateInvestor, getMaritalStatuses, getCountries, patchInvestor, getGender } from '../../../services/ApiService'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../i18n';
import { Datepicker } from '@themesberg/tailwind-datepicker';
import { init } from 'i18next';
import useWindowDimensions from '../../../useWindowDimensions';

const MySwal = withReactContent(Swal)
//const regex = /(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/

//example 1989-10-20
//const regex = /^(?:(?:1[6-9]|[2-9]\d)?\d{2})(?:(?:(-)(?:0?[13578]|1[02])\1(?:31))|(?:(-)(?:0?[13-9]|1[0-2])\2(?:29|30)))$|^(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(-)0?2\3(?:29)$|^(?:(?:1[6-9]|[2-9]\d)?\d{2})(-)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:0?[1-9]|1\d|2[0-8])$/

//example 20-10-1989
const regex = /^(?:(?:31(-)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(-)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(-)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(-)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/

//example 20/10/1989
//const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/   

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})


const PersonalData = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const { height, width } = useWindowDimensions();
    const [staticData, setStaticData] = useState({ marital: null, countries: null, gender: null });
    const [isInitializing, setIsInitializing] = useState(true);
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    //const [flagForBirthdate, setFlagForBirthdate] = useState({input_clicked:false,date_changed:true})
    watch()
    //console.log(watch())
    /*useEffect(() => {

        //console.log(width);
       // console.log(height);

        return () => { }
    }, [width])*/

    const onSubmit = async () => {
        try {
            //console.log(getValues())
            let data = {
                //birth_date: getValues('birthdate'),
                country_of_birth: getValues('nationality'),
                marital_status: getValues('civil_state'),
                gender: getValues('gender')
            }
            //console.log(data)
            let ApiResponse = await patchInvestor(mystate.user.id, mystate.user.investor_id, data)
            //console.log(ApiResponse.data);

            /*Toast.fire({
                icon: 'success',
                title: t('personal-alert-success-submit'),
                willClose: () => {
                    navigate('/onboarding/additional-information/residence-data');
                }
            })*/

            navigate('/onboarding/additional-information/residence-data');

        } catch (error) {

            Toast.fire({
                icon: 'error',
                title: t('personal-alert-error-submit'),
            })
        }

    }

    /*useEffect(() => {

        if (getValues('birthdate') === '' || getValues('birthdate') === null) {
            return;
        }
        console.log('entre aqui');
        if(flagForBirthdate.input_clicked){
            setFlagForBirthdate(false)
        }

        let splitted_text = watch('birthdate').split('-')
        let text = splitted_text[2] + '-' + splitted_text[1] + '-' + splitted_text[0];

        document.getElementById("datepickerId2").value = text;
        return () => {
        }
    }, [watch('birthdate')]);*/

    useEffect(() => {

        const init = async () => {
            //const datepickerEl = document.getElementById('datepickerId')
            //console.log(datepickerEl)
            //datepickerEl.click();
            // console.log(new Date().getFullYear());

            /*new Datepicker(datepickerEl, {
                // options
                format: 'mm-dd-yyyy',
                dateDelimiter:'01-01-1900,31-01-1900',
                maxDate: '31-12-'+new Date().getFullYear()-18,
                minDate: '01-01-1900'
            });*/

            let ApiResponse = await getMaritalStatuses(i18n.language === 'es' ? 'es' : 'en');
            //setValue('gender', true);
            //console.log(ApiResponse.data)
            Object.entries(ApiResponse.data).map((item, i) => {
                if (i === 0) {
                    //console.log(item[0])
                    setValue('civil_state', item[0]);
                }
            })

            let ApiResponse2 = await getCountries();
            //to match the data bank of china and place it in the first argument
            let data_to_shift_index = ApiResponse2.data.findIndex(element => element.code === 'PE');
            let initial_data = ApiResponse2.data[0];
            ApiResponse2.data[0] = ApiResponse2.data[data_to_shift_index];
            ApiResponse2.data[data_to_shift_index] = initial_data;

            setValue('nationality', ApiResponse2.data[0].id);

            let ApiResponse3 = await getGender(i18n.language === 'es' ? 'es' : 'en');
            //console.log(ApiResponse3.data)
            setStaticData({ marital: ApiResponse.data, countries: ApiResponse2.data, gender: ApiResponse3.data });
            setIsInitializing(false);
        }

        init();

        return () => { }
    }, [])

    const Back = () => {
        navigate('/your-security-is-first')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('personal-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('personal-description-label')}</p>
            </div>



             {/*<div id="birth-date-section" className={'mb-3'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="birthdate">
                    {t('personal-input-birth-date-label')}
                </label>

                <div style={{ position: 'relative', height: '45px' }}>

                    <input
                        style={{ position: 'absolute', zIndex: 1 }}
                        type="text"
                        id="datepickerId2"
                        onKeyDown={(e) => { e.preventDefault() }}
                        defaultValue={''}
                        className='focus:outline-none rounded-2xl border w-full py-3.5 px-3 text-gray-700 border-gray-300 leading-tight text-xs'
                    />

                    <div style={{ position: 'absolute', zIndex: 2, right: 12, top: 10 }}  >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                    </div>

                    this input is invisible over the first one
                    <input
                        onKeyDown={(e) => { e.preventDefault() }}
                        //onClick={()=>setFlagForBirthdate({...flagForBirthdate,input_clicked:true})}
                        type="date"
                        id="datepickerId"
                        style={{ zIndex: 3, position: 'absolute', top: 0, left: '-12px', right: 0, width: width > 705 ? width < 1024 ? '103%' : '118%' : '105%' }}
                        //placeholder={t('personal-input-birth-date-placeholder')}
                        //defaultValue={(new Date().getFullYear() - 18) + "-01-01"}
                        defaultValue={''}
                        min='1900-01-01'
                        max={(new Date().getFullYear() - 18) + "-12-31"}
                        className='focus:outline-none opacity-0 bg-blue-500 rounded-2xl border w-full py-3 px-3'
                        {...register("birthdate", { required: true })}
                    />

                </div>

                {/**
                <input
                    type="text"
                    id="datepickerId"
                    placeholder={t('personal-input-birth-date-placeholder')}
                    defaultValue=''
                    {...register("birthdate", { required: true, pattern: regex })}
                />

                <input
                    type="text"
                    placeholder={t('personal-input-birth-date-placeholder')}
                    defaultValue=''
                    {...register("birthdate", { required: true, pattern: regex })}
                    className={`mb-0 border-2 ${formState.errors.birthdate && 'border-red-500'}`}
                />
                {formState.errors.birthdate && !formState.isValid &&
                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                        {formState.errors.birthdate.type === 'required' && t('personal-alert-birth-date-required')}
                        {formState.errors.birthdate.type === 'pattern' && t('personal-alert-birth-date-pattern')}
                    </span>
                }
            </div>*/}

            <div id="nationality-section" className={'mb-3'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="nationality">
                    {t('personal-input-nationality-label')}
                </label>
                <div className='flex'>
                    <div className='flex-1 mr-1'>
                        {<select {...register("nationality")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                            {staticData.countries !== null &&
                                <>
                                    {staticData.countries.map((item, i) => {
                                        return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                    })}
                                </>
                            }

                        </select>}
                    </div>
                </div>
            </div>

            <div id="civil-state-section" className={'mb-12'}>

                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="civil_state">
                    {t('personal-input-civil-state-label')}
                </label>
                <div className='flex'>
                    <div className='flex-1 mr-1'>
                        {<select {...register("civil_state")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">

                            {staticData.marital !== null &&
                                Object.entries(staticData.marital).map((item, i) => {
                                    return (<option key={i} className="mt-3" value={item[0]}>{item[1]}</option>)
                                })
                            }
                        </select>}
                    </div>
                </div>
            </div>


            <div id="gender-section" className='mb-16 flex flex-col'>
                <div className='flex'>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="civil_state">
                        {t('personal-input-gender-label')}
                    </label>
                </div>
                {staticData.gender !== null &&
                    Object.entries(staticData.gender).map((item, i) => {
                        return (
                            <div key={i} className="flex mb-3 ml-1.5">
                                <input style={{ appearance: 'auto' }}
                                    {...register("gender", { required: true })}
                                    defaultValue={item[0]}
                                    className={`border-2  w-5 h-5  ${formState.errors.gender && 'border-red-500'}`}
                                    type="radio"
                                />
                                <label className="ml-2 text-xs self-center" htmlFor="gender">
                                    {item[1]}
                                </label>
                            </div>
                        )
                    })
                }

            </div>

            <button disabled={(!formState.isValid || isInitializing)} type="submit" className={` mb-10 ${(!formState.isValid || isInitializing) && 'opacity-50 cursor-not-allowed'}`} >
                {t('personal-button')}
            </button>



        </form>
    );
}

export default PersonalData;