import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CostEffectiveness = ({ fund }) => {
    const { t } = useTranslation();
    const [terms, setTerms] = useState([]);

    useEffect(() => {
        //console.log(fund.profitability_description);

        setTerms(fund)

        return () => { }
    }, [])

    const goToLink = (url) => {
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
        //navigate('/subscription/new', { state: { ...state } });
    };

    return (
        <div className="flex flex-col">

            {fund.links.map((item, index) => {
                return (
                    <h2 key={index} onClick={() => goToLink(item.attributes.url)} className="text-black font-normal text-base underline mt-5 cursor-pointer">
                        {item.attributes.linkText}
                    </h2>
                )
            })}

            <div className="p-4 bg-white rounded-lg mt-8 mb-20 shadow-xl">
                {fund.terms.map((item, i) => {
                    return (
                        <div key={i} >
                            <h3 className="my-3 text-gray-700">{item.attributes.term}</h3>
                            {(i !== fund.terms.length-1) &&
                                <hr style={{borderTop: '1px solid #ffffff'}} className="m-0 w-full border-2" />
                            }
                        </div>

                    )
                })}

            </div>


        </div>

    );
}

export default CostEffectiveness;