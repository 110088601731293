import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordRequest } from '../../services/ApiService';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { init } from 'i18next';

const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const MySwal = withReactContent(Swal)
const Toast = MySwal.mixin({
	toast: true,
	position: 'bottom',
	showConfirmButton: false,
	timer: 2500,
	timerProgressBar: true,
	width: '90%',
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})

const ForgotPassword = () => {
	const { t } = useTranslation();
	const [emailSended, setEmailSended] = useState('');
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { register, handleSubmit, setValue, getValues, trigger, formState } = useForm({
		mode: "onChange"
	});

	useEffect(() => {
		const init = async () => {
			/*console.log(searchParams);
			console.log(searchParams.get('email'));*/
			setValue('email', searchParams.get('email'))
			const result = await trigger("email");
		}
		init();

		return () => { }
	}, [])

	const changePassword = () => {
		navigate('/reset-password', { state: { email: getValues('email') } });
	}

	const onSubmit = async () => {
		try {
			let ApiResponse = await ForgotPasswordRequest({ email: getValues('email') })
			//console.log(ApiResponse);
			setEmailSended(getValues('email'));

			/*Toast.fire({
				icon: 'success',
				title: t('forgot-password-email-alert-email-sended-success'),
			})*/

		} catch (error) {

			Toast.fire({
				icon: 'error',
				title: t('forgot-password-email-alert-email-sended-error'),
			})
		}

	}

	const Back = () => {
		navigate('/login')
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-7 mt-10" >

			<div className="mt-9 mb-5">
				<img
					src="/images/flip-logo-color.svg"
					alt="logo"
					className="object-contain h-32 w-full"
				/>
			</div>

			<div className="self-center mb-6">
				<h1 className="text-xl font-bold">{t('forgot-password-label-password-recovery')}</h1>
			</div>

			<>
				{emailSended === '' ?
					<>
						<div className="mb-9">
							<p className="text-gray-500 text-sm ml-2">{t('forgot-password-label-we-send')}</p>
						</div>
						<div className="mb-16">
							<label className="block ml-2 text-gray-500 text-sm mb-3" htmlFor="email">
								{t('forgot-password-email-input')}
							</label>
							<input
								type="text"
								{...register("email", {
									required: true, pattern: regex,
								})}
								className={`border-2 ${formState.errors.email && 'border-red-500'}`}
								placeholder={t('forgot-password-email-placeholder')}
							//style={{...styles.inputBorder}}
							/>
							{formState.errors.email && !formState.isValid &&
								<span className="text-red-500 mt-0 ml-2 text-xs mb-2">
									{formState.errors.email.type === 'required' && t('register-alert-required-email')}
									{formState.errors.email.type === 'pattern' && t('register-input-email-alert')}
								</span>
							}
						</div>
						<button disabled={!formState.isValid} type="submit" className={formState.isValid ? ' mb-0.5' : 'opacity-50 cursor-not-allowed mb-0.5'}>
							{t('forgot-password-send-email-button')}
						</button>
					</>
					:
					<>
						<div className="mb-9">
							<p className="text-gray-500 text-sm ml-2">{t('forgot-password-label-we-send-two')}</p>
						</div>
						<div className="mb-9 self-center">
							<h2 className="text-xl text-sherpaBlue-500">{emailSended}</h2>
						</div>
						<div className="mb-10">
							<p className="text-gray-500 text-sm ml-2">{t('forgot-password-label-if-you-not')}</p>
						</div>
						<button onClick={() => changePassword()} type="button" >{/* className={!validations.inputEmail && 'opacity-50 cursor-not-allowed mb-0.5'}*/}
							{t('forgot-password-change-password-button')}
						</button>
					</>

				}
			</>

			{emailSended === '' &&
				<button onClick={() => Back()} className="bg-whiteLilac-500 text-asparagus-500  hover:bg-transparent text-sm font-thin">
					{t('forgot-password-back-button')}
				</button>
			}

		</form>
	);
}

export default ForgotPassword;