import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const GoToPepInformation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });

    const onSubmit = async () => {

        navigate('/onboarding/additional-information/political-exposed-person/pep-information');

    }

    const Back = () => {
        navigate('/your-security-is-first')
    }

    const completeLater = () => {
        navigate('/your-security-is-first')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mb-5 ml-2">
                <h1 className="text-3xl font-bold ">{t('go-to-pep-information-title')}</h1>
            </div>

            <div id="suggest-section" className="suggest flex mb-8">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <label className="block ml-2 mt-1 flex-1">
                        {t('go-to-pep-information-suggest-label')}
                    </label>
            </div>

            <div className="mb-32">
                <p className="text-gray-500 text-sm ml-2">{t('go-to-pep-information-description-label')}</p>
            </div>

            <button disabled={!formState.isValid} type="submit" className={`mb-0.5`} >
                {t('go-to-pep-information-button')}
            </button>
{/** 
			<button onClick={() => completeLater()} type='button' className="bg-whiteLilac-500 text-asparagus-500  hover:bg-transparent">
				{t('go-to-pep-information-label-complete-later')}
			</button>*/}

        </form>
    );
}

export default GoToPepInformation;