import React, { useState, useEffect,useImperativeHandle } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import { getBalances } from '../../services/ApiService';

const Footer = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [balance, setBalance] = useState({value:[],was_initialized:false});
    const [active, setActive] = useState({ home: false, movement: false, operation: false, account: false });
    const location = useLocation();

    useImperativeHandle(ref, () => ({

        goToOperationsFromHome() {
            goToOperations();
        }
    
    }));

    useEffect(() => {

        const init = async () => {
            try {
                let getBalancesResponse = await getBalances(mystate.user.investor_id);
                //console.log(getBalancesResponse.data)
                setBalance({value:getBalancesResponse.data,was_initialized:true});
            } catch (error) {
                //console.log(error)
            }
        }

        init();
        return () => { }

    }, [])

    useEffect(() => {

        if (location.pathname === '/home') {
            setActive({ ...active, home: true });
            return;
        }

        if (location.pathname === '/operations') {
            setActive({ ...active, operation: true });
            return;
        }

        if (location.pathname === '/home/investor-recommended-funds/know-all-our-funds') {
            setActive({ ...active, operation: true });
            return;
        }

        if (location.pathname === '/movements/history') {
            setActive({ ...active, movement: true });
            return;
        }

        if (location.pathname === '/account') {
            setActive({ ...active, account: true });
            return;
        }

        return () => { }

    }, [location.pathname])



    const goToMovements = () => {
        navigate('/movements/history', { state: { ...state } });
    };

    const goToHome = () => {
        setActive({ home: true, account: false, movementes: false, operations: false })
        navigate('/home', { state: { ...state } });
    };

    const goToFundInformation = () => {
        navigate('/fund-information', { state: { ...state } });
    };


    const goToOperations = () => {
        if (!balance.was_initialized) {
            return;
        }

        if (balance.value.length === 0) {
            navigate('/home/investor-recommended-funds/know-all-our-funds', { state: balance.value });
            return;
        }

        if (balance.value.length === 1) {
            navigate('/operations/investment-summary', { state: balance.value[0] });
            return;
        }

        if (balance.value.length > 1) {
            navigate('/operations', { state: balance.value });
            return;
        }
    };

    const goToAccount = () => {
        navigate('/account', { state: { ...state } });
    };

    return (

        <footer className="flex bg-white text-xl text-gray-400 text-center fixed bottom-0 px-5 justify-between w-full lg:w-2/3">

            <div className="flex flex-col pt-2 pb-3  cursor-pointer" onClick={goToHome}>

                <img className={`${active.home && 'footer-item-active'} object-contain h-6 self-center my-2`} src="/images/icon-home.svg" />
                <h5 className={`${active.home ? 'home-tab-menu-item-active' : 'home-tab-menu-item'} text-sm`}> {t('home-home')} </h5>
            </div>

            <div className="flex flex-col pt-2 pb-3 cursor-pointer" onClick={goToMovements}>
                <img className={`${active.movement && 'footer-item-active'} object-contain h-6 self-center my-2`} src="/images/icon-transactions.svg" />
                <h5 className={`${active.movement ? 'home-tab-menu-item-active' : 'home-tab-menu-item'} text-sm`}> {t('home-movements')} </h5>
            </div>

            <div className="flex flex-col pt-2 pb-3 cursor-pointer" onClick={goToOperations}>
                <img className={`${active.operation && 'footer-item-active'} ${!balance.was_initialized && 'opacity-50'} object-contain h-6 self-center my-2`} src="/images/icon-operations.svg" />
                <h5 className={`${active.operation ? 'home-tab-menu-item-active' : 'home-tab-menu-item'}  ${!balance.was_initialized && 'opacity-50'} text-sm`}> {t('home-operations')} </h5>
            </div>

            <div className="flex flex-col pt-2 pb-3 cursor-pointer" onClick={goToAccount}>
                <img className={`${active.account && 'footer-item-active'} object-contain h-6 self-center my-2`} src="/images/icon-account.svg" />
                <h5 className={`${active.account ? 'home-tab-menu-item-active' : 'home-tab-menu-item'} text-sm`}> {t('home-account')} </h5>
            </div>
        </footer>

    );
})

export default Footer;