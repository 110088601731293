import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import { getInvestorPEPInformation, storeInvestorPEPInformation, updateInvestor, showInvestor } from '../../../services/ApiService'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../i18n';

const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const PoliticalExposedPerson = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate, dispatch } = React.useContext(AuthContext);
    /*     const [isChecked, setisChecked] = useState(false)
        const radioFalse = useRef(null)
        const radioTrue = useRef(null) */
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    watch()

    useEffect(() => {
        const init = async () => {
            /*radioFalse.current.checked = true;
            console.log(radioTrue);*/
            let show_investor_response = await showInvestor(mystate.user.id, mystate.user.investor_id);
            //console.log(show_investor_response.data);
            setValue('option', show_investor_response.data.has_pep_relative);
            //setisChecked(show_investor_response.data.has_pep_relative);
            //radioFalse.current.checked = true;
            /*if(show_investor_response.data.has_pep_relative){
                radioTrue.current.checked = true;       
            }else{
                radioFalse.current.checked = true;
            }*/

            // reset()
        }
        init();

        return () => { }
    }, [])

    const onSubmit = async () => {
        try {
            let radio_value = getValues('option') === 'true' ? true : false
            //console.log('radio',radio_value)
            let data = {
                is_pep: radio_value,
            }

            let ApiResponse = await updateInvestor(mystate.user.id, mystate.user.investor_id, data);
            //console.log(ApiResponse.data)

            if (!radio_value) {
                let data2 = {
                    job: '-',
                    institution: '-',
                    has_25_percent_of_a_company: false
                }

                let ApiResponse2 = await storeInvestorPEPInformation(mystate.user.investor_id, data2);
                //console.log(ApiResponse2.data);

                let GetInvestorPepInformationResponse = await getInvestorPEPInformation(mystate.user.investor_id);
                //console.log(GetInvestorPepInformationResponse.data)

                dispatch({
                    type: 'UPDATE_PEP_ID',
                    id: Object.keys(GetInvestorPepInformationResponse.data).length === 0 ? null : GetInvestorPepInformationResponse.data.id,
                });
            }


            /*Toast.fire({
                icon: 'success',
                title: t('political-exposed-person-alert-success-submit'),
                willClose: () => {
                    navigate(radio_value ? '/onboarding/additional-information/political-exposed-person/go-to-pep-information'
                    :'/onboarding/additional-information/political-exposed-person/go-to-pep-family', { state: {from:'pep'} });
                }
            })*/

            navigate(radio_value ? '/onboarding/additional-information/political-exposed-person/go-to-pep-information'
                : '/onboarding/additional-information/political-exposed-person/do-you-have-related-pep', { state: { from: 'pep' } });

        } catch (error) {

            Toast.fire({
                icon: 'error',
                title: t('political-exposed-person-alert-error-submit'),
            })
        }

    }


    const Back = () => {
        navigate('/your-security-is-first')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('political-exposed-person-title')}</h1>
            </div>

            <div id="political-exposed-person-suggest-section" className="suggest flex my-2 mb-10">
                <svg className="h-5 w-5 text-ming-400 " viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 7H11V5H9V7ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z" fill="#0D4C57" />
                </svg>
                <label className="block ml-2 mb-2 flex-1">
                    {t('political-exposed-person-label-suggest')}
                </label>
            </div>

            <div id="answer-section" className='mb-16 flex flex-col'>

                <div className="flex mb-3 ml-1.5">
                    <input style={{ appearance: 'auto' }}
                        {...register("option", { required: true })}
                        defaultValue={false}
                        className={`mb-0 border-2 w-5 h-5`}
                        type="radio"

                    />
                    <label className="ml-2 text-xs self-center" htmlFor="option">
                        {t('political-exposed-person-input-option-radio-no')}
                    </label>
                </div>

                <div className="flex ml-1.5">
                    <input style={{ appearance: 'auto' }}
                        {...register("option", { required: true })}
                        defaultValue={true}
                        className={`border-2  w-5 h-5  ${formState.errors.option && 'border-red-500'}`}
                        type="radio"
                    />
                    <label className="ml-2 text-xs self-center" htmlFor="option">
                        {t('political-exposed-person-input-option-radio-yes')}
                    </label>
                </div>

            </div>

            <button disabled={!formState.isValid} type="submit" className={formState.isValid ? ' mb-10' : 'opacity-50 cursor-not-allowed mb-10'} >
                {t('political-exposed-person-button')}
            </button>



        </form>
    );
}

export default PoliticalExposedPerson;