import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    checkFaceAdvancedAccess, getPeruvianBanks, getInvestorBankAccount, getBankLogo, getTerms,
    storeInvestorTransaction, getTransactionMinimunAmount, getContactInformation, getBalances
} from '../../services/ApiService';
import { AuthContext } from "../../services/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useForm } from "react-hook-form";
import Modal from '../tailwind-components/Modal';
import i18n from '../../i18n';

const MySwal = withReactContent(Swal)
const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const NewWithdrawal = () => {
    const { t } = useTranslation();

    const { withdrawalType } = useParams();
    const [screenMode, setScreenMode] = useState('EDIT');
    const [bankAccount, setBankAccount] = useState(null);
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate, dispatch } = React.useContext(AuthContext);
    const [minimunTransactionValue, setMinimunTransactionValue] = useState(0);
    const [contact_information, setContactInformation] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [myBalance, setMyBalance] = useState(0);
    const [my_logo, setMyLogo] = useState(null);
    const { register, handleSubmit, trigger, watch, reset, control, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });

    //console.log(watch())

    useEffect(() => {
        //console.log(state)


        const init = async () => {

            let getBalancesResponse = await getBalances(mystate.user.investor_id);
            //console.log(getBalancesResponse.data)
            let my_balance = getBalancesResponse.data.find(element => element.fund_id === state.fund_id);
            //console.log(my_balance)
            let getPeruvianBanksResponse = await getPeruvianBanks();
            //console.log(getPeruvianBanksResponse.data);

            let getContactInformationResponse = await getContactInformation(i18n.language === 'es' ? 'es' : 'en');
            //console.log(getContactInformationResponse.data)
            setContactInformation(getContactInformationResponse.data);

            let getTransactionMinimunAmountResponse = await getTransactionMinimunAmount();
            setMinimunTransactionValue(getTransactionMinimunAmountResponse.data)

            let getInvestorBankAccountResponse = await getInvestorBankAccount(mystate.user.investor_id);
            getInvestorBankAccountResponse.data.map((item) => {

                let my_bank = getPeruvianBanksResponse.data.find(element => element.id === item.peruvian_bank_id);
                item.my_bank = i18n.language === 'es' ? my_bank.name.es : my_bank.name.en;
            })

            let getTermsResponse = await getTerms(i18n.language === 'es' ? 'es' : 'en');

            //console.log(getInvestorBankAccountResponse.data);
            setMyBalance(my_balance.current_balance);
            setModalData(getTermsResponse.data);
            setBankAccount(getInvestorBankAccountResponse.data);

            if (state.hasOwnProperty('from')) {
                if (state.from === "withdrawal") {

                    setValue('amount', state.amount);
                    onSubmit(state);
                    changeScreenMode({ bank: state.bank_account, bankAccount: getInvestorBankAccountResponse.data, from: 'init' })
                }
            } else {
                setValue('amount', 0);
            }
        }

        init();
        return () => { }
    }, [])

    const getImage = async (logo) => {
        let new_logo = await getBankLogo(logo);
        //console.log(new_logo)
        //console.log(new_logo.data.url)
        return new_logo.data.url;
    }

    const goBack = () => {
        navigate('/home', { state: { ...state } });
    };

    const changeScreenMode = async (data) => {
        //console.log(data)

        let logo = data.bankAccount.find(element => element.id.toString() === data.bank);
        /*console.log(getValues('bank_account'))
        console.log(bankAccount)
        console.log(logo)*/
        let real_logo = logo.peruvian_bank.logo === null ? null : await getImage(logo.peruvian_bank.logo);
        //console.log(real_logo)
        setMyLogo(real_logo)
        setScreenMode('SUBMIT');
    }

    const modalIsOpen = (value) => {
        setIsOpen(value)
    }

    const onSubmit = async (data) => {

        data.fund_id = state.fund_id;
        data.investor_bank_account_id = data.bank_account;

        if (!data.type) {
            data.type = withdrawalType === 'partial' ? 'PARTIAL_RESCUE' : 'TOTAL_RESCUE';
        }

        data.transfer_operation_number = '1000';
        data.name = state.name;
        
        //console.log(data);

        try {


            let advancedAccessResponse = await checkFaceAdvancedAccess(mystate.user.id);
            let ApiResponse = await storeInvestorTransaction(mystate.user.investor_id, data);


            /*Toast.fire({
                icon: 'success',
                title: t('new-withdrawal-alert-success'),
                willClose: () => {
                    navigate('/operations/withdrawal/process', { state: {...ApiResponse.data} });
                }
            })*/

            navigate('/operations/withdrawal/process', { state: { ...ApiResponse.data } });

        } catch (error) {
            //console.log(error.response)

            if (error.response.data.key === 'ADVANCED_ACCESS_REQUIRED') {
                // await requestAdvancedAccessPin(data.email);
                Toast.fire({
                    icon: 'error',
                    title: t('new-withdrawal-alert-error'),
                    timer: 2500,
                    willClose: () => {
                        navigate('/advanced-access/face/requirements', { state: { from: 'withdrawal', ...data } });
                    }
                })
                return;
            }

            if (error.response.data.key === 'INSUFFICIENT_AMOUNT') {
                // await requestAdvancedAccessPin(data.email);
                Toast.fire({
                    icon: 'error',
                    title: t('new-withdrawal-alert-insufficient-amount') + error.response.data.message + ' ' + t('new-withdrawal-label-usd'),
                    timer: 2500,
                })

            }

            if (error.response.data.key === 'INSUFFICIENT_BALANCE') {
                // await requestAdvancedAccessPin(data.email);
                Toast.fire({
                    icon: 'error',
                    title: t('new-withdrawal-alert-insufficient-balance-2') + error.response.data.message,
                    timer: 2500,
                })

            }

            if (error.response.data.key === 'TRANSACTIONS_NOT_ENABLED_FOR_THIS_FUND') {
                Toast.fire({
                    icon: 'error',
                    title: t('new-withdrawal-alert-error-transactions-not-enabled-for-this-fund'),
                    timer: 2500,
                })
                return;
            }
            if (error.response.data.key === 'ERROR_PENDING_TOTAL_RESCUE') {
                Toast.fire({
                    icon: 'error',
                    title: t('new-withdrawal-alert-error-pending-total-rescue'),
                    timer: 2500,
                })
                return;
            }

            if (error.response.data.key === 'BALANCE_NOT_FOUND') {
                Toast.fire({
                    icon: 'error',
                    title: t('new-withdrawal-alert-error-balance-not-found'),
                    timer: 2500,
                })
                return;
            }

        }


    }


    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col" >


            <div className="flex mt-8 ml-8">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => goBack()} className="h-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            {screenMode === 'EDIT' &&
                <div className="flex flex-col px-8" >
                    <h1 className="mt-6 ml-2">{t('new-withdrawal-title')}</h1>

                    <p className="not-italic font-normal  text-sm  leading-5 text-gray-700 mt-8 ml-2">{t('new-withdrawal-label-description')}</p>

                    {withdrawalType === 'partial' && (
                        <>
                            <label className="not-italic font-normal text-sm leading-5 text-gray-500 mt-8 ml-2">
                                {t('new-withdrawal-label-withdrawal-amount')}
                            </label>

                            <input
                                type="text"
                                {...register("amount", { required: true, min: minimunTransactionValue.value, validate: (value) => value > 0, })}//, { required: true }
                                className={`border-b-2 border-black mt-3 border-t-0 border-l-0 border-r-0 rounded-none text-lg`}
                            />

                            {formState.errors.amount && !formState.isValid &&
                                <span className="text-red-500 mt-1 ml-2 text-xs mb-2">
                                    {formState.errors.amount.type === 'required' && t('new-withdrawal-alert-withdrawal-amount-required')}
                                    {formState.errors.amount.type === 'min' && t('new-withdrawal-alert-withdrawal-amount-min')}
                                    {formState.errors.amount.type === 'validate' && t('new-withdrawal-alert-withdrawal-amount-number')}
                                </span>
                            }

                        </>

                    )}

                    <p className="not-italic font-normal text-base text-gray-500 mt-5 ml-2">{t('new-withdrawal-label-destination-account')}</p>

                    {bankAccount !== null && (
                        <div id="bank-account-section" className={'mb-3 mt-3'}>
                            <div className='flex'>
                                <div className='flex-1 mr-1'>
                                    {<select {...register("bank_account")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                                        {Object.entries(bankAccount).map((item, i) => {
                                            return (
                                                <option key={i} className="mt-3" value={item[1].id}>
                                                    {item[1].account_number} - {item[1].my_bank} {item[1].display_name !== null && '- ' + item[1].display_name}
                                                </option>
                                            )
                                        })}
                                    </select>}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="p-3 bg-gray-200 flex flex-row rounded-lg mt-5 ">
                        <img
                            src="/images/icon-help-circle-2.svg"
                            alt="logo"
                            className="object-contain h-5 w-5 cursor-pointer text-ming-500"
                        />
                        <p className="ml-3 not-italic font-bold text-sm leading-4 text-ming-500 ">
                            {t('new-withdrawal-label-if-you-want-to-witdraw')}
                            {contact_information.phone}
                            {t('new-withdrawal-label-if-you-want-to-witdraw-2')}
                        </p>
                    </div>
                    {/** 
                    <button onClick={changeScreenMode} className={'w-full my-10 self-center font-normal text-sm'}>
                        {t('new-withdrawal-button-next')}
                    </button>*/}

                    <button type='button' disabled={(!formState.isValid || bankAccount === null)} onClick={() => changeScreenMode({ bank: getValues('bank_account'), bankAccount: bankAccount, from: 'button' })} className={`w-full my-10 self-center font-normal text-sm ${(!formState.isValid || bankAccount === null) && 'opacity-50 cursor-not-allowed'}`}>
                        {t('new-withdrawal-button-next')}
                    </button>

                </div>
            }

            {screenMode === 'SUBMIT' &&
                <div className="flex flex-col px-10">
                    <h1 className="mt-6">{t('new-withdrawal-title-2')}</h1>

                    <h1 className='text-sm mt-3 font-medium'>{t('new-withdrawal-label-credited')}</h1>

                    <div className="bg-white rounded-lg mt-10 text-sm shadow-xl text-gray-700 px-5">
                        <h3 className="font-bold text-base text-center   mt-4">{withdrawalType === 'partial' ? t('new-withdrawal-label-withdrawal-amount-2') : t('new-withdrawal-label-withdrawal-amount-3')}</h3>
                        <h2 className="font-medium text-base text-gray-700 text-center mt-3">
                            {withdrawalType !== 'partial' && t('new-withdrawal-label-approx-amount')}
                            {t('new-withdrawal-label-usd')}
                            {withdrawalType === 'partial' ? Number(getValues('amount')).toLocaleString('en-US', { maximumFractionDigits: 2 }) : Number(myBalance).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                        </h2>
                        <div className='px-1'>
                            <hr className="mt-4 border-t border-gray-300" />
                        </div>
                        <h4 className="font-normal mt-4">{state.name}</h4>

                        <div className='flex mt-4'>
                            <div className='flex-1'>
                                <p className="font-normal text-sm">{t('new-withdrawal-label-conmission')}</p>
                            </div>
                            <div>
                                <p className="font-normal text-sm">{t('new-withdrawal-label-ivg')}</p>
                            </div>
                        </div>

                        <h1 className='text-xs mt-4'>{t('new-withdrawal-label-only')}</h1>

                        <div className='px-1'>
                            <hr className="mt-4 border-t border-gray-300" />
                        </div>

                        <p className="font-normal text-sm mt-3 ">{t('new-withdrawal-label-destination-account-2')}</p>

                        {bankAccount !== null &&
                            <div className='flex mb-3 mt-1'>
                                <div className=''>
                                    {my_logo === null ?
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        :
                                        <img src={my_logo} alt="user-created" className="object-contain h-8 w-8" />
                                    }
                                </div>
                                <div className='flex-1 self-center ml-1 text-black '>
                                    {bankAccount.find(element => element.id.toString() === getValues('bank_account')).account_number}
                                </div>
                            </div>

                        }

                        <div id="new-subscription-withdrawal-terms-section" className='flex '>
                            <div className="">
                                <input style={{ appearance: 'auto' }}
                                    {...register("terms", { required: true })}
                                    className={`mb-0 flex-2 w-5 h-6 ${formState.errors.terms && 'border-red-500'}`}
                                    type="checkbox"
                                />
                            </div>

                            <div className='flex-1 ml-2 text-xs mb-5 mt-1'>
                                {`${t('new-subscription-label-terms')} `}
                                <label className='underline cursor-pointer font-bold text-gray-600' onClick={() => modalIsOpen(true)}>
                                    {t('new-subscription-label-terms-2')}
                                </label>
                            </div>
                        </div>

                    </div>

                    <button disabled={!formState.isValid} type="submit" className={` my-14 ${!(formState.isValid) && 'opacity-50 cursor-not-allowed'}`} >
                        {t('new-withdrawal-button-confirm')}
                    </button>

                    <Modal isOpen={isOpen} isFrom={'subscription-new'} modalData={modalData} modalIsOpen={modalIsOpen} />

                </div>
            }

        </form>
    );
}

export default NewWithdrawal;