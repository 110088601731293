import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';


const Modal = (data) => {
	const { t } = useTranslation();
	const [modalText, setModalText] = useState({ title: 'login-modal-title', description: 'login-modal-description', button: 'login-modal-button' });


	useEffect(() => {

		const init = async () => {
			if (data.isFrom === 'login') {
				setModalText({ title: 'login-modal-title', description: 'login-modal-description', button: 'login-modal-button' });
				return;
			}
		}
		init();


		return () => { }
	}, [data.isOpen])

	const closeModal = () => {
		data.modalIsOpen(false);
	}

	return (
		<Transition appear show={data.isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				onClose={closeModal}
			>
				<div className="min-h-screen text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="inline-block h-screen align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						{/*modal content starts here */}
						<div className="inline-block w-11/12 px-6 py-4  overflow-hidden text-left align-middle transition-all transform bg-asparagus-500 shadow-xl ">


							<Dialog.Title
								as="h2"
								className={" font-bold text-lgleading-6 text-center mb-8 mt-2 text-white"}
							>
								{t(modalText.title)}
							</Dialog.Title>

							<div className="mt-4">
								<div className='my-5 px-1'>

									{(data.isFrom === 'login') &&
										<div className='mb-16 text-white text-center'>
											{t(modalText.description)}
										</div>
									}

								</div>

								<button
									className={`w-full my-14 text-xs p-3 
									${(data.isFrom === 'login') && 'bg-white hover:bg-gray-100 mt-0 mb-5 p-4 text-sm text-asparagus-500 !important'}
									`}//bg-sherpaBlue-500 hover:bg-sherpaBlue-600
									onClick={() => window.location = `mailto:contacto@flip.com`}//?subject=${'test' || ""}&body=${'test' || ""}
								>
									{t(modalText.button)}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}

export default Modal;