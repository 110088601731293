import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams} from "react-router-dom";
import { useTranslation} from 'react-i18next';
import { Tab } from '@headlessui/react';
import MovementsFilter from './MovementsFilter';
import Footer from '../home/Footer';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MovementsDashboard = () => {
    const { t } = useTranslation();
	const navigate = useNavigate();
    const { state } = useLocation();
    //const { type } = useParams();

	useEffect(() => {
		//console.log(state);
		//console.log(type);
		return () => {}
	}, [])
    
    const Back = () => {
        navigate('/home')
    }


	return (
        <div className='flex flex-col'>


            <div className="flex flex-col px-5 mt-9" >
                <div className="">
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </div>
                <div className="mt-3 mb-3 ml-4">
                    <h1 className="text-3xl font-bold ">{t('movements-title')}</h1>
                </div>

            </div>

            <div className="w-full py-0 sm:px-0 mb-44">
                <Tab.Group>
                    <div className='px-2'> 
                        <Tab.List className="flex p-1 space-x-1 rounded-xl">
                            <Tab key={1} className={({ selected }) =>
                                classNames(
                                    'w-full rounded-full font-normal',selected ? 'tab-selected' : 'tab-not-selected'
                                )}>{t('movements-dashboard-tab-subscription')}</Tab>
                            <Tab key={2} className={({ selected }) =>
                                classNames(
                                    'w-full rounded-full font-normal',selected ? 'tab-selected' : 'tab-not-selected'
                                )}>{t('movements-dashboard-tab-withdrawal')}</Tab>

                            <Tab key={3} className={({ selected }) =>
                                classNames(
                                    'w-full rounded-full font-normal',selected ? 'tab-selected' : 'tab-not-selected'
                                )}>{t('movements-dashboard-tab-all')}</Tab>

                        </Tab.List>
                    </div>
                    <Tab.Panels className="mt-2">
                        <Tab.Panel className={classNames('')}>
                            <MovementsFilter type={'SUBSCRIPTION'}/>
                        </Tab.Panel>

                        <Tab.Panel className={classNames('')}>
                            <MovementsFilter type={'RESCUE'}/>
                        </Tab.Panel>

                        <Tab.Panel className={classNames('')}>
                            <MovementsFilter type={''}/>
                        </Tab.Panel>
                    </Tab.Panels> 
                </Tab.Group>
            </div>

            <Footer/>
        </div>
	);
}

export default MovementsDashboard;