import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import Footer from './Footer';


const Menu = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate, dispatch } = React.useContext(AuthContext);

    useEffect(() => {
        return () => { }
    }, []);

    const goToMovements = () => {
        navigate('/movements/history', { state: { ...state } });
    };

    const Back = () => {
        navigate('/home', { state: { ...state } });
    };

    const goToFundInformation = () => {
        navigate('/fund-information', { state: { ...state } });
    };

    const goToOperations = () => {
        if(state.balance.length === 0){
            navigate('/home/investor-recommended-funds/know-all-our-funds', { state: state.balance });
            return;
        }

        if(state.balance.length === 1){
            navigate('/operations/investment-summary', { state: state.balance[0] });
            return;
        }

        if(state.balance.length > 1){
            navigate('/operations', { state: state.balance });
            return;
        }
    };

    const goToAccount = () => {
        navigate('/account', { state: { ...state } });
    };

    const Logout = async () => {

        try {
            dispatch({ type: 'SIGN_OUT' })

            navigate('/login');


        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="flex flex-col" >
            <div className="flex flex-col" >
                <div className="flex mt-4 mb-5 px-5 items-center">
                    <div className='flex-1 w-30'>
                        <img
                            src="/images/flip-logo-color.svg"
                            alt="logo"
                            className="object-contain h-20 w-30 "
                        />
                    </div>
                    <div className='cursor-pointer' onClick={() => Back()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-sherpaBlue-500 font-bold h-10 w-12" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>

                <div className=" p-3 rounded-lg mb-1 cursor-pointer">

                    <div className="bg-white flex items-center cursor-pointer mb-3 py-5 px-4">
                        <div className='w-10'>
                            <div className='rounded-full bg-gray-400 h-6 w-6' />
                        </div>
                        <div className=" font-bold text-gray-600">{mystate.investor.name}</div>
                    </div>

                    <div className="bg-white flex items-center cursor-pointer mb-3 py-5 px-4" onClick={Back}>
                        <div className='w-10'>
                            <img className="object-contain h-6 self-center" src="/images/icon-home.svg" />
                        </div>
                        <div className=" font-bold text-gray-600 "> {t('home-home')} </div>
                    </div>

                    <div className="bg-white flex items-center cursor-pointer mb-3 py-5 px-4" onClick={goToMovements}>
                        <div className='w-10'>
                            <img className="object-contain h-6 self-center" src="/images/icon-transactions.svg" />
                        </div>
                        <div className=" font-bold text-gray-600 " > {t('home-movements')} </div>
                    </div>

                    <div className="bg-white flex items-center cursor-pointer mb-3 py-5 px-4" onClick={goToOperations}>
                        <div className='w-10'>
                            <img className="object-contain h-6 self-center" src="/images/icon-operations.svg" />
                        </div>
                        <div className=" font-bold text-gray-600 "> {t('home-operations')} </div>
                    </div>

                    <div className="bg-white flex items-center cursor-pointer mb-3 py-5 px-4 " onClick={goToAccount}>
                        <div className='w-10'>
                            <img className="object-contain h-6 self-center" src="/images/icon-account.svg" />
                        </div>
                        <div className=" font-bold text-gray-600 "> {t('home-account')} </div>
                    </div>

                    <div className="bg-white flex items-center cursor-pointer mb-3 py-5 px-4 min-w-full" onClick={Logout}>
                        <div className='w-10'>
                            <img className={`object-contain h-6 self-center`} src="/images/icon-profile-sign-off.svg" />
                        </div>
                        <div className=" font-bold text-gray-600 "> {t('account-button-logout')} </div>
                    </div>

                </div>
            </div>
        </div>


    );
}

export default Menu;