import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import {
    getInvestorBankAccount, getInvestorAddress, showInvestor,
    getInvestorOccupation, getInvestorPEPInformation, GetLoggedUser, storeAgreement
} from '../../services/ApiService'
import config from '../../constants/Config';
import { useForm } from "react-hook-form";


const YourSecurityIsFirst = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate, dispatch } = React.useContext(AuthContext);
    const [userHaveField, setUserHaveField] = useState({
        validate_identity: true, bank: true, personal: true,
        address: true, occupation: true, has_25_percent_of_a_company: false, is_pep: false,
        family_pep: true, pep_for_business_and_family: false,
        investor_pep_information: false, additional_information_button: false, completed: true,
        validate_identity_state: '', add_bank_account_state: '', additional_information_state: '',
        state_labels_updated: false, administration_agreement: true
    });
    const { register, handleSubmit, trigger, watch, reset, control, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    watch()
    useEffect(() => {

        const init = async () => {
            //console.log('state', state);// ü, é, á, í, ó, ú, ñ, Ñ, ¿, ¡,Í
            setUserHaveField({ ...userHaveField, additional_information_button: true });
            setValue('agreement', false);
            try {
                let get_investor_bank_account_response = [];

                try {
                    get_investor_bank_account_response = await getInvestorBankAccount(mystate.user.investor_id);
                } catch (error) {
                    get_investor_bank_account_response.data = []
                }
                let user_response = await GetLoggedUser(mystate.userToken);
                let show_investor_response = await showInvestor(mystate.user.id, mystate.user.investor_id);
                let get_investor_address_response = await getInvestorAddress(mystate.user.investor_id);
                let get_occupation_response = await getInvestorOccupation(mystate.user.investor_id);
                let get_investor_pep_information_response = await getInvestorPEPInformation(mystate.user.investor_id);
                /*console.log('get_investor_bank_account_response.data',get_investor_bank_account_response.data);
                console.log('show_investor_response.data',show_investor_response.data);
                console.log('get_investor_address_response.data',get_investor_address_response.data);
                console.log('get_occupation_response.data',get_occupation_response.data);
                console.log('show_investor_response.data.is_pep',show_investor_response.data.is_pep);
                console.log('get_investor_pep_information_response.data',get_investor_pep_information_response.data);
                console.log('user_response.data.document_id_image',user_response.data.document_id_image);*/

                let has_25_percent_of_a_company = !(Object.keys(get_investor_pep_information_response.data).length === 0) ? (get_investor_pep_information_response.data.has_25_percent_of_a_company === "1" ? true : false) : null
                //console.log(has_25_percent_of_a_company)

                dispatch({
                    type: 'UPDATE_PEP_ID',
                    id: Object.keys(get_investor_pep_information_response.data).length === 0 ? null : get_investor_pep_information_response.data.id,
                });

                //console.log(show_investor_response.data.onboarding_completed_at)
                /*console.log('identity',(user_response.data.document_id_image !== null) && (user_response.data.document_id_verified_at !== null))
                console.log('bank',!(Object.keys(get_investor_bank_account_response.data).length === 0))
                console.log('additional',!(Object.keys(get_investor_pep_information_response.data).length === 0))

                console.log('all',!(!(Object.keys(get_investor_bank_account_response.data).length === 0) && 
                    (user_response.data.document_id_image !== null) && (user_response.data.document_id_verified_at !== null) &&
                    !(Object.keys(get_investor_pep_information_response.data).length === 0)
                ))*/
                /*((userHaveField.add_bank_account_state === t('your-security-is-first-label-pending')) &&
                (userHaveField.validate_identity_state === t('your-security-is-first-label-pending')) &&
                (userHaveField.additional_information_state === t('your-security-is-first-label-pending'))*/
                setUserHaveField({
                    validate_identity: ((user_response.data.document_id_image !== null) && (user_response.data.document_id_verified_at !== null)) ? true : false,
                    bank: Object.keys(get_investor_bank_account_response.data).length === 0 ? false : true,
                    personal: show_investor_response.data.marital_status === null ? false : true,
                    address: Object.keys(get_investor_address_response.data).length === 0 ? false : true,
                    occupation: Object.keys(get_occupation_response.data).length === 0 ? false : true,
                    has_25_percent_of_a_company: has_25_percent_of_a_company,
                    pep_for_business_and_family: show_investor_response.data.is_pep === 0 ? false : true,
                    is_pep: show_investor_response.data.is_pep,
                    investor_pep_information: Object.keys(get_investor_pep_information_response.data).length === 0 ? false : true,
                    additional_button: true,
                    additional_information_button: false,
                    completed: show_investor_response.data.onboarding_completed_at === null ? true : false,
                    validate_identity_state: ((user_response.data.document_id_image !== null) && (user_response.data.document_id_verified_at !== null)) ? t('your-security-is-first-label-completed') : t('your-security-is-first-label-pending'),
                    add_bank_account_state: Object.keys(get_investor_bank_account_response.data).length === 0 ? t('your-security-is-first-label-pending') : t('your-security-is-first-label-completed'),
                    additional_information_state: Object.keys(get_investor_pep_information_response.data).length === 0 ? t('your-security-is-first-label-pending') : t('your-security-is-first-label-completed'),
                    state_labels_updated: true,
                    administration_agreement: !(!(Object.keys(get_investor_bank_account_response.data).length === 0) &&
                        (user_response.data.document_id_image !== null) && (user_response.data.document_id_verified_at !== null) &&
                        !(Object.keys(get_investor_pep_information_response.data).length === 0)
                    ),
                    /*family_pep: Object.keys(get_investor_address_response.data).length === 0 ? false:true,*/
                });

            } catch (error) {
                //console.log(error)
            }

        }
        init();
        //console.log(state);
        return () => { }
    }, [])

    const onSubmit = async (data) => {

        try {
            if (!data.agreement) {
                return;
            }

            let apiResponse = await storeAgreement(mystate.user.investor_id);
            //console.log(apiResponse.data)
            navigate('/onboarding/congratulations', { state: { ...state, from: 'your-security-is-first' } });
        } catch (error) {

            /*
                Toast.fire({
                    icon: 'error',
                    title: t('register-dni-data-alert-error-user-registered'),
                })*/

        }

    };

    const goToValidateIdentity = () => {
        navigate('/onboarding/validate-identity', { state: { ...state } });
    }

    const goToAddBankAccount = () => {
        navigate('/onboarding/add-bank-account', { state: { ...state } });
    }

    const sendWhatsappMessage = () => {
        const win = window.open(config.whatsappUrl, '_blank');
        win.focus();

    }

    const goToAgreement = () => {
        const win = window.open(config.agreementUrl, '_blank');
        win.focus();

    }
    
    const goToAdditionalInformation = () => {

        /*if (userHaveField.has_25_percent_of_a_company !== null) {
            navigate(userHaveField.has_25_percent_of_a_company ? 
                '/onboarding/additional-information/political-exposed-person/pep-information/participations' : 
                '/onboarding/additional-information/family-of-political-exposed-person', { state: { ...state } });
            return;
        }*/

        /* if (userHaveField.is_pep) {
             navigate('/onboarding/additional-information/political-exposed-person/go-to-pep-information', { state: { ...state } });
             return;
         }*/

        if (userHaveField.occupation) {
            navigate('/onboarding/additional-information/political-exposed-person', { state: { ...state } });
            return
        }

        if (userHaveField.address) {
            navigate('/onboarding/additional-information/occupation-data', { state: { ...state } });
            return
        }

        if (userHaveField.personal) {
            navigate('/onboarding/additional-information/residence-data', { state: { ...state } });
            return
        }

        navigate('/onboarding/additional-information/personal-data', { state: { ...state } });

    }

    const Back = () => {
        navigate('/register-dni-data')
    }

    return (
        <div className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-4 ml-2">
                <h1 className="text-3xl font-bold ">{t('your-security-is-first-title')}</h1>
            </div>

            <div className="mt-2">
                <p className="text-gray-700 text-sm ml-2">{t('your-security-is-first-description-label')}</p>
            </div>

            <div id="register-dni-data-suggest-section" className="suggest flex mb-9 mt-5">
                <svg className="h-5 w-5 text-ming-400 " viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 7H11V5H9V7ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z" fill="#0D4C57" />
                </svg>
                <label className="block ml-2 mb-2 flex-1">
                    {t('your-security-is-first-suggest-label')}
                    <label onClick={() => sendWhatsappMessage()} className="ml-1 mb-2 flex-1 cursor-pointer">
                        {t('your-security-is-first-suggest-phone-label')}
                    </label>
                </label>
            </div>
            {/**disabled={userHaveField.validate_identity} */}
            <button onClick={() => goToValidateIdentity()} disabled={userHaveField.validate_identity}
                className={`mb-5 card-option-btn border-gray-300 ${userHaveField.validate_identity && 'opacity-50 cursor-not-allowed'} 
                    ${userHaveField.validate_identity_state === t('your-security-is-first-label-pending') ? 'border-yellow-400' : 'border-green-500'}`}>
                <div>
                    <img
                        src="/images/icon-credential.svg"
                        alt="icon-credential"
                        className="object-contain h-7"
                    />
                </div>
                <div className="flex-1 ml-4">
                    <p className="text-gray-500 text-sm">{t('your-security-is-first-card-validate-identity')}</p>
                    {userHaveField.state_labels_updated &&
                        <div className={`w-max px-3 py-1 mt-1 text-white rounded-md text-center 
                        ${userHaveField.validate_identity_state === t('your-security-is-first-label-pending') ? 'bg-yellow-400' : 'bg-green-500'} `}>
                            <p className="text-white text-xs">{userHaveField.validate_identity_state}</p>
                        </div>
                    }
                </div>
            </button>

            <button onClick={() => goToAddBankAccount()} className={`mb-5 card-option-btn ${userHaveField.add_bank_account_state === t('your-security-is-first-label-pending') ? 'border-yellow-400' : 'border-green-500'}`}>
                <div>
                    <img
                        src="/images/icon-bank.svg"
                        alt="icon-bank"
                        className="object-contain h-7"
                    />
                </div>
                <div className="flex-1 ml-4">
                    <p className="text-gray-500 text-sm">{t('your-security-is-first-card-add-bank-account')}</p>
                    {userHaveField.state_labels_updated &&
                        <div className={`w-max px-3 py-1 mt-1 text-white rounded-md text-center
                            ${userHaveField.add_bank_account_state === t('your-security-is-first-label-pending') ? 'bg-yellow-400' : 'bg-green-500'} `}>
                            <p className="text-white text-xs">{userHaveField.add_bank_account_state}</p>
                        </div>
                    }

                </div>
            </button>

            <button onClick={() => goToAdditionalInformation()} disabled={(userHaveField.additional_information_button || userHaveField.additional_information_state !== t('your-security-is-first-label-pending'))}
                className={`mb-20 card-option-btn ${(userHaveField.additional_information_button || userHaveField.additional_information_state !== t('your-security-is-first-label-pending')) && 'opacity-50 cursor-not-allowed'}
            ${userHaveField.additional_information_state === t('your-security-is-first-label-pending') ? 'border-yellow-400' : 'border-green-500'}`}>
                <div>
                    <img
                        src="/images/icon-additional-info.svg"
                        alt="icon-additional-info"
                        className="object-contain h-7"
                    />
                </div>
                <div className="flex-1 ml-4 ">
                    <p className="text-gray-500 text-sm">{t('your-security-is-first-card-personal-data')}</p>
                    {userHaveField.state_labels_updated &&
                        <div className={`w-max px-3 py-1 mt-1 text-white rounded-md text-center
                        ${userHaveField.additional_information_state === t('your-security-is-first-label-pending') ? 'bg-yellow-400' : 'bg-green-500'} `}>
                            <p className="text-white text-xs">{userHaveField.additional_information_state}</p>
                        </div>
                    }
                </div>
            </button>

            <form onSubmit={handleSubmit(onSubmit)}>

                <div id="administration-agreement-section" className='mb-5 ml-3 flex'>

                    <input
                        className={`mb-0 border-2 w-5 h-6 self-center  ${userHaveField.administration_agreement && 'cursor-not-allowed'}`}
                        disabled={userHaveField.administration_agreement}
                        style={{ appearance: 'auto' }}
                        {...register("agreement", { required: true })}
                        type="checkbox"
                    />
                    <label className='ml-2 text-xs self-center underline cursor-pointer' onClick={goToAgreement}>
                        {`${t('your-security-is-first-label-administration-agreement')}`}
                    </label>

                </div>

                <button style={{ cursor: !getValues('agreement') && 'pointer' }} disabled={!getValues('agreement')} className={`self-center w-full mb-12  ${!getValues('agreement') && 'opacity-50 cursor-not-allowed'}`}>
                    {t('your-security-is-first-button')}
                </button>
            </form>

        </div>
    );
}

export default YourSecurityIsFirst;