import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const Logout = () => {
    const navigate = useNavigate();
    const { mystate, dispatch } = React.useContext(AuthContext);

    useEffect(() => {
        try {
            dispatch({ type: 'SIGN_OUT' })

            navigate('/login');


        } catch (e) {
            //console.log(e);
        }

        return () => { }
    }, [])


    const Logout = async () => {

        try {
            dispatch({ type: 'SIGN_OUT' })

            navigate('/login');


        } catch (e) {
            //console.log(e);
        }
    }


    return (
        <>
            {/* <button onClick={() => Logout()} type='button' >
            Logout
            </button> */}
        </>
    );
}

export default Logout;