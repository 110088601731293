import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import { getEconomicActivities, getSourceFunds, storeInvestorOccupation, getCountries, getPeruvianStates, getOccupation, getAverageIncomes } from '../../../services/ApiService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../i18n';
import { init } from 'i18next';


const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const OccupationData = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [fields, setFields] = useState({ occupation: [], economic_activities: [], countries: [], states: [], source_founds: [], average_incomes: [] });
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    //console.log(watch())
    watch()//doesn't work without this

    const onSubmit = async () => {
        try {
            //
            let data = {
                occupation: getValues('isOtherSelected') ? getValues('occupation2') : ( i18n.language === 'es' ? fields.occupation.find(element => element.id.toString() === getValues('occupation')).name.es : fields.occupation.find(element => element.id.toString() === getValues('occupation')).name.en),
                economic_activity_id: getValues('economic_activities'),
                country_id: getValues('country'),
                source_fund_id: getValues('source_founds'),
                peruvian_state_id: getValues('state'),
                average_income_id: getValues('average_incomes'),
            }
            
            if (data.country_id !== '169') {
                delete data['peruvian_state_id']
            }

            let ApiResponse = await storeInvestorOccupation(mystate.user.investor_id, data)
            //console.log(ApiResponse);

            /*Toast.fire({
                icon: 'success',
                title: t('occupation-alert-success-submit'),
                willClose: () => {
                    navigate('/onboarding/additional-information/political-exposed-person');
                }
            })*/

            navigate('/onboarding/additional-information/political-exposed-person');

        } catch (error) {
            //console.log(error)
            Toast.fire({
                icon: 'error',
                title: t('occupation-alert-error-submit'),
            })
        }

    }

    useEffect(() => {
        if (fields.states.length === 0) return;

        if (getValues('country') === '169') {
            //console.log(fields.states[0])
            setValue('isPeruSelected', true);
            setValue('state', fields.states[0].id.toString());
            //setValue('states', item[0]);
        } else {
            setValue('isPeruSelected', false);
            setValue('state', '');
        }
        return () => { }

    }, [getValues('country')])

    useEffect(() => {
        if (fields.occupation.length === 0) return;

        if (getValues('occupation') === '25') {
            //console.log(fields.states[0])

            setValue('isOtherSelected', true);
            //setValue('occupation', '');
            //setValue('occupation', fields.occupation[0].id.toString());
            //setValue('states', item[0]);
        } else {
            setValue('isOtherSelected', false);
            //setValue('occupation', '');
        }
        return () => { }

    }, [getValues('occupation')])

    useEffect(() => {
        const init = async () => {

            let getOccupationResponse = await getOccupation();
            setValue('isOtherSelected', false);
            //console.log(getOccupationResponse);

            let getEconomicActivitiesResponse = await getEconomicActivities();
            //console.log('getEconomicActivitiesResponse',getEconomicActivitiesResponse)
            getEconomicActivitiesResponse.data = getEconomicActivitiesResponse.data.sort((a, b) => (a.name.es > b.name.es ? 1 : -1))


            //console.log(getEconomicActivitiesResponse.data)
            let getCountriesResponse = await getCountries();
            setValue('isPeruSelected', true);
            let data_to_shift_index = getCountriesResponse.data.findIndex(element => element.code === 'PE');
            let initial_data = getCountriesResponse.data[0];
            getCountriesResponse.data[0] = getCountriesResponse.data[data_to_shift_index];
            getCountriesResponse.data[data_to_shift_index] = initial_data;

            let getPeruvianStatesResponse = await getPeruvianStates();
            let getSourceFundsResponse = await getSourceFunds();
            let getAverageIncomesResponse = await getAverageIncomes();

            setValue('occupation', getOccupationResponse.data[0].id.toString());
            setValue('country', getCountriesResponse.data[0].id.toString());
            setValue('state', getPeruvianStatesResponse.data[0].id.toString());
            setValue('economic_activities', getEconomicActivitiesResponse.data[0].id.toString());
            setValue('source_founds', getSourceFundsResponse.data[0].id.toString());
            setValue('average_incomes', getAverageIncomesResponse.data[0].id.toString());

            setFields({
                occupation: getOccupationResponse.data,
                countries: getCountriesResponse.data,
                states: getPeruvianStatesResponse.data,
                economic_activities: getEconomicActivitiesResponse.data,
                source_founds: getSourceFundsResponse.data,
                average_incomes: getAverageIncomesResponse.data
            });
        }

        init();
        return () => { }
    }, [])

    const goNext = (user) => {
        navigate('/register', { state: { ...state } });
    }

    const Back = () => {
        navigate('/your-security-is-first')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('occupation-title')}</h1>
            </div>

            <div className="mb-4">
                <p className="text-gray-600 text-sm ml-2">{t('occupation-description-label')}</p>
            </div>

            <div id="occupation-section" className={'mb-8'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="occupation">
                    {t('occupation-input-occupation-label')}
                </label>
                <div className='flex'>
                    <div className='flex-1 mr-1'>

                        <select {...register("occupation", { required: true })} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                            {fields.occupation.length !== 0 &&
                                <>
                                    {fields.occupation.map((item, i) => {
                                        return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                    })}
                                </>
                            }
                        </select>

                        {getValues('isOtherSelected') &&
                            <>
                                <input
                                    type="text"
                                    placeholder={t('occupation-input-occupation-placeholder')}
                                    defaultValue=''
                                    {...register("occupation2", { required: true })}
                                    className={`mb-0 border-2 mt-5 ${formState.errors.occupation2 && 'border-red-500'}`}
                                />
                                {formState.errors.occupation2 && !formState.isValid &&
                                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                                        {formState.errors.occupation2.type === 'required' && t('occupation-alert-occupation-required')}
                                    </span>
                                }
                            </>

                        }
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <p className="text-gray-600 text-sm ml-2">{t('occupation-description-label2')}</p>
            </div>

            <div id="economic-activites-section" className={'mb-3'}>

                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="economic-activites">
                    {t('occupation-input-economic-activities-label')}
                </label>
                <div className='flex'>
                    <div className='flex-1 mr-1'>
                        {<select {...register("economic_activities", { required: true })} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                            {fields.economic_activities.length !== 0 &&
                                <>
                                    {fields.economic_activities.map((item, i) => {
                                        return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                    })}
                                </>
                            }
                        </select>}
                    </div>
                </div>
            </div>

            <div id="country-section" className={'mb-3'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="country">
                    {t('occupation-input-country-label')}
                </label>
                <div className='flex'>
                    <div className='flex-1 mr-1'>
                        {<select {...register("country")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                            {fields.countries.length !== 0 &&
                                <>
                                    {fields.countries.map((item, i) => {
                                        return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                    })}
                                </>
                            }

                        </select>}
                    </div>
                </div>
            </div>

            <div id="state-section" className={'mb-3'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="state">
                    {t('occupation-input-state-label')}
                </label>
                <div className='flex'>
                    <div className='flex-1 mr-1'>
                        {getValues('isPeruSelected') === true ?
                            <>
                                {<select {...register("state")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                                    {fields.states.length !== 0 &&
                                        <>
                                            {fields.states.map((item, i) => {
                                                return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                            })}
                                        </>
                                    }
                                </select>}
                            </>
                            :
                            <>
                                <input
                                    type="text"
                                    placeholder={t('occupation-input-state-placeholder')}
                                    defaultValue=''
                                    {...register("state", { required: true })}
                                    className={`mb-0 border-2 ${formState.errors.state && 'border-red-500'}`}
                                />
                                {formState.errors.state && !formState.isValid &&
                                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                                        {formState.errors.state.type === 'required' && t('occupation-alert-state-required')}
                                    </span>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>

            <div id="source-founds-section" className={'mb-3'}>

                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="source-founds">
                    {t('occupation-input-source-founds-label')}
                </label>
                <div className='flex'>
                    <div className='flex-1 mr-1'>
                        {<select {...register("source_founds", { required: true })} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                            {fields.source_founds.length !== 0 &&
                                <>
                                    {fields.source_founds.map((item, i) => {
                                        return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                    })}
                                </>
                            }
                        </select>}
                    </div>
                </div>
            </div>

            <div id="average-incomes-section" className={'mb-12'}>

                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="average_incomes">
                    {t('occupation-input-mensual-average-incomes-label')}
                </label>
                
                <div className='flex'>
                    <div className='flex-1 mr-1'>
                        {<select {...register("average_incomes", { required: true })} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                            {fields.average_incomes.length !== 0 &&
                                <>
                                    {fields.average_incomes.map((item, i) => {
                                        return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                    })}
                                </>
                            }
                        </select>}
                    </div>
                </div>
            </div>

            <button disabled={!formState.isValid} type="submit" className={formState.isValid ? ' mb-10' : 'opacity-50 cursor-not-allowed mb-10'} >
                {t('occupation-button')}
            </button>

        </form >
    );
}

export default OccupationData;