import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import { GetLoggedUser } from '../../services/ApiService';
import i18n from '../../i18n';


const Maintenance = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const { mystate, dispatch } = React.useContext(AuthContext);
    //const [balance, setBalance] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            //console.log(mystate.userToken)
            ///let user_response = await GetLoggedUser(mystate.userToken);
            //console.log(user_response)
        }

        init();
        return () => { }
    }, []);

    const goNext = () => {
        navigate('/login', { state: { ...state } });
    };



    return (
        <div className="flex flex-col px-8 mb-10" >

            <div className="mt-28">
                <img
                    src="/images/flip-logo-color.svg"
                    alt="logo"
                    className="object-contain h-32 w-full"
                />
            </div>

            <div className='flex flex-col px-8 my-12'>
                <h2 className="mt-3 mb-14 text-center">{t('maintenance-title')}</h2>
            </div>

            <button onClick={() => goNext()} type="button" >
                {t('maintenance-button')}
            </button>


        </div>

    );
}

export default Maintenance;