const { REACT_APP_BACKOFFICE, REACT_APP_WHATSAPP_NUMBER, REACT_APP_FLIP_MAIN_URL } = process.env;
//console.log(process.env)

const config = {
    imgBaseUrl: REACT_APP_BACKOFFICE + '.flipinversiones.com/api/storage/image',
    ProductionBaseUrl: 'https://app.flipinversiones.com',
    BaseUrl: REACT_APP_BACKOFFICE + `${process.env.NODE_ENV === 'development' ? '-test.flipinversiones.com' : '.flipinversiones.com'}`,
    landingPageUrl: REACT_APP_FLIP_MAIN_URL + '/',
    blogUrl: 'https://blog.flipinversiones.com/',
    calendlyUrl: 'https://calendly.com/flipinversiones/15min',
    faqUrl: REACT_APP_FLIP_MAIN_URL + '/#faq',
    agreementUrl: REACT_APP_FLIP_MAIN_URL + '/legal',
    whatsappUrl: 'https://wa.me/' + REACT_APP_WHATSAPP_NUMBER,
}

export default config;