import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Webcam from "react-webcam";
import { grantFaceAdvancedAccess } from "../../services/ApiService";
import { AuthContext } from "../../services/AuthContext";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';



const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

const FaceAdvancedAccessRequest = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [isRendering, setIsRendering] = useState(true);
    //const [numberOfErrors, setNumberOfErrors] = useState(0);
    const { mystate } = React.useContext(AuthContext);

    const [selectedImage, setSelectedImage] = useState(null);
    let [screenMode, setScreenMode] = useState('GRANT')

    const videoConstraints = {
        width: 1280,
        height: 720,
        // aspectRatio: 9/16,
        facingMode: { ideal: "user" }
    };
/*
    useEffect(() => {
        console.log(state)
        //console.log(numberOfErrors);
        return () => { }
    }, [numberOfErrors])*/


    useEffect(() => {

        setTimeout(() => {
            setIsRendering(false);
        }, 2000);

        return () => { }
    }, [])

    const goNext = () => {

        if (state.from === 'withdrawal') {
            navigate('/operations/withdrawal/' + (state.type === 'PARTIAL_RESCUE' ? 'partial' : 'total') + '/new', { state: { ...state } });
            return;
        }

        navigate('/operations/subscription/new', { state: { ...state } });
    }

    const restart = () => {
        setIsRendering(true);
        setTimeout(() => {
            setIsRendering(false);
        }, 1500);
        setSelectedImage(null)
    }

    const Back = () => {
        if (state.from === 'document-id-registration') {
            navigate('/advanced-access/face/requirements', { state: { ...state } });
            return;
        }
        navigate('/home')
    }

    const backToDocumentIdRegistration = () => {
        navigate('/onboarding/validate-identity/document-registration', { state: { ...state } })
    }

    const webcamRef = React.useRef(null);

    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            // console.log(imageSrc);
            setSelectedImage(imageSrc);
        },
        [webcamRef]
    );

    const requestFaceAdvancedAccess = async () => {

        try {
            let formData = new FormData();
            formData.append('face_id_image', dataURLtoFile(selectedImage, 'face-id-' + mystate.user.id));

            let apiResponse = await grantFaceAdvancedAccess(mystate.user.id, formData);

            /*Toast.fire({
                icon: 'success',
                title: t('document-id-registration-alert-success'),
            })*/
            if (state.from === 'document-id-registration') {
                navigate('/onboarding/congratulations', { state: { from: 'document-id-registration' } });
                return;
            }
            setScreenMode('SUCCESS');

        } catch (error) {

            //alert(error.response.data.key);
            /*if(state.from === 'document-id-registration'){
                if (numberOfErrors >= 3) {
                    navigate('/advanced-access/face/errors', { state: { from: state.from } });
                }
            }*/


            if (error.response.data.key === 'ERROR_ON_FACE_VALIDATION') {
                Toast.fire({
                    icon: 'error',
                    title: t('request-alert-image-not-valid'),
                    timer: 5000,
                })
            }

            if (error.response.data.key === 'DOCUMENT_ID_IMAGE_NOT_FOUND') {
                Toast.fire({
                    icon: 'error',
                    title: t('request-alert-document-id-image-not-found'),
                    timer: 5000,
                })
            }

            if (error.response.data.key === 'FACE_IMAGE_IS_NOT_A_PERSON') {
                Toast.fire({
                    icon: 'error',
                    title: t('request-alert-image-not-a-person'),
                    timer: 5000,
                })
            }

            if (error.response.data.key === 'FACE_ID_IMAGE_IS_A_DOCUMENT_ID') {
                Toast.fire({
                    icon: 'error',
                    title: t('request-alert-face-image-is-a-document-id'),
                    timer: 5000,
                })
            }

            if (error.response.data.key === 'ENDPOINT') {
                Toast.fire({
                    icon: 'error',
                    title: t('request-alert-endpoint'),
                    timer: 5000,
                })
            }

            if (error.response.data.key === 'FACES_DONT_MATCH') {
                Toast.fire({
                    icon: 'error',
                    title: t('request-alert-faces-dont-match'),
                    timer: 5000,
                })
            }

            //setNumberOfErrors(numberOfErrors + 1);
        }

    }

    if (screenMode === 'SUCCESS') {
        return (
            <div className="flex flex-col px-10">
                <h1 className="mt-36 not-italic font-bold text-3xl leading-5 text-gray-700 self-center">{t('request-label-image-valid')}</h1>

                <img
                    src="/images/icon-auth-passed.svg"
                    alt="email-sent"
                    className="object-contain h-60 w-full"
                />

                <button onClick={goNext} className="w-full my-10 self-center font-normal text-xl mt-36">{t('request-button-continue')}</button>
            </div>
        )
    }

    return (
        <>
            <div className={`flex flex-col px-5  webcam-container pb-20 ${isRendering && 'pb-80'}`} >
                <div className="mt-6">
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 text-white cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </div>

                <h2 className="w-full text-white text-center mt-3">{t('request-title')}</h2>

                <div className="flex flex-row mx-20 mt-20 self-center ">
                    {!selectedImage &&
                        <Webcam audio={false}
                            width={1280}
                            height={720}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints} />
                    }
                    {selectedImage && <img className="m-1 " src={selectedImage} alt="" />}
                </div>

                <h3 className=" text-white text-center mt-8 mb-12 ">{t('request-label-description')}</h3>

                {!selectedImage && <button className="place-self-end w-full text-center mt-14 mb-60" onClick={capture}>{t('request-button-photo-capture')}</button>}

                {selectedImage &&
                    <div className='mb-40'>
                        <button className="place-self-end w-full text-center mt-14 mb-0.5" onClick={requestFaceAdvancedAccess}>
                            {t('request-button-send')}
                        </button>

                        <button onClick={restart} type='button' className="bg-transparent text-asparagus-500  hover:bg-transparent w-full text-center">
                            {t('request-button-restart')}
                        </button>

                        {state.from === 'document-id-registration' &&
                            <button onClick={backToDocumentIdRegistration} type='button' className="bg-transparent text-asparagus-500  hover:bg-transparent  w-full text-center mb-6">
                                {t('request-button-back-to-document-id-registration')}
                            </button>
                        }

                    </div>
                }

            </div>


        </>
    );
}

export default FaceAdvancedAccessRequest;