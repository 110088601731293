import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../../tailwind-components/Modal';

const ValidateIdentity = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        //console.log(state);
        return () => { }
    }, [])


    const goNext = (user) => {
        modalIsOpen(true);
    }

    const modalIsOpen = (value) => {
        setIsOpen(value)
        if (value === false) {
            navigate('/onboarding/validate-identity/document-registration', { state: { ...state } });
        }
    }

    const Back = () => {
        navigate('/your-security-is-first')
    }

    return (
        <div className="flex flex-col px-5 mt-9" >
            
            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-2xl font-bold ">{t('validate-identity-title')}</h1>
            </div>

            <div className="mb-9">
                <p className="text-gray-700 text-sm ml-2">{t('validate-identity-description-label')}</p>
            </div>

            <div className="mb-9">
                <p className="text-gray-700 text-sm ml-2">{t('validate-identity-description-label-question')}</p>
            </div>


            <div id="security-section" className="ml-3 flex mb-5">
                <div className="mr-2">
                    <img
                        src="/images/icon-shield.svg"
                        alt="icon-shield"
                        className="object-contain h-9 w-full"
                    />
                </div>
                <div>
                    <label className="block ml-2 mb-1 text-sherpaBlue-500 font-bold flex-1">
                        {t('validate-identity-label-section-title-security')}
                    </label>

                    <label className="block ml-2 mb-2 text-gray-700  text-sm flex-1">
                        {t('validate-identity-label-section-description-security')}
                    </label>
                </div>
            </div>

            <div id="regulation-section" className="ml-3 flex mb-5">
                <div className="mr-2">
                    <img
                        src="/images/icon-badge.svg"
                        alt="icon-badge"
                        className="object-contain h-9 w-full"
                    />
                </div>
                <div className='mb-24'>
                    <label className="block ml-2 mb-1 text-sherpaBlue-500 font-bold flex-1">
                        {t('validate-identity-label-section-title-regulation')}
                    </label>

                    <label className="block ml-2 mb-2 text-gray-700  text-sm flex-1">
                        {t('validate-identity-label-section-description-regulation')}
                    </label>
                </div>
            </div>
            {/** 
            <div id="suggest-section" className="suggest-validate-identity cursor-pointer flex my-2 self-center mb-20 font-bold ">
                <div className="cursor-pointer  self-center mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div className="cursor-pointer">
                    <label className="block flex-1 self-center cursor-pointer underline text-base">
                        {t('validate-identity-label-suggest')}
                    </label>
                </div>
            </div>*/}

            <Modal isOpen={isOpen} isFrom={'validate-identity'} modalIsOpen={modalIsOpen} />

            <button onClick={() => goNext()} className="self-center w-full" type="button" >
                {t('validate-identity-button')}
            </button>

        </div>
    );
}

export default ValidateIdentity;