import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import { getInvestorTransaction, getInvestorTransactionStatus } from '../../services/ApiService';
import Spinner from '../../components/tailwind-components/Spinner';

const MovementsFilter = ({ type }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [investment, setInvestment] = useState([])
    const [isLoadingComplete, setLoadingComplete] = React.useState(false);

    const goToInvestmentDetail = (id) => {
        navigate(`/movements/${id}/detail`, { state: { ...state } });
    }

    useEffect(() => {

        const init = async () => {
            try {

                let ApiResponse = await getInvestorTransaction(mystate.user.investor_id, type);
                let getInvestorTransactionStatusResponse = await getInvestorTransactionStatus('es');

                ApiResponse.data.map((item) => {
                    let status = Object.entries(getInvestorTransactionStatusResponse.data).find(item2 => item2[0] === item.status)
                    item.mystatus = status[1]
                })
                //console.log(ApiResponse.data)
                setInvestment(ApiResponse.data)
                setLoadingComplete(true);
                //console.log(new Date(ApiResponse.data[0].created_at).toLocaleDateString())
                //console.log(new Date(ApiResponse.data[0].created_at));
            } catch (error) {
                //console.log(error);
            }
        }

        init();
        return () => { }
    }, []);

    if (!isLoadingComplete) {
        return (
            <Spinner type={'small'} />
        )

    }

    return (
        <div className="flex flex-col mt-3" >

            {investment.length > 0 &&
                <>
                    {investment.map((item, i) => {
                        return (
                            <div key={i} onClick={() => goToInvestmentDetail(item.id)} className="card-shadow bg-white p-3 rounded-lg mb-1 cursor-pointer">
                                <div className='mb-1 text-xs text-gray-400'>
                                    {
                                        new Date(item.created_at).toLocaleDateString('es-VE') + ' ' +
                                        new Date(item.created_at).toLocaleTimeString('es-VE')
                                    }
                                </div>
                                <div className='self-end mb-2'>
                                    {item.type === 'SUBSCRIPTION' ? t('movements-filter-label-investment') : t('movements-filter-label-withdrawal')}
                                </div>
                                <div className='flex'>
                                    <div className='flex-1'>
                                        {item.type === "TOTAL_RESCUE" ?
                                            <>
                                                {t('movements-filter-label-total-rescue')}
                                            </>
                                            :
                                            <>
                                                {t('movements-filter-label-usd')} {Number(item.amount).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                            </>    
                                        }

                                        
                                    </div>
                                    <div className=''>
                                        {item.mystatus}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            }
        </div>
    );
}

export default MovementsFilter;