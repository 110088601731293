import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CostEffectiveness = ({ fund }) => {
    const { t } = useTranslation();
    const [performanceTable, setPerformanceTable] = useState([]);

    useEffect(() => {
        //console.log(fund.profitability_description);
        let fund_performance_table = fund.profitability.map((item) => {
            return {
                badYear: item.attributes.rightYear,
                badYearPercentage: item.attributes.rightYearPercentage,
                goodYear: item.attributes.leftYear,
                goodYearPercentage: item.attributes.leftYearPercentage,
            }
        })
        setPerformanceTable(fund_performance_table)
        /*let fund_description_table = fund.description.map((item) => {
            let items = item.attributes.rows.map((row) => {
                return { title: row.attributes.title, percentage: row.attributes.percentage }
            })

            return {
                title: item.attributes.title, description: item.attributes.description, percentage: item.attributes.percentage,
                items
            }
        })
        console.log(fund_description_table);
        setFundDescriptionTable(fund_description_table);*/
        return () => { }
    }, [])

    /*const performanceTable = [
        { goodYear: '2019', goodYearPercentage: '+13.67%', badYear: '2015', badYearPercentage: '-4.47%' },
        { goodYear: '2010', goodYearPercentage: '+11.40%', badYear: '2018', badYearPercentage: '-3.21%' },
        { goodYear: '2017', goodYearPercentage: '+10.57%', badYear: '2011', badYearPercentage: '-1.30%' },
        { goodYear: '2016', goodYearPercentage: '+10.22%', badYear: '2013', badYearPercentage: '+2.09%' },
        { goodYear: '2012', goodYearPercentage: '+7.86%', badYear: '2014', badYearPercentage: '+5.78%' },
        { goodYear: '2020', goodYearPercentage: '+6.60%', badYear: '', badYearPercentage: '', },
    ]*/


    return (
        <div className="flex flex-col">
            {/** 
            <h3 className="mt-10 w-full text-gray-700"> <span className="font-bold text-sherpaBlue-500">{t('fund-information-cost-effectiveness-title')}</span>{t('fund-information-cost-effectiveness-title-2')}</h3>

            <h3 className="mt-0 w-full text-gray-700"> <span className="font-bold text-sherpaBlue-500">{fund.profitability_title}</span></h3>
            <p className="mt-6 not-italic font-medium text-xs leading-4 text-gray-700"
                dangerouslySetInnerHTML={{ __html: fund.profitability_description }}
            >

            </p>
*/}
            <div className="p-2 bg-white rounded-lg mt-0 shadow-xl">
                
                <div className="p-2 rounded w-full fund-information-stripped-row text-center  text-sherpaBlue-500 not-italic font-medium text-sm leading-4">
                        {fund.profitability_title}
                </div>

                <table className="performance-table w-full text-left  mt-2.5">


                    <tbody className="border-t-8 border-white">
                        {performanceTable.map((row) => {
                            return (
                                <tr key={row.goodYear}>
                                    <td className="fund-information-stripped-row text-left pl-4 not-italic border-white border-b-8 font-bold text-sm leading-4 text-gray-600 ">{row.goodYear} </td>
                                    <td className="fund-information-stripped-row border-white border-r-8 border-b-8 text-right pr-4 not-italic font-bold text-xs leading-4 text-gray-600">{row.goodYearPercentage}</td>
                                    <td className="fund-information-stripped-row border-white border-l-8 text-left pl-4 not-italic font-bold text-sm leading-4 text-gray-600 border-b-8">{row.badYear} </td>
                                    <td className="fund-information-stripped-row text-right pr-4 not-italic font-bold text-xs leading-4 text-gray-600 border-white border-b-8">{row.badYearPercentage}  </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <p className="mt-6 not-italic font-medium text-xs leading-4 text-gray-700"
                dangerouslySetInnerHTML={{ __html: fund.profitability_description }}
            />

            {/*<p className="not-italic font-medium text-xs leading-4 text-gray-700 mt-24">
                {t('fund-information-cost-effectiveness-label-the-portfolio')}
            </p>*/}

        </div>

    );
}

export default CostEffectiveness;