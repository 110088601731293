import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';

const KnowYourInvestorProfile = () => {
    const { t } = useTranslation();
	const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
	//const [content, setContent] = useState({title:'null',image:'/images/icon-hand-success.svg'})

	useEffect(() => {
        //console.log(mystate);
		return () => {}
	}, [])
    
	const goNext = () => {
		navigate('/know-your-investor-profile/investor-questions', { state: {...state} });
	}

    const Back = () => {
        navigate('/your-security-is-first', { state: {...state} })
    }

	return (
		<div className="flex flex-col px-6 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

			<div className="mt-5 mb-8 ml-2">
				<h1 className="text-xl font-bold ">{t('know-your-investor-profile-title')}</h1>
			</div>

            <div className="mb-32">
                <p className="text-gray-500 text-sm ml-2">{t('know-your-investor-profile-label-description')}</p>
            </div>

            <button onClick={()=>goNext()} type="button" >
                {t('know-your-investor-profile-button-submit')}
            </button>

		</div>
	);
}

export default KnowYourInvestorProfile;