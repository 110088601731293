import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { checkFaceAdvancedAccess, requestAdvancedAccessPin, getTransactionMinimunAmount, getInvestorBankAccount, getPeruvianBanks, getTerms, resetPasswordRequest } from '../../services/ApiService';
import { AuthContext } from "../../services/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useForm } from "react-hook-form";
import Modal from '../tailwind-components/Modal';
import i18n from '../../i18n';

const MySwal = withReactContent(Swal)
const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const NewSubscription = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { register, handleSubmit, watch, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    const { mystate, dispatch } = React.useContext(AuthContext);
    const [screenMode, setScreenMode] = useState('EDIT');
    const [isOpen, setIsOpen] = useState(false);
    const [investorBankAccounts, setinvestorBankAccounts] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [minimunTransactionValue, setMinimunTransactionValue] = useState('');
    const maximunTransactionValue = 100000000;
    watch();
    /*useEffect(() => {

        if (state !== null) {
            reset(state);
            onSubmit(state);
            //trigger();
        }
        return () => { }
    }, =[])*/


    
    useEffect(() => {
        //console.log(state)
        if(state.hasOwnProperty('from')){
           
            if(state.from === "subscription"){
                setValue('amount',state.amount);
                setScreenMode('SUBMIT');
                onSubmit(state);
                return;
            }
        }
        //console.log(mystate)
        const init = async () => {

            let getPeruvianBanksResponse = await getPeruvianBanks();
            let getInvestorBankAccountResponse = await getInvestorBankAccount(mystate.user.investor_id);
            let getTermsResponse = await getTerms(i18n.language === 'es' ? 'es' : 'en');
            let ApiResponse = await getTransactionMinimunAmount();

            getInvestorBankAccountResponse.data.map((item) => {

                let my_bank = getPeruvianBanksResponse.data.find(element => element.id === item.peruvian_bank_id);
                item.my_bank = i18n.language === 'es' ? my_bank.name.es : my_bank.name.en;
            })

            //console.log(getInvestorBankAccountResponse.data)

            //console.log(ApiResponse.data)

            setinvestorBankAccounts(getInvestorBankAccountResponse.data);
            setMinimunTransactionValue(ApiResponse.data)
            setModalData(getTermsResponse.data);
            setValue('bank_accounts', getInvestorBankAccountResponse.data[0].id.toString());

        }

        init();


        return () => { }
    }, []);

    const onSubmit = async (oldData) => {
        //console.log(oldData)

        let oldBankAccountSelected = oldData.investor_bank_account_id ? oldData.investor_bank_account_id : getValues('bank_accounts');
        //console.log('oldBankAccountSelected',oldBankAccountSelected)

        let peruvian_bank_id_of_selected_bank_account = investorBankAccounts.find(element => element.id.toString() === oldBankAccountSelected);
        //console.log('peruvian_bank_id_of_selected_bank_account ',peruvian_bank_id_of_selected_bank_account)

        let data = {
            amount: oldData ? oldData.amount : getValues('amount'),
            investor_bank_account_id: oldBankAccountSelected,
            peruvian_bank_id: state.peruvian_bank_id ? state.peruvian_bank_id: peruvian_bank_id_of_selected_bank_account.peruvian_bank_id,
            type: 'SUBSCRIPTION',
            fund_id: state.fund_id,
            name: state.name,
            ruc: state.ruc,
            account_number: state.account_number,
            cci: state.cci,
        }
        //console.log(data);

        try {

            let advancedAccessResponse = await checkFaceAdvancedAccess(mystate.user.id);
            //console.log(advancedAccessResponse.data)
            //let ApiResponse = await GetLoggedUser(mystate)
            /*Toast.fire({
                icon: 'success',
                title: t('new-subscription-alert-success'),
                willClose: () => {
                    navigate('/operations/subscription/transfer-to-flip', { state: {...data} });
                }
            })*/
            let fund_ids=[];

            if(mystate.investor.investor_profile.fund_id) fund_ids.push(mystate.investor.investor_profile.fund_id)
            if(mystate.investor.investor_profile.fund_id2) fund_ids.push(mystate.investor.investor_profile.fund_id2);
            
            //console.log(fund_ids.includes(state.fund_id));

            navigate(fund_ids.includes(state.fund_id) ? '/operations/subscription/transfer-to-flip'
            :'/operations/subscription/fund-do-not-fit-profile', { state: { ...data } })

            /*navigate(((state.fund_id === mystate.investor.investor_profile.fund_id) || (state.fund_id === mystate.investor.investor_profile.fund_id2)) ? '/operations/subscription/transfer-to-flip'
            :'/operations/subscription/fund-do-not-fit-profile', { state: { ...data } });*/


        } catch (error) {

            if (error.response.data.key === 'ADVANCED_ACCESS_REQUIRED') {
                // await requestAdvancedAccessPin(data.email);
                Toast.fire({
                    icon: 'error',
                    title: t('new-subscription-alert-error'),
                    willClose: () => {
                        navigate('/advanced-access/face/requirements', { state: { from: 'subscription', ...data } });
                    }
                })

            }

        }

    };
    
    const modalIsOpen = (value) => {
        setIsOpen(value)
    }

    const goBack = () => {
        navigate('/home', { state: { ...state } });
    };

    const changeScreenMode = () => {
        setScreenMode('SUBMIT');
    }

    return (
        <div className="flex flex-col">

            <div className="flex mt-10 ml-6">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => goBack()} className="h-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            {screenMode === 'EDIT' &&
                <form onSubmit={handleSubmit(changeScreenMode)} className="flex flex-col px-8">

                    <h1 className="mt-6">{state.name}</h1>

                    <div className="mt-10">
                        <label className="not-italic font-medium text-base text-gray-600">{t('new-subscription-label-how-much')}</label>
                        {minimunTransactionValue.length !== 0 &&
                            <>
                                <input
                                    type="text"
                                    defaultValue={0}
                                    {...register("amount", {
                                        required: true, min: minimunTransactionValue.value, max: maximunTransactionValue,validate: (value) => value > 0                                    })}
                                    className={`border-2 mt-2 ${formState.errors.email && 'border-red-500'}`}
                                //style={{...styles.inputBorder}}
                                />

                                {formState.errors.amount &&
                                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                                        {formState.errors.amount.type === 'required' && t('new-subscription-alert-amount-error-required')}
                                        {formState.errors.amount.type === 'min' && t('new-subscription-alert-amount-error-min') + minimunTransactionValue.value}
                                        {formState.errors.amount.type === 'max' && t('new-subscription-alert-amount-error-max') + maximunTransactionValue}
                                        {formState.errors.amount.type === 'validate' && t('new-subscription-alert-amount-error-validate')}
                                        
                                    </span>
                                }
                            </>
                        }

                    </div>


                    <h3 className="not-italic font-medium text-base text-gray-600 mt-5">{t('new-subscription-label-from-wich-bank')}</h3>

                    {<select {...register("bank_accounts", { required: true })} className=" rounded-2xl mt-2 border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">

                        {investorBankAccounts.map((item, i) => {
                            return (<option key={i} className="mt-3" value={item.id}> {item.account_number} - {item.my_bank} {item.display_name !== null && '- ' + item.display_name}</option>)
                        })}

                    </select>}

                    {/**
                    <p className="not-italic font-medium text-xs leading-4 text-gray-700 mt-16">{t('new-subscription-label-average-annual-return')}</p>
                     */}

                    <div id="new-subscription-suggest-section" className="suggest flex mt-12">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <label className="block ml-2 flex-1">
                            {t('new-subscription-input-suggest-label')} {minimunTransactionValue.value}
                        </label>
                    </div>



                    <button disabled={!formState.isValid} type="submit" className={`w-full mb-20 self-center font-normal text-xl mt-36 ${!(formState.isValid) && 'opacity-50 cursor-not-allowed'}`} >
                        {t('new-subscription-button-next')}
                    </button>

                </form>
            }

            {screenMode === 'SUBMIT' &&
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-8">
                    <h1 className="mt-6 text-xl">{t('new-subscription-label-summary')}</h1>

                    <div className="p-6 bg-white rounded-lg mt-10 shadow-lg ">

                        <h3 className="font-bold text-lg text-center text-gray-700">
                            {t('new-subscription-label-box-title')}
                        </h3>

                        <h2 className=" font-medium text-3xl text-gray-700 break-words text-center mt-4">
                            {t('new-subscription-label-usd')} {Number(getValues('amount')).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                        </h2>

                        <hr style={{ borderTop: '1px solid #ffffff' }} className="m-0 w-full border-2  mt-5" />

                        <h4 className=" text-large text-gray-800  mt-5 ">
                            {state.name}
                        </h4>
                        {/** 
                        <p className=" font-normal text-base text-black mt-8">
                            {t('new-subscription-label-commission')}
                        </p>*/}

                        <div id="new-subscription-terms-section" className='mt-5 flex '>
                            <div className="m-0 p-0">
                                <input style={{ appearance: 'auto' }}
                                    {...register("terms", { required: true })}
                                    className={`mb-0 flex-2 w-5 h-6 ${formState.errors.terms && 'border-red-500'}`}
                                    type="checkbox"
                                />
                            </div>
                            <div className='flex-1 ml-2 mt-1 text-xs'>
                                {`${t('new-subscription-label-terms')} `}
                                <label className='underline cursor-pointer font-bold text-gray-600' onClick={() => modalIsOpen(true)}>
                                    {t('new-subscription-label-terms-2')}
                                </label>
                            </div>
                        </div>

                        <p className="text-xs font-semibold text-sherpaBlue-500 mt-5">
                            {t('new-subscription-label-will-close')}
                        </p>
                    </div>

                    <button disabled={!formState.isValid} type="submit" className={` my-14 ${!(formState.isValid) && 'opacity-50 cursor-not-allowed'}`} >
                        {t('new-subscription-button-submit')}
                    </button>

                    <Modal isOpen={isOpen} isFrom={'subscription-new'} modalData={modalData} modalIsOpen={modalIsOpen} />

                </form>
            }

        </div>
    );
}

export default NewSubscription;