import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import { getInvestorTransaction } from '../../services/ApiService';
import Spinner from '../../components/tailwind-components/Spinner';
import { getInvestorGlobalBalances } from '../../services/ApiService';

const OperationsFilter = ({ type }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [isLoadingComplete, setLoadingComplete] = React.useState(false);

    /*const goToInvestmentDetail = (id) => {
        navigate(`/movements/${id}/detail`, { state: { ...state } });
    }

    const [balance, setBalance] = useState({
        current_balance: "0.0",
        investments: "0.0",
        winnings: "0.0",
    });*/

    useEffect(() => {

        const init = async () => {
            try {
                //console.log(state)
                //let ApiResponse = await getInvestorGlobalBalances(mystate.user.investor_id);
                
                //setBalance(ApiResponse.data)
                //console.log(ApiResponse.data);
                setLoadingComplete(true);
            } catch (error) {
                //console.log(error);
            }
        }

        init();
        return () => { }
    }, []);

    if (!isLoadingComplete) {
        return (
            <Spinner type={'small'} />
        )

    }

    return (
        <div className="flex flex-col mt-3" >

            <div className="flex flex-col card-shadow bg-white p-3 rounded-lg mb-1 cursor-pointer">
                <div className='flex my-2 ml-4  text-sm'>
                    <div className='flex-1 text-gray-700'>
                        {t('operations-filter-label-current-balance')}
                    </div>
                    <div className='mr-4'>
                        {t('operations-filter-label-usd')}{Number(state.current_balance).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                    </div>
                </div>
                <hr style={{ borderTop: '1px solid #ffffff' }} className='mx-4 border-2' />

                <div className='flex my-2 ml-4   text-sm '>
                    <div className='flex-1 text-gray-700'>
                        {t('operations-filter-label-cumulative-return')}
                    </div>
                    <div className='mr-4'>
                        {t('operations-filter-label-usd')}{Number(state.winnings).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                    </div>
                </div>

            </div>

        </div>
    );
}

export default OperationsFilter;