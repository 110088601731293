import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { storeInvestorTransaction,getTerms } from '../../services/ApiService';
import { AuthContext } from '../../services/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../i18n';
import Modal from '../tailwind-components/Modal';

const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const SendConstancy = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    //console.log(watch())
    useEffect(() => {
        const init = async () => {

            let getTermsResponse = await getTerms(i18n.language === 'es' ? 'es' : 'en');

            setModalData(getTermsResponse.data);


        }

        init();
        return () => {

        }
    }, []);

    const modalIsOpen = (value) => {
        setIsOpen(value)
    }

    const onSubmit = async () => {
        try {

            let data = {
                ...state,
                transfer_operation_number: getValues('operation'),
            }
            //console.log(data);
            let ApiResponse = await storeInvestorTransaction(mystate.user.investor_id, data)
            //console.log(ApiResponse.data);

            /*Toast.fire({
                icon: 'success',
                title: t('send-constancy-alert-success-submit'),
                willClose: () => {
                    navigate('/operations/subscription/sended-constancy', { state: {...ApiResponse.data} });
                }
            })*/

            navigate('/operations/subscription/sended-constancy', { state: { ...ApiResponse.data } });

        } catch (error) {
            //console.log(error)

            if (error.response.data.key === 'INSUFFICIENT_AMOUNT') {
                // await requestAdvancedAccessPin(data.email);
                Toast.fire({
                    icon: 'error',
                    title: t('send-constancy-alert-error-insufficent-amount') + error.response.data.message + t('send-constancy-label-usd'),
                    timer: 2500,
                })
                return;
            }

            if (error.response.data.key === 'TRANSACTIONS_NOT_ENABLED_FOR_THIS_FUND') { 
                Toast.fire({
                    icon: 'error',
                    title: t('send-constancy-alert-error-transactions-not-enabled-for-this-fund'),
                    timer: 2500,
                })
                return;
            }

            if (error.response.data.key === 'BALANCE_NOT_FOUND') {
                Toast.fire({
                    icon: 'error',
                    title: t('send-constancy-alert-error-balance-not-found'),
                    timer: 2500,
                })
                return;
            }
             
            Toast.fire({
                icon: 'error',
                title: t('send-constancy-alert-error-submit'),
            })
        }

    }

    useEffect(() => {
        const init = async () => {
            try {
                //console.log(state)
            } catch (error) {
                //console.log(error)
            }
        }
        init();
        return () => { }
    }, [])


    const Back = () => {
        navigate('/operations/subscription/transfer-to-flip', { state: { ...state } })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-6 mt-9" >

            <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                <div className='ml-5 mt-0.5 text-sm'>{t('send-constancy-step-3')}</div>
            </div>

            <div className="mt-5 mb-20 ml-2">
                <h1 className="text-2xl font-bold ">{t('send-constancy-title')}</h1>
            </div>

            <div id="operation-section">
                <div className='mb-3'>
                    <label className="block ml-2 text-sm text-gray-700 mb-2" htmlFor="operation">
                        {t('send-constancy-input-operation-number')}
                    </label>
                </div>

                <input
                    type="text"
                    placeholder={t('send-constancy-input-operation-number-placeholder')}
                    defaultValue=''
                    {...register("operation", { required: true })}
                    className={`mb-0 border-2 ${formState.errors.operation && 'border-red-500'}`}
                />
                {formState.errors.operation && !formState.isValid &&
                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                        {formState.errors.operation.type === 'required' && t('send-constancy-alert-operation-required')}
                    </span>
                }
            </div>

            <div id="new-subscription-suggest-section" style={{background:'none'}} className=" bg-opacity-100 suggest flex p-0 mb-40 cursor-pointer" onClick={() => modalIsOpen(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <label className="block ml-2 font-bold self-center cursor-pointer">
                    {t('send-constancy-label-where')}
                </label>
            </div>

            <button disabled={!formState.isValid} type="submit" className={formState.isValid ? ' mb-10' : 'opacity-50 cursor-not-allowed mb-10'} >
                {t('send-constancy-button-submit')}
            </button>

            <Modal isOpen={isOpen} isFrom={'send-constancy'} modalData={modalData} modalIsOpen={modalIsOpen} />

        </form>
    );
}

export default SendConstancy;