import React, { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import OperationsFilter from './OperationsFilter';
import Footer from '../home/Footer';
import i18n from '../../i18n';

const MySwal = withReactContent(Swal)
const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const OperationsInvestmentSummary = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { state } = useLocation();
    const [fund, setFund] = useState({});

    useEffect(() => {
        //console.log(state);

        let fund = {
            fund_id: state.fund_id,
            name: i18n.language === 'es' ? state.fund.name.es : state.fund.name.en,
            ruc: state.fund.ruc,
            account_number: state.fund.account_number,
            cci: state.fund.cci,
        };
        //console.log(fund)
        setFund(fund);

        //setFunds({ ...aux_fund });
        /*let aux_fund = {
            fund_id: state.id,
            name: i18n.language === 'es' ? ApiResponse.data.name.es : ApiResponse.data.name.en,
            how_it_works: i18n.language === 'es' ? ApiResponse.data.how_it_works.es : ApiResponse.data.how_it_works.en,
            description: JSON.parse(i18n.language === 'es' ? ApiResponse.data.description_es : ApiResponse.data.description_en),
            links: JSON.parse(i18n.language === 'es' ? ApiResponse.data.links_es : ApiResponse.data.links_en),
            profitability_description: i18n.language === 'es' ? ApiResponse.data.profitability_description.es : ApiResponse.data.profitability_description.en,
            profitability: JSON.parse(i18n.language === 'es' ? ApiResponse.data.profitability_es : ApiResponse.data.profitability_en),
            profitability_title: i18n.language === 'es' ? ApiResponse.data.profitability_title.es : ApiResponse.data.profitability_title.en,
            terms: JSON.parse(i18n.language === 'es' ? ApiResponse.data.terms_es : ApiResponse.data.terms_en),
            ruc: ApiResponse.data.ruc,
            account_number: ApiResponse.data.account_number,
            cci: ApiResponse.data.cci,
            description_extra_content: i18n.language === 'es' ? ApiResponse.data.description_extra_content.es : ApiResponse.data.description_extra_content.en,
        };*/
        return () => { }
    }, [])

    const goBack = () => {
        navigate('/home', { state: { ...state } });
    };

    const goToWithdrawalSelector = () => {
        navigate('/operations/withdrawal/type', { state: { ...fund } });
    }

    const goToNewSubscription = () => {
        navigate('/operations/subscription/new', { state: { ...fund } });
    }


    return (
        <div className="flex flex-col">

            <div className="flex mt-10 ml-6">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => goBack()} className="h-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>


            <div className="flex flex-col px-8">
                <h1 className="mt-3">{t('operations-summary-title')}{ i18n.language === 'es' ? state.fund.name.es : state.fund.name.en}</h1>

                <div className="w-full mt-10 sm:px-0">
                    <OperationsFilter type={'SUMMARY'} />
                </div>

                <div style={{ position: 'relative' }} className='self-center mt-8'>
                    <img
                        style={{ position: 'absolute', top: '2px', left: '2px' }}
                        src="/images/icon-line1.svg"
                        alt="icon-hand-success"
                        className="object-contain h-40 w-full"
                    />

                    <img
                        style={{ position: 'relative' }}
                        src="/images/icon-line2.svg"
                        alt="icon-hand-success"
                        className="object-contain h-40 w-full"
                    />

                </div>

                <button className="w-full self-center font-normal text-sm mt-14" onClick={goToNewSubscription}>{t('operations-summary-button-add-money')}</button>
                <button className="w-full self-center font-normal text-sm  mb-36 bg-whiteLilac-500 text-asparagus-500  hover:bg-transparent" onClick={goToWithdrawalSelector}>{t('operations-summary-button-withdraw-money')}</button>
            </div>
            <Footer />
        </div>
    );
}

export default OperationsInvestmentSummary;