import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

const FaceRequirements = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {

        //console.log(state)
        return () => { }
    }, [])

    const goBack = () => {

        if (state.from === 'document-id-registration') {
            navigate('/onboarding/validate-identity/reverse-document-registration', { state: { ...state } });
            return;
        }

        navigate('/home', { state: { ...state } });
    };

    const goToFaceAdvancedAccessRequest = () => {
        navigate('/advanced-access/face/request', { state: { ...state } });
    };

    return (
        <div className="flex flex-col px-5">

            <div style={{ marginLeft: '-2px' }}  className=" mt-6 mb-10 flex flex-row content-center">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => goBack()} className="h-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                {state.from === 'document-id-registration' &&
                    <p className="text-sm ml-2">{t('face-requirements-label-back')}</p>
                }
            </div>

            <div className="flex flex-col">
                <h1 className="mt-4 ml-2 not-italic font-bold text-2xl">{t('face-requirements-title')}</h1>

                <img
                    src="/images/icon-face-scanner-advanced-access.svg"
                    alt="email-sent"
                    className="object-contain h-36 w-full mt-14"
                />

                <h2 className="not-italic font-bold text-xl leading-5 self-center mt-16 text-gray-700">{t('face-requirements-label-prepare-for-selfie')}</h2>

                <p className="not-italic font-normal text-base leading-5 text-gray-700 self-center mt-5">{t('face-requirements-label-look-in-front')}</p>

                <button className="w-full my-10 self-center font-normal text-sm mt-32" onClick={goToFaceAdvancedAccessRequest}>{t('face-requirements-button-submit')}</button>
            </div>




        </div>
    );
}

export default FaceRequirements;