import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFunds } from '../../services/ApiService';
import i18n from '../../i18n';

const InvestorRecommendedFunds = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { from } = useParams();
    const [funds, setFunds] = useState([])
    const [funds2, setFunds2] = useState([])


    useEffect(() => {
        //console.log(state.fund)
        setFunds([{...state.fund}]);
        setFunds2(state.fund2 === null ? [] : [{...state.fund2}]);
        return () => { }
    }, [])

    const goNext = () => {
        navigate('/know-your-investor-profile/investor-recommended-funds/know-all-our-funds', { state: {...state} });
    }

    const goToAllFunds = () => {
        navigate('/know-your-investor-profile/investor-recommended-funds/know-all-our-funds', { state: {...state} });
    }

    const goToFund = (id) => {
        navigate('/fund-information', { state: {id:id} });
    }

    const goToHome = () => {
        navigate('/home');
    }

    const Back = () => {
        navigate('/know-your-investor-profile')
    }

    return (
        <div className="flex flex-col px-6" >

            <div className="mt-10 mb-10 ml-2">
                <h1 className="text-xl font-bold ">{t('investor-recommended-funds-title')}</h1>
            </div>

            {funds.map((fund, i) => {
                return (
                    <div key={i} className='mb-5 bg-white rounded-lg py-6'>

                        <div className='ml-6 font-semibold text-base'>
                            {i18n.language === 'es' ? fund.name.es : fund.name.en}
                        </div>

                        <button onClick={() => goToFund(fund.id)} 
                            type='button' 
                            className="underline ml-2 text-left  bg-transparent text-asparagus-500 text-base  hover:bg-transparent">
                            {t('investor-recommended-funds-button-more-details')}
                        </button>
                    </div>
                )
            })}
            {funds2.map((fund, i) => {
                return (
                    <div key={i} className='mb-5 bg-white rounded-lg py-6'>

                        <div className='ml-6 font-semibold text-base'>
                            {i18n.language === 'es' ? fund.name.es : fund.name.en}
                        </div>

                        <button onClick={() => goToFund(fund.id)} 
                            type='button' 
                            className="underline ml-2 text-left  bg-transparent text-asparagus-500 text-base  hover:bg-transparent">
                            {t('investor-recommended-funds-button-more-details')}
                        </button>
                    </div>
                )
            })}
            
            <div className="flex justify-between cursor-pointer home-primary-button mt-5 p-4 rounded-2xl tracking-wide" onClick={()=>goToAllFunds()}>
                     <h2 className="grow self-center">{t('investor-recommended-funds-button-know-our-mutual-fund')}</h2>
                     <img className="grow-0 object-contain px-1 h-8 self-center" src="/images/icon-arrow-right.svg"   />
            </div>

            <div className='mb-24'></div>

            <button onClick={() => goNext()} className="mb-0.5" type="button" >
                {t('investor-recommended-funds-button-submit')}
            </button>

            <button onClick={() => goToHome()} type='button' className="bg-transparent text-asparagus-500 text-base  hover:bg-transparent">
                {t('investor-recommended-funds-button-go-to-home')}
            </button>

        </div>
    );
}

export default InvestorRecommendedFunds;