import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import { SignIn } from "../../services/AuthService";
import { useForm } from "react-hook-form";
import LockOutModal from '../tailwind-components/LockOutModal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)
const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const Login = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);
	const { dispatch } = React.useContext(AuthContext);
	const { register, handleSubmit, getValues, formState } = useForm({
		mode: "onChange"
	});


	const onSubmit = async () => {

		try {

			let SingInResponse = await SignIn({ email: getValues('email'), password: getValues('password') }, dispatch);
			let user = SingInResponse.user
			let investor = SingInResponse.investor
			//console.log('user',user);
			//console.log('user',investor);
			//console.log('mystate.user',mystate.user);
			/*setTimeout(() => {
				navigate(user.investor_id !== null ? '/your-security-is-first' :'/register-dni-data');
			}, 2500);*/

			if (user.investor_id === null) {
				navigate('/register-dni-data')
				return;
			}else{
				if(investor.onboarding_completed_at === null){
					navigate('/your-security-is-first');
				}else{
					navigate(investor.investor_profile === null ? '/know-your-investor-profile' : '/home');
				}
				return;
			}



		} catch (error) {
			console.log(error);
			if(error.data.key === "LOGIN_LOCKOUT"){
				modalIsOpen(true);
			}   
		}
	}

    const modalIsOpen = (value) => {
        /*if (modalData.length === 0) {
            return;
        }*/

        setIsOpen(value)
    }

	const forgotPassword = () => {
		navigate('/forgot-password')
	}

	const Register = () => {
		navigate('/register')
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-7 mt-10" >

			<div className="mt-9 mb-5">
				<img
					src="/images/flip-logo-color.svg"
					alt="logo"
					className="object-contain h-32 w-full"
				/>
			</div>

			<div className="self-center  font-bold mb-6">
				<h1>{t('login-label-sign-in')}</h1>
			</div>

			<div className="mb-3">
				<label className="block ml-2 text-gray-500 text-sm mb-2.5" htmlFor="email">
					{t('login-email-input')}
				</label>
				<input
					type="text"
					defaultValue=''
					{...register("email", {
						required: true, pattern: regex,
					})}
					className={`border-2 ${formState.errors.email && 'border-red-500'}`}
					placeholder={t('login-email-placeholder')}
				//style={{...styles.inputBorder}}
				/>
				{formState.errors.email && !formState.isValid &&
					<span className="text-red-500 mt-0 ml-2 text-xs mb-2">
						{formState.errors.email.type === 'required' && t('register-alert-required-email')}
						{formState.errors.email.type === 'pattern' && t('register-input-email-alert')}
					</span>
				}
			</div>

			<div className="mb-2.5">
				<label className="block ml-2 text-gray-500 text-sm  mb-2.5" htmlFor="password">
					{t('login-password-input')}
				</label>
				<input
					defaultValue=''
					{...register("password", { required: true, minLength: 8 })}
					className={`mb-0 border-2 ${formState.errors.password && 'border-red-500'}`}
					type={'password'}
					placeholder={t('login-password-placeholder')}
				/>
				{formState.errors.password &&
					<span className="text-red-500 mt-0 ml-2 text-xs mb-2">
						{formState.errors.password.type === 'required' && t('register-alert-required-password')}
						{formState.errors.password.type === 'minLength' && t('register-input-password-alert')}
					</span>
				}
			</div>

			<div className="mb-14 self-center">
				<button onClick={() => forgotPassword()} type='button' className="underline font-normal bg-whiteLilac-500 text-gray-400  hover:bg-transparent py-1 px-1 text-sm  focus:outline-none">
					{t('login-label-forgot-password')}
				</button>
			</div>

			<button disabled={!formState.isValid} type="submit" className={formState.isValid ? ' mb-0.5' : 'opacity-50 cursor-not-allowed mb-0.5'}>
				{t('login-button-sign-in')}
			</button>

			<button onClick={() => Register()} type='button' className="bg-whiteLilac-500 text-asparagus-800  hover:bg-transparent">
				{t('login-button-register')}
			</button>
			<LockOutModal isOpen={isOpen} isFrom={'login'} modalData={modalData} modalIsOpen={modalIsOpen} />
		</form>
	);
}

export default Login;