import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import { getPeruvianBanks, getBankAccountTypes, storeInvestorBankAccount, getInvestorBankAccount, showInvestorBankAccount, deleteInvestorBankAccount, getBankLogo } from '../../../services/ApiService'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../i18n';
import Select from 'react-select';
import Config from '../../../constants/Config'


const customStyles = {
    control: (base, state) => ({
        ...base,
        '&:hover': { borderColor: 'black' }, // border style on hover
        border: '1px solid lightgray', // default border color
        boxShadow: 'none', // no box-shadow
    })
};

const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const AddBankAccount = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [bank, setBank] = useState([]);
    const [type, setType] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [bank_account, setBankAccount] = useState([]);
    const [disableButton, setDisableButton] = useState({ add: false, delete: false });
    const { register, handleSubmit, trigger, watch, reset, control, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    const [options, setOptions] = useState([]);
    //console.log(watch())

    const onSubmit = async () => {
        try {
            setDisableButton({ ...disableButton, add: true });
            let data = {
                account_type: getValues('type'), peruvian_bank_id: getValues('bank'), account_number: getValues('account'),
                display_name: getValues('put_name_account'), cci: getValues('cci'), investor_declared_ownership_of_account: getValues('declare')
            }

            let ApiResponse = await storeInvestorBankAccount(mystate.user.investor_id, data);
            window.scroll({left:0,top:document.body.scrollHeight,behavior:'smooth'});
            //(ApiResponse.data);

            //finding the exact bank to show in the htlm list
            let my_bank = bank.find(element => element.id === parseInt(ApiResponse.data.peruvian_bank_id));
            ApiResponse.data.my_bank = i18n.language === 'es' ? my_bank.name.es : my_bank.name.en;


            let new_bank = bank_account.length === 0 ? [{ ...ApiResponse.data }] : [...bank_account, { ...ApiResponse.data }];
            //console.log(new_bank)

            setBankAccount(new_bank);

            /*Toast.fire({
                icon: 'success',
                title: t('add-bank-account-alert-success-account-added'),
            })*/

        } catch (error) {
            //console.log(error);
            Toast.fire({
                icon: 'error',
                title: t('add-bank-account-alert-error-account-added'),
            })
        }

        reset();
        setValue('declare', false)//doing this because there is a package error 
        setDisableButton({ ...disableButton, add: false });
    }

    useEffect(() => {
        const init = async () => {
            try {

                let ApiResponse = await getBankAccountTypes(i18n.language === 'es' ? 'es' : 'en');
                //console.log(ApiResponse.data)

                Object.entries(ApiResponse.data).map((item, i) => {
                    if (i === 0) {
                        //console.log(item)
                        setValue('type', item[0]);
                    }
                })

                let ApiResponse2 = await getPeruvianBanks();
                //console.log(ApiResponse2.data)

                setValue('bank', ApiResponse2.data[0].id.toString());

                let options = await Promise.all(ApiResponse2.data.map(async (item, i) => {
                    //console.log(item) i18n.language === 'es' ? item[1].name.es : item[1].name.en
                    //console.log(item);

                    let logo = item.logo === null ? '' : await getImage(item.logo);

                    return {
                        value: item.id,
                        label:
                            <div className='flex'>
                                {item.logo === null ?
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                    :
                                    <div><img src={logo} alt="user-created" className="object-contain h-6 w-6" /></div>
                                }

                                <div className='flex-1 ml-3'>{i18n.language === 'es' ? item.name.es : item.name.en}</div>
                            </div>
                    }

                }))

                //console.log(options);
                setOptions(options)
                setType(ApiResponse.data);
                setBank(ApiResponse2.data);


                let getInvestorBankAccountResponse = await getInvestorBankAccount(mystate.user.investor_id);

                getInvestorBankAccountResponse.data.map((item) => {
                    let my_bank = ApiResponse2.data.find(element => element.id === item.peruvian_bank_id);
                    item.my_bank = i18n.language === 'es' ? my_bank.name.es : my_bank.name.en;
                })

                //console.log(getInvestorBankAccountResponse.data)

                Object.keys(getInvestorBankAccountResponse.data).length !== 0 && setBankAccount(getInvestorBankAccountResponse.data)
            } catch (error) {
                //console.log(error)
            }

        }

        init();

        return () => { }
    }, [])


    const deleteBankAccount = async (i, id) => {

        if (disableButton.delete) {
            return;
        }
        setDisableButton({ ...disableButton, delete: true });

        try {
            let ApiResponse = await deleteInvestorBankAccount(mystate.user.investor_id, id);
            console.log(ApiResponse.data);

            let new_bank_account_array = [...bank_account];
            new_bank_account_array.splice(i, 1);
            setBankAccount(new_bank_account_array);

            /*Toast.fire({
                icon: 'success',
                title: t('occupation-alert-success-submit'),
            })*/

        } catch (error) {
            //console.log(error)
            Toast.fire({
                icon: 'error',
                title: t('occupation-alert-error-submit'),
            })
        }
        setDisableButton({ ...disableButton, delete: false });
    }

    const goNext = () => {
        navigate(state.from !== 'account' ? '/onboarding/congratulations' : '/account', { state: { from: 'add-bank-account' } });
    }

    const getImage = async (logo) => {
        let new_logo = await getBankLogo(logo);
        //console.log(new_logo)
        //console.log(new_logo.data.url)
        return new_logo.data.url;
    }

    const Back = () => {
        navigate(state.from !== 'account' ? '/your-security-is-first' : '/account')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-5 ml-2">
                <h1 className="text-3xl font-bold ">{t('add-bank-account-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-700 text-sm ml-2">{t('add-bank-account-description-label-2')}</p>
            </div>

            <div id="suggest-section" className="suggest flex mb-8">
                <svg className="h-5 w-5 text-ming-400 " viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 7H11V5H9V7ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z" fill="#0D4C57" />
                </svg>
                <label className="block ml-2 mt-1 flex-1">
                    {t('add-bank-account-suggest-label')}
                </label>
            </div>

            <div className='card-information-pep-participation-section flex flex-col mb-32'>
                <div id="add-bank-account-type-section" className={'mb-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="type">
                        {t('add-bank-account-input-type-label')}
                    </label>
                    <div className='flex'>
                        <div className='flex-1 mr-1'>
                            {<select {...register("type")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                                {Object.entries(type).map((item, i) => {
                                    return (<option key={i} className="mt-3" value={item[0]}>{item[1]}</option>)
                                })}
                            </select>}
                        </div>
                    </div>
                </div>

                <div id="account-section" className={'mb-3'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="account">
                        {t('add-bank-account-input-account-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('add-bank-account-input-account-placeholder')}
                        defaultValue=''
                        {...register("account", { required: true, pattern: /^[0-9]+$/, minLength: 5 })}
                        className={`mb-0 border-2 ${formState.errors.account && 'border-red-500'}`}
                    //style={{...styles.inputBorder}}
                    />
                    {formState.errors.account && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.account.type === 'required' && t('add-bank-account-alert-account-required')}
                            {formState.errors.account.type === 'pattern' && t('add-bank-account-alert-account-only-numbers')}
                            {formState.errors.account.type === 'minLength' && t('add-bank-account-alert-account-max-length')}
                        </span>
                    }
                </div>

                <div id="cci-section" className={'mb-3'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="account">
                        {t('add-bank-account-input-cci-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('add-bank-account-input-cci-placeholder')}
                        defaultValue=''
                        {...register("cci", { required: true, pattern: /^[0-9]+$/, maxLength: 20 })}
                        className={`mb-0 border-2 ${formState.errors.cci && 'border-red-500'}`}
                    //style={{...styles.inputBorder}}
                    />
                    {formState.errors.cci && !formState.isValid &&
                       
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.cci.type === 'required' && t('add-bank-account-alert-cci-required')}
                            {formState.errors.cci.type === 'pattern' && t('add-bank-account-alert-account-only-numbers')}
                            {formState.errors.cci.type === 'maxLength' && t('add-bank-account-alert-account-max-length-cci')}
                        </span>
                    }
                </div>

                <div id="add-bank-account-bank-section" className={'mb-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="bank">
                        {t('add-bank-account-input-bank-label')}
                    </label>
                    <div className='flex'>
                        <div className='flex-1 mr-1'>
                            <Select
                                {...register("bank")}
                                defaultValue={selectedOption}
                                onChange={value => setValue('bank', value.value)}
                                options={options}
                                className="border-black"
                                styles={customStyles}

                            />
                            {/*<select {...register("bank")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                                {Object.entries(bank).map((item, i) => {
                                    //console.log(item) i18n.language === 'es' ? item[1].name.es : item[1].name.en
                                    return (
                                        <option key={i} style={{ lineHeight: 15 }} height={'20px'} className="mt-3 h-12" value={item[1].id}
                                            label={'<div>hello</div>'} />
                                    )
                                })}
                            </select>*/}
                        </div>
                    </div>
                </div>


                <div id="put-name-account-section" className={'mb-5'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="put_name_account">
                        {t('add-bank-account-input-put-name-account-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('add-bank-account-input-put-name-account-placeholder')}
                        defaultValue=''
                        {...register("put_name_account")}
                        className={`border-2`}
                    //style={{...styles.inputBorder}}
                    />
                </div>

                <div id="register-dni-data-policy-section" className='mb-3 flex '>
                    <div className="">
                        <input style={{ appearance: 'auto' }}
                            {...register("declare", { required: true })}
                            className={`mb-0 ml-0.5 border-2 w-5 h-6 self-center ${formState.errors.declare && 'border-red-500'}`}
                            type="checkbox"
                        />
                    </div>
                    <div >
                        <label className='ml-2 text-xs'>
                            {`${t('add-bank-account-checkbox-declare-label')}`}
                        </label>
                    </div>
                </div>
                <button type="submit" disabled={!formState.isValid || disableButton.add} className={`my-10 information-pep-save-business-button self-end mt-5 w-1/2 ${(!formState.isValid || disableButton.add) && 'opacity-50 cursor-not-allowed'}`} >
                    {t('add-bank-account-button')}
                </button>
            </div>
            <div className="flex justify-center mb-8">
                <div className='flex-1  self-center pr-2'>
                    <hr />
                </div>

                <div className='flex-1'>
                    <div className='flex justify-center'>
                        {t('add-bank-account-label-bank-accounts')}
                    </div>
                </div>

                <div className='flex-1 self-center pl-2'>
                    <hr />
                </div>

            </div>

            {bank_account.length > 0 &&
                <div className='mb-3'>
                    {bank_account.map((item, i) => {
                        return (
                            <div key={i} className="flex mb-3 pep-information-business-shadow bg-white rounded-xl py-2 px-3 ">
                                <div className='flex-1'>
                                    <div className='mb-2'>{item.account_number}</div>
                                    <div className='text-gray-400 text-sm'>
                                        {item.my_bank} {item.display_name !== null && '- ' + item.display_name}
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div className='flex h-full justify-end items-center'>
                                        <div className={`cursor-pointer ${disableButton.delete && 'opacity-50 cursor-not-allowed'}`} onClick={() => deleteBankAccount(i, item.id)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }

            <button disabled={bank_account.length === 0} type="button" onClick={() => goNext()} className={`my-10 ${bank_account.length === 0 && 'opacity-50 cursor-not-allowed'}`} >
                {t('add-bank-account-button-continue')}
            </button>


        </form>
    );
}

export default AddBankAccount;