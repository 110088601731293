import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import { getTransferFunds } from '../../services/ApiService';

const TransferToFlip = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [transferData, setTransferData] = useState([]);
    const [isLoadingComplete, setLoadingComplete] = useState(false);

    useEffect(() => {
        //console.log(state)

        const init = async () => {
            //console.log(state);
            let apiResponse = await getTransferFunds(state.fund_id, state.peruvian_bank_id);
            // console.log(apiResponse.data)
            setTransferData(apiResponse.data)
            setLoadingComplete(true);
        }
        init();
        return () => { }

    }, [])

    const goNext = () => {
        navigate('/operations/subscription/send-constancy', { state: { ...state } });
    }

    const copyAmount = () => {
        navigator.clipboard.writeText(state.amount);
    }

    const copyOperationNumber = () => {
        navigator.clipboard.writeText(state.account_number);
    }

    const copyCCI = () => {
        navigator.clipboard.writeText(state.cci);
    }

    const copyRuc = () => {
        navigator.clipboard.writeText(state.ruc);
    }
    /*const goToMovements = () => {
        navigate('/movements/history');
    }*/

    const Back = () => {
        let fund_ids = [];

        if (mystate.investor.investor_profile.fund_id) fund_ids.push(mystate.investor.investor_profile.fund_id)
        if (mystate.investor.investor_profile.fund_id2) fund_ids.push(mystate.investor.investor_profile.fund_id2);
        //console.log(fund_ids.includes(state.fund_id));

        navigate(fund_ids.includes(state.fund_id) ? '/operations/subscription/new'
            : '/operations/subscription/fund-do-not-fit-profile', { state: { ...state } });
    }


    return (
        <div className="flex flex-col px-6 mt-9" >

            <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                <div className='ml-5 mt-0.5 text-sm'>{t('transfer-to-flip-step-2')}</div>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('transfer-to-flip-title')}</h1>
            </div>

            <div className="mb-2">
                <p className="text-gray-500 text-sm ml-2">{t('transfer-to-flip-label-description')}</p>
            </div>

            <div className="mb-8">
                <p className="text-sherpaBlue-500 font-semibold  text-xs ml-2">{t('transfer-to-flip-label-flip-do-not')}</p>
            </div>

            <div className={`mb-28 card-withdrawal-process flex flex-col  text-gray-700`}>

                <div className="font-bold text-lg mb-4  text-center">
                    {t('transfer-to-flip-label-amount-to-transfer')}
                </div>

                <div className="flex font-bold   text-lg mb-4 self-center">

                    <div className='mr-1.5'>{t('transfer-to-flip-label-usd')}</div>

                    <div className='mr-3'>{Number(state.amount).toLocaleString('en-US', { maximumFractionDigits: 2 })}</div>

                    <div className="cursor-pointer" onClick={() => copyAmount()}>
                        <img
                            src="/images/icon-copy.svg"
                            alt="icon-copy"
                            className="object-contain mt-1 h-4 w-full"
                        />
                    </div>

                </div>

                <hr style={{ borderTop: '1px solid #ffffff' }} className="m-0 w-full border-2  mb-4" />
                {!isLoadingComplete ?
                    <div className="flex h-14 my-7 justify-center self-center items-center">
                        <div style={{ borderTopColor: 'transparent' }} className="w-16 h-16 border-8  border-asparagus-500 border-solid rounded-full animate-spin" />
                    </div>
                    :
                    <>

                        <div className="text-xs mb-0.5">
                            {transferData.hasOwnProperty('peruvian_bank') ?
                                <>
                                    {t('transfer-to-flip-label-number-of-the-account')}
                                    {transferData.peruvian_bank.name.es}
                                    {' '}
                                    {t('transfer-to-flip-label-of')}
                                    {state.name}
                                </>
                                :
                                <>
                                    {t('transfer-to-flip-label-number-of-the-bsp')}
                                    {state.name}
                                </>
                            }
                        </div>

                        <div className="flex flex-col font-bold text-sm mb-4">


                            <div className='flex mb-1'>
                                <div className='mt-0.5 mr-3'>
                                    {transferData.account_number}
                                </div>
                                <div className="cursor-pointer self-center" onClick={() => copyOperationNumber()}>
                                    <img
                                        src="/images/icon-copy.svg"
                                        alt="icon-copy"
                                        className="object-contain h-4 w-full"
                                    />
                                </div>
                            </div>

                            <div className='flex mb-1'>
                                <div className='mt-0.5 mr-3'>
                                    CCI: {transferData.cci}
                                </div>
                                <div className="cursor-pointer self-center" onClick={() => copyCCI()}>
                                    <img
                                        src="/images/icon-copy.svg"
                                        alt="icon-copy"
                                        className="object-contain h-4 w-full"
                                    />
                                </div>
                            </div>

                            <div className='flex'>
                                <div className='mt-0.5 mr-3'>
                                    RUC: {transferData.ruc}
                                </div>
                                <div className="cursor-pointer self-center" onClick={() => copyRuc()}>
                                    <img
                                        src="/images/icon-copy.svg"
                                        alt="icon-copy"
                                        className="object-contain h-4 w-full"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

            <button onClick={() => goNext()} className={'mb-28'}>
                {t('transfer-to-flip-button-understood')}
            </button>

        </div>
    );
}

export default TransferToFlip;