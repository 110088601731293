import React, { useEffect, useState } from "react";
import {
    Routes,
    Switch,
    Route,
    Link,
    useHistory,
    useLocation,
    useNavigate,
    Navigate
} from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";

import Spinner from '../tailwind-components/Spinner';
import Home from "../home/Home";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import ForgotPassword from "../Auth/ForgotPassword";
import ResetPassword from "../Auth/ResetPassword";
import SendCode from "../Auth/SendCode";
import ValidatedEmail from "../Auth/ValidatedEmail";
import NotFound from '../tailwind-components/NotFound';
import RegisterDNIData from "../investor-register/RegisterDNIData";
import YourSecurityIsFirst from '../onboarding-home/YourSecurityIsFirst';
import Congratulations from '../investor-register/Congratulations';
import AddBankAccount from '../onboarding-home/bank-account/AddBankAccount';
import FundInformation from '../FundInformation/Index';
import PersonalData from '../onboarding-home/additional-information/PersonalData';
import RecidenceData from '../onboarding-home/additional-information/ResidenceData';
import Occupation from '../onboarding-home/additional-information/OcupationData';
import ValidateIdentity from '../onboarding-home/validate-identity/ValidateIdentity';
import DocumentIdRegistration from '../onboarding-home/validate-identity/DocumentIdRegistration';
import Operations from '../Operations/Operations';
import OperationsFilter from '../Operations/OperationsFilter';
import OperationsInvestmentSummary from '../Operations/OperationsInvestmentSummary';
import NewSubscription from '../subscription/New';
import WithdrawalSelector from '../withdrawal/TypeSelector';
import NewWithdrawal from '../withdrawal/New';
import PoliticalExposedPerson from '../onboarding-home/additional-information/PoliticalExposedPerson';
import FamilyOfPoliticalExposedPerson from '../onboarding-home/additional-information/FamilyOfPoliticalExposedPerson';
import FaceAdvancedAccessRequirements from '../advanced-access/FaceRequirements';
import FaceAdvancedAccessRequest from '../advanced-access/Request';
import OnboardingCongratulations from '../onboarding-home/OnboardingCongratulations';
import PepInformation from '../onboarding-home/pep-information/PepInformation';
import ValidatePepParticipation from '../onboarding-home/pep-information/ValidatePepParticipation';
import PepParticipations from '../onboarding-home/pep-information/PepParticipations';
import GoToPepFamily from '../onboarding-home/additional-information/GoToPepFamily';
import Movements from '../movements/Movements';
import MovementsDashboard from '../movements/MovementsDashboard';
import WithdrawalProcess from '../withdrawal/WithdrawalProcess';
import TransferToFlip from '../subscription/TransferToFlip';
import SendConstancy from '../subscription/SendConstancy';
import SendedConstancy from '../subscription/SendedConstancy';
import Logout from "../Auth/Logout";
import GoToPepInformation from "../onboarding-home/additional-information/GoToPepInformation";
import Account from "../home/Account";
import Menu from "../home/Menu";
import KnowYourInvestorProfile from "../know-your-investor-profile/KnowYourInvestorProfile";
import InvestorQuestions from "../know-your-investor-profile/InvestorQuestions";
import InvestorRecommendedFunds from "../know-your-investor-profile/InvestorRecommendedFunds";
import KnowAllOurFunds from "../know-your-investor-profile/KnowAllOurFunds";
import InvestorFitsWith from "../know-your-investor-profile/InvestorFitsWith";
import WrongFund from "../subscription/WrongFund";
import DoYouHaveRelatedPep from "../onboarding-home/additional-information/DoYouHaveRelatedPep";
import MovementsDetail from "../movements/MovementsDetail";
import ChangePassword from "../home/ChangePassword";
import ReactGA from 'react-ga4';
import Config from '../../constants/Config';
import IncompleteValidations from "../advanced-access/IncompleteValidations";
import PictureErrors from "../advanced-access/PictureErrors";
import BackDocumentIdRegistration from "../onboarding-home/validate-identity/BackDocumentIdRegistration";
import Maintenance from "../maintenance/Maintenance";
import { getMaintenanceStatus } from '../../services/ApiService';

const { REACT_APP_TRACKING_ID } = process.env;

//import MovementsDetail from '../movements/MovementsDetail';
const tracking_routes = [
    '/register',
    '/register-dni-data',
    '/your-security-is-first',
    '/onboarding/validate-identity/document-registration',
    '/advanced-access/face/requirements',
    '/advanced-access/face/request',
    '/know-your-investor-profile/investor-recommended-funds',
    '/operations/subscription/sended-constancy',
]

const MyRouter = () => {
    const location = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const [showRoutes, setShowRoutes] = useState(true);

    useEffect(() => {
        ReactGA.initialize(REACT_APP_TRACKING_ID);
        
        const init = async () => {
            //console.log(mystate);
            try {
                let ApiResponse = await getMaintenanceStatus()
                //console.log(ApiResponse); 

                if (location.pathname === '/logout') return;
                if (location.pathname === '/forgot-password') return;
    
                if (mystate.userToken === null) {
    
                    if (location.pathname !== '/login') {
                        navigate('/login');
                        return;
                    }
    
                } else {
    
                    if (mystate.user !== null) {
                        if (mystate.user.investor_id === null) {
                            navigate('/register-dni-data')
                            return;
                        } else {
                            if (mystate.investor.onboarding_completed_at === null) {
                                navigate('/your-security-is-first');
                            } else {
                                navigate(mystate.investor.investor_profile === null ? '/know-your-investor-profile' : '/home');
                            }
                            return;
                        }
    
                    }
    
    
                }
            } catch (error) {
                //console.log(error)
                if(error.response.data.key === "FLIP_APP_MAINTENANCE_MODE_ENABLED"){
                    navigate('/maintenance')
                    return;
                }
            } finally {
                setShowRoutes(false);
            }


        }

        init();

        return () => { };
    }, []);

    useEffect(() => {
        //console.log(Config.ProductionBaseUrl + location.pathname + location.search)
        if (tracking_routes.indexOf(location.pathname + location.search) !== -1) {
            //console.log('tracking..')
            ReactGA.send({ hitType: "pageview", page: Config.ProductionBaseUrl + location.pathname + location.search });
        }

        return () => { };
    }, [location]);

    if (showRoutes) {
        return (
            <Spinner type={''} />
        )
    } else {

        return (

            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/send-code" element={<SendCode />} />
                <Route path="/validated-email" element={<ValidatedEmail />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/register" element={<Register />} />
                <Route path="/home" element={<Home />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/" element={<Home />} />
                <Route path="/know-your-investor-profile" element={<KnowYourInvestorProfile />} />
                <Route path="/know-your-investor-profile/investor-questions" element={<InvestorQuestions />} />
                <Route path="/know-your-investor-profile/investor-fits-with" element={<InvestorFitsWith />} />
                <Route path="/:from/investor-recommended-funds" element={<InvestorRecommendedFunds />} />
                <Route path="/:from/investor-recommended-funds/know-all-our-funds" element={<KnowAllOurFunds />} />
                {/*<Route path="/movements" element={<Movements />} />*/}
                <Route path="/movements/history" element={<MovementsDashboard />} />
                <Route path="/movements/:id/detail" element={<MovementsDetail />} />
                <Route path="/fund-information" element={<FundInformation />} />
                <Route path="/register-dni-data" element={<RegisterDNIData />} />
                <Route path="/your-security-is-first" element={<YourSecurityIsFirst />} />
                <Route path="/congratulations" element={<Congratulations />} />
                <Route path="/onboarding/congratulations" element={<OnboardingCongratulations />} />
                <Route path="/onboarding/add-bank-account" element={<AddBankAccount />} />
                <Route path="/onboarding/additional-information/personal-data" element={<PersonalData />} />
                <Route path="/onboarding/additional-information/residence-data" element={<RecidenceData />} />
                <Route path="/onboarding/additional-information/occupation-data" element={<Occupation />} />
                <Route path="/onboarding/additional-information/political-exposed-person" element={<PoliticalExposedPerson />} />
                <Route path="/onboarding/additional-information/political-exposed-person/do-you-have-related-pep" element={<DoYouHaveRelatedPep />} />
                <Route path="/onboarding/additional-information/political-exposed-person/go-to-pep-information" element={<GoToPepInformation />} />
                <Route path="/onboarding/additional-information/political-exposed-person/go-to-pep-family" element={<GoToPepFamily />} />
                <Route path="/onboarding/additional-information/family-of-political-exposed-person" element={<FamilyOfPoliticalExposedPerson />} />
                <Route path="/onboarding/additional-information/political-exposed-person/pep-information" element={<PepInformation />} />
                <Route path="/onboarding/additional-information/political-exposed-person/pep-information/validate-user-participation" element={<ValidatePepParticipation />} />
                <Route path="/onboarding/additional-information/political-exposed-person/pep-information/participations" element={<PepParticipations />} />
                <Route path="/onboarding/validate-identity" element={<ValidateIdentity />} />
                <Route path="/onboarding/validate-identity/document-registration" element={<DocumentIdRegistration />} />
                <Route path="/onboarding/validate-identity/reverse-document-registration" element={<BackDocumentIdRegistration />} />
                <Route path="/operations" element={<Operations />} />
                <Route path="/operations/investment-summary" element={<OperationsInvestmentSummary />} />
                <Route path="/account" element={<Account />} />
                <Route path="/account/change-password" element={<ChangePassword />} />
                <Route path="/operations/subscription/new" element={<NewSubscription />} />
                <Route path="/operations/subscription/fund-do-not-fit-profile" element={<WrongFund />} />
                <Route path="/operations/subscription/transfer-to-flip" element={<TransferToFlip />} />
                <Route path="/operations/subscription/send-constancy" element={<SendConstancy />} />
                <Route path="/operations/subscription/sended-constancy" element={<SendedConstancy />} />
                <Route path="/operations/withdrawal/type" element={<WithdrawalSelector />} />
                <Route path="/operations/withdrawal/:withdrawalType/new" element={<NewWithdrawal />} />
                <Route path="/operations/withdrawal/process" element={<WithdrawalProcess />} />
                <Route path="/advanced-access/face/requirements" element={<FaceAdvancedAccessRequirements />} />
                <Route path="/advanced-access/face/request" element={<FaceAdvancedAccessRequest />} />
                <Route path="/advanced-access/face/incomplete-validations" element={<IncompleteValidations />} />
                <Route path="/advanced-access/face/errors" element={<PictureErrors />} />
                <Route path="/maintenance" element={<Maintenance />} />
                {/*<Route path="*" element={<NotFound />} />*/}

            </Routes>

        );
    }
}

export default MyRouter;
