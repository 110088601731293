import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import all locales
import en from './lang/en.json';
import es from './lang/es.json';

const options = {
	order: ['querystring', 'navigator'],
	lookupQuerystring: 'lng',
}


i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: 'es',
		//detection: options,
		resources: {
			en: {
				translation: en
			},
			es: {
				translation: es
			},
		}
	});

	i18n.changeLanguage('es');
  
export default i18n;


//guide https://locize.com/blog/how-to-internationalize-react-i18next/