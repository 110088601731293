import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { useTranslation} from 'react-i18next';


const Congratulations = () => {
    const { t } = useTranslation();
	const navigate = useNavigate();
    const { state } = useLocation();

	useEffect(() => {
		//console.log(state);
		return () => {}
	}, [])
    
	const goNext = (user) => {
		navigate('/your-security-is-first', { state: {...state} });
	}

	return (
		<div className="flex flex-col px-6 mt-9" >

			<div className="mt-11 mb-5 ml-2">
				<h1 className="text-3xl font-bold ">{t('congratulations-title')}</h1>
			</div>

            <div className="mb-16">
                <p className="text-gray-500 text-sm ml-2">{t('congratulations-label')}</p>
            </div>

            <div className="mb-28 self-center">
				<img
				src="/images/user-created.svg"
				alt="user-created"
				className="object-contain h-40 w-full"
				/>
            </div>


            <button onClick={()=>goNext()} type="button" >
                {t('congratulations-button')}
            </button>


		</div>
	);
}

export default Congratulations;