import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import { getCountries, storeInvestorAddress, getPeruvianStates, getPeruvianProvinces, getPeruvianDistricts } from '../../../services/ApiService'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../i18n';


const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const ResidenceData = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [fields, setFields] = useState({ countries: [], states: [], provinces: [], districts: [] });
    const [disabledInputs, setDisabledInputs] = useState(false);
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    //console.log(watch())
    watch()//doesn't work without this

    const onSubmit = async () => {
        setDisabledInputs(true);
        try {
  
            //console.log(getValues());

            let data = {
                country_id: getValues('country'),
                state: getValues('state'),
                city: i18n.language === 'es' ? fields.provinces.find(element => element.id.toString() === getValues('city')).name.es : fields.provinces.find(element => element.id.toString() === getValues('city')).name.en,
                district: i18n.language === 'es' ? fields.districts.find(element => element.id.toString() === getValues('district')).name.es : fields.provinces.find(element => element.id.toString() === getValues('district')).name.en,
                address: getValues('direction'),
                zip_code: getValues('zip_code'),
            }

            //console.log(data);
            let ApiResponse = await storeInvestorAddress(mystate.user.investor_id, data)
            //console.log(ApiResponse);

            /*Toast.fire({
                icon: 'success',
                title: t('residence-alert-success-submit'),
                willClose: () => {
                    navigate('/onboarding/additional-information/occupation-data');
                }
            })*/

            navigate('/onboarding/additional-information/occupation-data');

        } catch (error) {

            Toast.fire({
                icon: 'error',
                title: t('residence-alert-error-submit'),
            })
        } finally {
            setDisabledInputs(false);
        }

    }

    useEffect(() => {

        const refreshData = async () => {
            
            if (fields.states.length === 0) return;

            try {
                setDisabledInputs(true);
                if (getValues('country') === '169') {
                    //console.log(fields)

                    setValue('isPeruSelected', true);
    
                    let getPeruvianProvincesResponse = await getPeruvianProvinces(fields.states[0].id.toString());
                    let getPeruvianDistrictsResponse = await getPeruvianDistricts(fields.states[0].id.toString(), getPeruvianProvincesResponse.data[0].id.toString());
                    //console.log(getPeruvianProvincesResponse.data)
                    //console.log(getPeruvianDistrictsResponse.data)
                    setValue('state', fields.states[0].id.toString());
                    setValue('city', getPeruvianProvincesResponse.data[0].id.toString());
                    setValue('district', getPeruvianDistrictsResponse.data[0].id.toString());
                    setFields({ ...fields, provinces: getPeruvianProvincesResponse.data, districts: getPeruvianDistrictsResponse.data });

                    //setValue('states', item[0]);
                } else {
                    setValue('isPeruSelected', false);
                    setValue('state', '');
                    setValue('city', '');
                    setValue('district', '');
                }   
            } catch (error) {
                //console.log(error);
            } finally {
                setDisabledInputs(false);
            }

        }

        refreshData();

        return () => { }
    }, [getValues('country')])

    const refreshProvinces = async (e) => {//changing state value and refreshing Provinces (citys)

        setDisabledInputs(true);
        //resetField('district')
        try {
            setValue('state', e.target.value);

            let getPeruvianProvincesResponse = await getPeruvianProvinces(e.target.value);
            let getPeruvianDistrictsResponse = await getPeruvianDistricts(e.target.value, getPeruvianProvincesResponse.data[0].id.toString());
            //console.log(getPeruvianProvincesResponse.data)
            //console.log(getPeruvianDistrictsResponse.data)
            setValue('city', getPeruvianProvincesResponse.data[0].id.toString());

            //console.log(getPeruvianProvincesResponse)
            setFields({ ...fields, provinces: getPeruvianProvincesResponse.data, districts: getPeruvianDistrictsResponse.data });
            setValue('district', getPeruvianDistrictsResponse.data[0].id.toString());

        } catch (error) {
           // console.log(error);
        } finally {
            setDisabledInputs(false);
        }

    }

    const refreshDistricts = async (e) => {//changing city value and refreshing districts
        try {
            setDisabledInputs(true);
            //resetField('district')
            //console.log(getValues('city'))

            setValue('city', e.target.value);
            let getPeruvianDistrictsResponse = await getPeruvianDistricts(getValues('state'), e.target.value);
            //console.log(getPeruvianDistrictsResponse.data)
            setFields({ ...fields, districts: getPeruvianDistrictsResponse.data });
            setValue('district', getPeruvianDistrictsResponse.data[0].id.toString());
        } catch (error) {
            //console.log(error);
        } finally {
            setDisabledInputs(false);
        }

    }

    useEffect(() => {
        const init = async () => {
            setDisabledInputs(true);
            let getCountriesResponse = await getCountries();
            let data_to_shift_index = getCountriesResponse.data.findIndex(element => element.code === 'PE');
            let initial_data = getCountriesResponse.data[0];
            getCountriesResponse.data[0] = getCountriesResponse.data[data_to_shift_index];
            getCountriesResponse.data[data_to_shift_index] = initial_data;

            let getPeruvianStatesResponse = await getPeruvianStates();
            let lima_state = getPeruvianStatesResponse.data.findIndex(element => element.name.es === 'Lima'); //finding lima state, and putting it at first place 
            getPeruvianStatesResponse.data[0] = getPeruvianStatesResponse.data.splice(lima_state, 1, getPeruvianStatesResponse.data[0])[0];
            //console.log(getPeruvianStatesResponse.data)
            //console.log(getCountriesResponse.data[0].id.toString())

            let getPeruvianProvincesResponse = await getPeruvianProvinces(getPeruvianStatesResponse.data[0].id.toString());
            //console.log(getPeruvianProvincesResponse.data)
            let getPeruvianDistrictsResponse = await getPeruvianDistricts(getPeruvianStatesResponse.data[0].id.toString(), getPeruvianProvincesResponse.data[0].id.toString());
            //console.log(getPeruvianDistrictsResponse.data)


            setValue('isPeruSelected', true);
            setValue('country', getCountriesResponse.data[0].id.toString());
            setValue('state', getPeruvianStatesResponse.data[0].id.toString());
            setValue('city', getPeruvianProvincesResponse.data[0].id.toString());
            setValue('district', getPeruvianDistrictsResponse.data[0].id.toString());
            setFields({ countries: getCountriesResponse.data, states: getPeruvianStatesResponse.data, provinces: getPeruvianProvincesResponse.data, districts: getPeruvianDistrictsResponse.data });
            setDisabledInputs(false);
        }

        init();
        return () => { }
    }, [])

    const Back = () => {
        navigate('/your-security-is-first')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('residence-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('residence-description-label')}</p>
            </div>

            <div id="country-section" className={'mb-3'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="country">
                    {t('residence-input-country-label')}
                </label>
                <div className='flex'>
                    <div className='flex-1 mr-1'>
                        {<select {...register("country")} disabled={disabledInputs} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                            {fields.countries.length !== 0 &&
                                <>
                                    {fields.countries.map((item, i) => {
                                        return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                    })}
                                </>
                            }

                        </select>}
                    </div>
                </div>
            </div>

            <div id="state-section" className={'mb-3'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="state">
                    {t('residence-input-department-label')}
                </label>
                <div className='flex'>
                    <div className='flex-1 mr-1'>
                        {getValues('isPeruSelected') === true ?
                            <>
                                {<select {...register("state")} onChange={e => refreshProvinces(e)} disabled={disabledInputs} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                                    {fields.states.length !== 0 &&
                                        <>
                                            {fields.states.map((item, i) => {
                                                return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                            })}
                                        </>
                                    }

                                </select>}
                            </>
                            :
                            <>
                                <input
                                    type="text"
                                    placeholder={t('residence-input-state-placeholder')}
                                    defaultValue=''
                                    {...register("state", { required: true })}
                                    className={`mb-0 border-2 ${formState.errors.state && 'border-red-500'}`}
                                />
                                {formState.errors.state && !formState.isValid &&
                                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                                        {formState.errors.state.type === 'required' && t('residence-alert-state-required')}
                                    </span>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>

            {/**Provinces */}
            <div id="city-section" className={'mb-3'}>

                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="city">
                    {t('residence-input-city-label')}
                </label>

                {getValues('isPeruSelected') === true ?
                    <>
                        {<select {...register("city")} onChange={e => refreshDistricts(e)} disabled={disabledInputs} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                            {fields.provinces.length !== 0 &&
                                <>
                                    {fields.provinces.map((item, i) => {
                                        return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                    })}
                                </>
                            }
                        </select>}
                    </>
                    :
                    <>
                        <input
                            type="text"
                            placeholder={t('residence-input-city-placeholder')}
                            defaultValue=''
                            {...register("city", { required: true })}
                            className={`mb-0 border-2 ${formState.errors.city && 'border-red-500'}`}
                        />
                        {formState.errors.city && !formState.isValid &&
                            <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                                {formState.errors.city.type === 'required' && t('residence-alert-city-required')}
                            </span>
                        }
                    </>
                }

            </div>


            <div id="district-section" className={'mb-3'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="district">
                    {t('residence-input-district-label')}
                </label>

                {getValues('isPeruSelected') === true ?
                    <>
                        {<select {...register("district")} disabled={disabledInputs} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">
                            {fields.districts.length !== 0 &&
                                <>
                                    {fields.districts.map((item, i) => {
                                        return (<option key={i} className="mt-3" value={item.id}>{i18n.language === 'es' ? item.name.es : item.name.en}</option>)
                                    })}
                                </>
                            }

                        </select>}
                    </>
                    :
                    <>
                        <input
                            type="text"
                            placeholder={t('residence-input-district-placeholder')}
                            defaultValue=''
                            {...register("district", { required: true })}
                            className={`mb-0 border-2 ${formState.errors.district && 'border-red-500'}`}
                        />
                        {formState.errors.district && !formState.isValid &&
                            <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                                {formState.errors.district.type === 'required' && t('residence-alert-district-required')}
                            </span>
                        }
                    </>
                }
            </div>

            <div id="direction-section" className={'mb-3'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="direction">
                    {t('residence-input-direction-label')}
                </label>
                <input
                    type="text"
                    placeholder={t('residence-input-direction-placeholder')}
                    defaultValue=''
                    {...register("direction", { required: true })}
                    className={`mb-0 border-2 ${formState.errors.direction && 'border-red-500'}`}
                />
                {formState.errors.direction && !formState.isValid &&
                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                        {formState.errors.direction.type === 'required' && t('residence-alert-direction-required')}
                    </span>
                }
            </div>

            <div id="zip_code-section" className={'mb-12'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="zip_code">
                    {t('residence-input-zip_code-label')}
                </label>
                <input
                    type="text"
                    placeholder={t('residence-input-zip_code-placeholder')}
                    defaultValue=''
                    {...register("zip_code", { required: true })}
                    className={`mb-0 border-2 ${formState.errors.zip_code && 'border-red-500'}`}
                />
                {formState.errors.zip_code && !formState.isValid &&
                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                        {formState.errors.zip_code.type === 'required' && t('residence-alert-zip_code-required')}
                    </span>
                }
            </div>

            <button type="submit" disabled={!formState.isValid || disabledInputs} className={`mb-10 ${(!formState.isValid || disabledInputs) && 'opacity-50 cursor-not-allowed'}`}>
                {t('residence-button')}
            </button>

        </form>
    );
}

export default ResidenceData;