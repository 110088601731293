import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import { getContactInformation } from '../../services/ApiService';
import Footer from './Footer';
import i18n from '../../i18n';
import config from '../../constants/Config';

const Account = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mystate, dispatch } = React.useContext(AuthContext);
    const [contact_information, setContactInformation] = useState([]);

    useEffect(() => {

        const init = async () => {
            //console.log(mystate);
            let getContactInformationResponse = await getContactInformation(i18n.language === 'es' ? 'es' : 'en');
            //console.log(getContactInformationResponse.data)
            setContactInformation(getContactInformationResponse.data);
        }

        init();
        return () => { }

    }, []);

    const goToAgreement = () => {
        const win = window.open(config.agreementUrl, '_blank');
        win.focus();

    }

    const goToFAQ = () => {
        const win = window.open(config.faqUrl, '_blank');
        win.focus();

    }

    const goToChangePassword = () => {
        navigate('/account/change-password', { state: { from: 'account' } });
    }

    const goToMyBankAccounts = () => {
        navigate('/your-security-is-first', { state: { from: 'account' } });
    }

    const Back = () => {
        navigate('/home')
    }

    const Logout = async () => {

        try {
            dispatch({ type: 'SIGN_OUT' })

            navigate('/login');


        } catch (e) {
            //console.log(e);
        }
    }

    return (
        <div className="flex flex-col" >
            <div className="flex flex-col px-6 mt-9" >
                <div className="">
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </div>
                <div className="mt-3 mb-12 ml-3">
                    <h1 className="text-3xl font-bold ">{t('account-title')}</h1>
                </div>
                

                <button className={`mb-5 card-option-account`}>
                    <div className='rounded-full bg-gray-400 h-6 w-6' />
                    <div className="flex-1 ml-3 ">
                        <p className="font-bold text-sm">{mystate.investor.name}</p>
                    </div>
                </button>

                <button className={`mb-5 card-option-account`}>
                    {/* <div className='rounded-full bg-gray-400 h-6 w-6' /> */}
                    <div className="flex-1 ml-3 ">
                        <p className="font-bold text-sm">
                            {t('account-profile')} { i18n.language === 'es' ? mystate.investor.investor_profile.name.es :  mystate.investor.investor_profile.name.en}
                        </p>
                    </div>
                </button>

                <button onClick={() => goToChangePassword()} className={`mb-5 card-option-account`}>
                    {/* <img className={`object-contain h-6 self-center`} src="/images/icon-profile-bank-accounts.svg" /> */}
                    <div className="flex-1 ml-3 ">
                        <p className="font-bold text-sm">{t('account-button-change-password')}</p>
                    </div>
                    <div className=''>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                </button>

                <button onClick={() => goToMyBankAccounts()} className={`mb-5 card-option-account`}>
                    <img className={`object-contain h-6 self-center`} src="/images/icon-profile-bank-accounts.svg" />
                    <div className="flex-1 ml-3 ">
                        <p className="font-bold text-sm">{t('account-button-my-bank-accounts')}</p>
                    </div>
                    <div className=''>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                </button>

                <div className="mb-8">
                    <p className="text-sherpaBlue-500 text-sm ml-2 ">{t('account-label-contact')}</p>
                </div>

                <button onClick={() => { return; }} className={`mb-5 card-option-account`}>
                    <img className={`object-contain h-6 self-center`} src="/images/icon-profile-phone.svg" />
                    <div className="flex-1 ml-3 ">
                        <p className="font-bold text-sm">{contact_information.phone}</p>
                    </div>
                </button>

                <button onClick={() => { return; }} className={`mb-8 card-option-account`}>
                    <img className={`object-contain h-6 self-center`} src="/images/icon-profile-mail.svg" />
                    <div className="flex-1 ml-3 ">
                        <p className="font-bold text-sm">{contact_information.email}</p>
                    </div>
                </button>

                <hr style={{ marginTop: '2px', borderTop: '2px solid #cccccc' }} />


                <button onClick={() => goToFAQ()} className={`mt-8 my-5 card-option-account`}>
                    <div className="flex-1 ml-3 ">
                        <p className="font-bold text-sm">{t('account-button-faq')}</p>
                    </div>
                    <div className=''>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                </button>

                <button onClick={() => goToAgreement()} className={`mb-5 card-option-account`}>
                    <div className="flex-1 ml-3 ">
                        <p className="font-bold text-sm">{t('account-button-contract')}</p>
                    </div>
                    <div className=''>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                </button>


                <button onClick={() => Logout()} className={`mb-36 card-option-account`}>
                    <img className={`object-contain h-6 self-center`} src="/images/icon-profile-sign-off.svg" />
                    <div className="flex-1 ml-3 ">
                        <p className="font-bold text-sm">{t('account-button-logout')}</p>
                    </div>
                </button>
            </div>

            <Footer />

        </div>
    );
}

export default Account;