import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import { showInvestorTransaction, listInvestorTransactionLogs } from '../../services/ApiService';

const MovementsDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [investment, setInvestment] = useState({ value: null, log: [] })

    useEffect(() => {

        const init = async () => {
            try {
                //console.log(id);
                let showInvestorTransactionResponse = await showInvestorTransaction(mystate.user.investor_id, id);
                //console.log(showInvestorTransactionResponse.data);
                let listInvestorTransactionLogsResponse = await listInvestorTransactionLogs(mystate.user.investor_id, id);
                //console.log(listInvestorTransactionLogsResponse.data);
                setInvestment({ value: showInvestorTransactionResponse.data, log: listInvestorTransactionLogsResponse.data });

            } catch (error) {
                //console.log(error);
            }
        }

        init();
        return () => { }
    }, []);


    const Back = () => {
        navigate('/movements/history', { state: { ...state } });
    }

    return (
        <div className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-1 ml-2">
                <h1 className="text-2xl font-bold ">
                    {investment.value !== null &&
                        <>
                            {investment.value.type === "SUBSCRIPTION" ?
                                t('movements-detail-title-suscription')
                                :
                                t('movements-detail-title-rescue')
                            }
                        </>
                    }


                </h1>
            </div>

            <div className="mb-3 ml-2">
                {investment.value !== null &&
                    <p className="text-sm">
                        {t('movements-detail-label-code')} {investment.value.flip_code}
                    </p>
                }
            </div>

            {investment.value !== null &&
                <div className=' ml-2'>
                    <div className='mb-7 font-semibold text-gray-700'>
                        {investment.value.type !== "TOTAL_RESCUE" && t('movements-detail-label-usd')}  {Number(investment.value.amount).toLocaleString('en-US', { maximumFractionDigits: 2 })} {investment.value.type === "TOTAL_RESCUE" && t('movements-detail-label-quota')}
                    </div>

                    {/*  {t('movements-add-money-label-investment')} */}
                    {investment.log.map((item, i) => {
                        return (
                            <div key={i} className="flex">
                                {investment.log.length === 1 ?
                                    <>
                                        <div className='flex'>
                                            <svg width="15" height="187" viewBox="0 0 15 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 91L8 55.137L8 14" stroke="#828282" strokeLinecap="square" strokeDasharray="3" />
                                                <path d="M8 172.004L8 139.401L8 102.004" stroke="#828282" strokeLinecap="square" strokeDasharray="3" />
                                                <circle cx="7" cy="7" r="6.5" fill="#0D4C57" stroke="#0D4C57" />
                                                <circle cx="8" cy="98" r="7" fill="#828282" />
                                                <circle cx="8" cy="180" r="7" fill="#828282" />
                                            </svg>
                                        </div>

                                        <div style={{ marginTop: '-1.5px' }} className=" ml-2.5">

                                            <div className='text-sm text-sherpaBlue-500 mb-3 font-bold'>
                                                {item.status === "PENDING" &&
                                                    t('movements-detail-label-pending')
                                                }
                                            </div>
                                            <div className='text-sm text-gray-500 ml-2 mb-9'>
                                                {
                                                    new Date(item.created_at).toLocaleTimeString('es-VE') + ' ' +
                                                    new Date(item.created_at).toLocaleDateString('es-VE')

                                                }
                                            </div>
                                            <div className='text-sm text-gray-500 pt-0.5 mb-14 font-bold'>
                                                {investment.value.type === "SUBSCRIPTION" ?
                                                    t('movements-detail-label-approved-subscription') :
                                                    t('movements-detail-label-approved-rescue')
                                                }
                                            </div>
                                            <div className='text-sm text-gray-500 pt-1.5 font-bold'>
                                                {investment.value.type === "SUBSCRIPTION" ?
                                                    t('movements-detail-label-completed-subscription') :
                                                    t('movements-detail-label-completed-rescue')
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='flex'>
                                            {/* <img className={`object-contain h-max self-center`} src="/images/movement.svg" /> */}
                                            {i !== investment.log.length - 1 ?
                                                <svg width="14" height="92" viewBox="0 0 14 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 91L8 55.137L8 14" stroke={"#0D4C57"} strokeLinecap="square" strokeDasharray="3" />
                                                    <circle cx="7" cy="7" r="6.5" fill={"#0D4C57"} />{/* {`${investment.value.type === 'SUSCRIPTION' ? "#0D4C57" : "#7f7f80"}`} */}
                                                </svg>
                                                :
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="7" cy="7" r="6.5" fill="#0D4C57" stroke="#0D4C57" />
                                                </svg>
                                            }


                                        </div>
                                        <div style={{ marginTop: '-1.5px' }} className=" ml-2.5">

                                            <div className='text-sm text-sherpaBlue-500 mb-3 font-bold'>
                                                {item.status === "PENDING" &&
                                                    t('movements-detail-label-pending')
                                                }
                                                {item.status === "APPROVED" &&
                                                    <>
                                                        {investment.value.type === "SUBSCRIPTION" ?
                                                            t('movements-detail-label-approved-subscription') :
                                                            t('movements-detail-label-approved-rescue')
                                                        }
                                                    </>
                                                }
                                                {item.status === "COMPLETED" &&
                                                    <>
                                                        {investment.value.type === "SUBSCRIPTION" ?
                                                            t('movements-detail-label-completed-subscription') :
                                                            t('movements-detail-label-completed-rescue')
                                                        }
                                                    </>
                                                }
                                                {item.status === "REJECTED" &&
                                                    t('movements-detail-label-rejected')
                                                }

                                            </div>
                                            <div className='text-sm text-gray-500 ml-2'>
                                                {
                                                    new Date(item.created_at).toLocaleTimeString('es-VE') + ' ' +
                                                    new Date(item.created_at).toLocaleDateString('es-VE')

                                                }
                                            </div>

                                        </div>
                                    </>
                                }


                            </div>
                        )
                    })}


                </div>

            }
        </div>
    );
}

export default MovementsDetail;