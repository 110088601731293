import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import { storeInvestorPEPInformation } from '../../../services/ApiService'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../i18n';


const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const ValidatePepParticipation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate,dispatch } = React.useContext(AuthContext);
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    //console.log(watch())

    useEffect(() => {
        //console.log(state)
        return () => {
            
        }
    }, [])

    const onSubmit = async () => {
        try {
            let radio_value = getValues('option')  === 'true' ? true : false
            let data = {
                ...state,
                has_25_percent_of_a_company: radio_value
            }
            //console.log(data);
            let ApiResponse = await storeInvestorPEPInformation(mystate.user.investor_id, data)
            //console.log(ApiResponse.data);
            dispatch({ type: 'UPDATE_PEP_ID',id:ApiResponse.data.id})

            /*Toast.fire({
                icon: 'success',
                title: t('occupation-alert-success-submit'),
            })

            setTimeout(() => {
                navigate(radio_value ? '/onboarding/additional-information/political-exposed-person/pep-information/participations'
                :'/onboarding/congratulations',{state:{from:'validate-pep-participation'}});
            }, 2500);*/

            navigate(radio_value ? '/onboarding/additional-information/political-exposed-person/pep-information/participations'
            :'/onboarding/additional-information/political-exposed-person/do-you-have-related-pep',{state:{from:'validate-pep-participation'}});
            
        } catch (error) {

            Toast.fire({
                icon: 'error',
                title: t('occupation-alert-error-submit'),
            })
        }

    }

    const Back = () => {
        navigate('/onboarding/additional-information/political-exposed-person/pep-information')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('pep-information-two-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('pep-information-two-description-label')}</p>
            </div>


            <div id="option-section" className='mb-32 flex flex-col'>
               
               <div className="flex mb-3 ml-1.5">
                   <input style={{ appearance: 'auto' }}
                       {...register("option", { required: true })}
                       defaultValue={true}
                       className={`border-2  w-5 h-5  ${formState.errors.option && 'border-red-500'}`}
                       type="radio"
                   />
                   <label className="ml-2 text-xs self-center" htmlFor="option">
                       {t('pep-information-two-input-option-radio-yes')}
                   </label>
               </div>

               <div className="flex ml-1.5">
                   <input style={{ appearance: 'auto' }}
                       {...register("option", { required: true })}
                       defaultValue={false}
                       className={`mb-0 border-2 w-5 h-5`}
                       type="radio"
                   />
                   <label className="ml-2 text-xs self-center" htmlFor="option">
                       {t('pep-information-two-input-option-radio-no')}
                   </label>
               </div>
           </div>


           <button disabled={!formState.isValid} type="submit" className={formState.isValid ? ' mb-10' : 'opacity-50 cursor-not-allowed mb-10'} >
                {t('pep-information-two-button')}
            </button>

        </form>
    );
}

export default ValidatePepParticipation;