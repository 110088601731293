import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactCodeInput from "react-code-input";
import { grantAdvancedAccessPin,requestAdvancedAccessPin } from '../../services/ApiService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const props = {
    inputStyle: {
        fontFamily: 'monospace',
        margin: '3px',
        MozAppearance: 'textfield',
        //display: 'inline-flex',
        borderRadius: '7px',
        height: '45px',
        padding: '5px',
        textAlign: 'center',
        fontSize: '25px',
        borderWidth: 1,

    },
    inputStyleInvalid: {
        fontFamily: 'monospace',
        margin: '3px',
        MozAppearance: 'textfield',
        //display: 'inline-flex',
        borderRadius: '7px',
        height: '45px',
        padding: '5px',
        textAlign: 'center',
        fontSize: '25px',
        color: 'rgb(185, 74, 72)',
        backgroundColor: 'rgb(242, 222, 222)',
        borderWidth: 1,
    },
}

const SendCode = () => {
    const { t } = useTranslation();
    const [validations, setValidations] = useState({ inputEmail: false, inputConfirmEmail: false, inputPassword: false, inputConfirmPassword: false })
    const navigate = useNavigate();
    const [isPinCodeValid, setIsPinCodeValid] = useState(true);
    const [pinCode, setPinCode] = useState("");
    const { state } = useLocation();
    const { email } = state;


    const checkPinCode = async () => {
        try {
            if (pinCode.length !== 6) {
                return;
            }

            let apiResponse = await grantAdvancedAccessPin(email,pinCode);
           // console.log(apiResponse.data)

            setIsPinCodeValid(true);

            /*Toast.fire({
                icon: 'success',
                title:  t('send-code-success-pin-alert'),
            })

            setTimeout(() => {
                navigate('/validated-email', { state: {...state} });
            }, 1000);*/

            navigate('/validated-email', { state: {...state} });
            
        } catch (error) {

            setIsPinCodeValid(false);
            Toast.fire({
                icon: 'error',
                title: t('send-code-error-pin-alert'),
            })  

        }

    };

    const handlePinChange = pinCode => {
        setPinCode(pinCode);
    };

    const sendCode = (user) => {

    }

    const reSend = async () => {
        try {

            await requestAdvancedAccessPin(email);   
            Toast.fire({
                icon: 'success',
                title:  t('send-code-success-email-resended-alert'),
            })

        } catch (error) {

            Toast.fire({
                icon: 'error',
                title:  t('send-code-error-email-resended-alert'),
            })
            


        }

    }
    
    const Back = () => {
        navigate('/register')
    }

    return (
        <div className="flex flex-col px-4 mt-9" >

            <div className="mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mb-8 ml-2">
                <h1 className="text-2xl font-bold">{t('send-code-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('send-code-label-to-email')} {email}</p>
            </div>

            <div className="mb-4">
                <label className="text-xl  block ml-2 text-gray-500 mb-2">
                    {t('send-code-label-code-verification')}
                </label>
            </div>

            <div className="mb-6 flex">
                <ReactCodeInput
                    id="pinCode"
                    type="number"
                    className='flex-1 code-input'
                    isValid={isPinCodeValid}
                    fields={6}
                    onChange={handlePinChange}
                    value={pinCode}
                    {...props}
                />
            </div>


            <div className="mb-20">
                <label className="block ml-2 text-gray-500 text-sm mb-2">
                    {t('send-code-label-did-not-reach-you')}
                    <button onClick={() => reSend()} className="underline inline font-normal bg-whiteLilac-500 text-gray-500  hover:bg-transparent py-1 px-1 text-sm  focus:outline-none">
                        {t('send-code-resend')}
                    </button>
                </label>
            </div>

            <button onClick={() => checkPinCode()} type="button" className={`mt-3 mb-12 ${pinCode.length !== 6 && 'opacity-50 cursor-not-allowed'}`}>
                {t('send-code-button')}
            </button>



        </div>
    );
}

export default SendCode;