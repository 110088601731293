import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { storeNewPassword } from '../../services/ApiService';
import { useForm, useWatch } from "react-hook-form";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AuthContext } from '../../services/AuthContext';
import { SignIn } from "../../services/AuthService";

const MySwal = withReactContent(Swal)
const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})


const ChangePassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { dispatch } = React.useContext(AuthContext);
    const { register, handleSubmit, trigger, watch, reset, control, setValue,getValues, formState } = useForm({
        mode: "onChange"
    });


    useEffect(() => {

        return () => { }
    }, [])


    const onSubmit = async (data) => {

        try {
            console.log(data)

            let apiResponse = await storeNewPassword(data);

            Toast.fire({
                icon: 'success',
                title: t('change-password-alert-success'),
            })

            /*setTimeout(() => {
                navigate('/register-dni-data', { state: { ...data } });
            }, 2500);*/

        } catch (error) {
            try {
                console.log(error.response.data)
                if (error.response.data.key[0] === 'INVALID_PASSWORD') {
                    Toast.fire({
                        icon: 'error',
                        title: t('change-password-alert-current-password-invalid'),
                    })
                    return;
                }


            } catch (error) {
                //console.log(error)
            }

        }

    };


    const Back = () => {
        navigate('/account')
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-6 mt-9" >


            <div className="mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mb-5 ml-2">
                <h1 className="text-2xl font-bold">{t('change-password-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('change-password-label-description')}</p>
            </div>

            <div className="ml-1 mb-14">

                <div id="current-password-section" className={'mb-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="current_password">
                        {t('change-password-input-password-current')}
                    </label>
                    <input
                        type="password"
                        defaultValue=''
                        {...register("current_password", { required: true, minLength: 8 })}
                        className={`mb-0 border-2 ${formState.errors.password && 'border-red-500'}`}
                        placeholder={t('change-password-input-password-placeholder-current')}
                    />
                    {formState.errors.current_password &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.current_password.type === 'required' && t('change-password-input-current-password-alert-required')}
                            {formState.errors.current_password.type === 'minLength' && t('change-password-input-current-password-alert-min')}
                        </span>
                    }
                </div>

                <div id="password-section" className={'mb-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="password">
                        {t('change-password-input-password')}
                    </label>
                    <input
                        type="password"
                        defaultValue=''
                        {...register("password", { required: true, minLength: 8, onChange: async () => await trigger("password_confirmation") })}
                        className={`mb-0 border-2 ${formState.errors.password && 'border-red-500'}`}
                        placeholder={t('change-password-input-password-placeholder-new')}
                    />
                    {formState.errors.password &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.password.type === 'required' && t('change-password-input-new-password-alert-required')}
                            {formState.errors.password.type === 'minLength' && t('change-password-input-new-password-alert-min')}
                        </span>
                    }
                </div>

                <div id="password-confirm-section" className={'mb-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="confirm-password-input">
                        {t('change-password-input-password-confirm')}
                    </label>
                    <input
                        type="password"
                        name="confirm-password-input"
                        defaultValue=''
                        {...register("password_confirmation", {
                            required: true, validate: async (value) => {
                                return await value === watch('password')
                            }
                        })}
                        className={`mb-0 border-2 ${formState.errors.password_confirmation && 'border-red-500'}`}
                        placeholder={t('change-password-input-password-confirm-placeholder')}
                    />
                    {formState.errors.password_confirmation &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.password_confirmation.type === 'required' && t('change-password-input-new-password-confirmation-alert-required')}
                            {formState.errors.password_confirmation.type === 'validate' && t('change-password-input-new-password-confirmation-alert-validate')}
                        </span>
                    }
                </div>

            </div>


            <button disabled={!formState.isValid} type="submit" className={`mb-12 ${!formState.isValid && 'opacity-50 cursor-not-allowed'}`}>
                {t('change-password-button-change-password')}
            </button>



        </form>
    );
}

export default ChangePassword;