import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkUserPinAdvancedAccess, requestAdvancedAccessPin, registerUser, checkCodeExistence } from '../../services/ApiService';
import { useForm, useWatch } from "react-hook-form";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AuthContext } from '../../services/AuthContext';
import { SignIn } from "../../services/AuthService";

const MySwal = withReactContent(Swal)
const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexCode = /^[0-9a-zA-Z]+$/;

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})



const Register = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { dispatch } = React.useContext(AuthContext);
    const { register, handleSubmit, trigger, watch, reset, control, setValue, getValues, formState, setError } = useForm({
        mode: "onChange"
    });


    useEffect(() => {

        if (state !== null) {
            reset(state);
            onSubmit(state);
            //trigger();
        }
        return () => { }
    }, [])


    const onSubmit = async (data) => {

        try {

            data.email = data.email.toLowerCase();
            data.confirm_email = data.confirm_email.toLowerCase();


            let apiResponse = await checkUserPinAdvancedAccess(data.email);

            
            let registerResponse = await registerUser({ email: data.email, password: data.password, ref_code: data.ref_code });
            
            let form_data = {email:getValues('email'),password:getValues('password')}
            //console.log(form_data);
            await SignIn(form_data,dispatch);

            /*Toast.fire({
                icon: 'success',
                title: t('register-alert-success'),
            })

            setTimeout(() => {
                navigate('/register-dni-data', { state: { ...data } });
            }, 2500);*/

            navigate('/register-dni-data', { state: { ...data } });

        } catch (error) {
            try {
                if (error.response.data.key === 'ADVANCED_ACCESS_REQUIRED') {
                    await requestAdvancedAccessPin(data.email);
                    Toast.fire({
                        icon: 'success',
                        title: t('register-alert-advanced-access-required'),
                        timer: 4000,
                        willClose: () => {
                            navigate('/send-code', { state: { ...data } });
                        }
                    })
                    return;
                }

                if (error.response.data.errors.hasOwnProperty('email')) {
                    Toast.fire({
                        icon: 'error',
                        title: error.response.data.errors.email,
                    })
                    return;
                }
                
            } catch (error) {
                //console.log(error)
            }

        }

    };


    const Back = (user) => {
        navigate('/login')
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-6 mt-9" >


            <div className="mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mb-2 ml-2">
                <h1 className="text-2xl font-bold">{t('register-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('register-label-start')}</p>
            </div>

            <div className="ml-1 mb-14">
                <div id="email-section" className={'mb-3'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="email">
                        {t('register-input-email')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('register-input-email-placeholder')}
                        defaultValue=''
                        {...register("email", {
                            required: true, pattern: regex,
                            onChange: async (e) => await trigger("confirm_email")
                        })}
                        className={`border-2 ${formState.errors.email && 'border-red-500'}`}
                    //style={{...styles.inputBorder}}
                    />
                    {formState.errors.email && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.email.type === 'required' && t('register-alert-required-email')}
                            {formState.errors.email.type === 'pattern' && t('register-input-email-alert')}
                        </span>
                    }
                </div>

                <div id="confirm-email-section" className={'mb-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="confirm-email-input">
                        {t('register-input-email-confirm')}
                    </label>
                    <input
                        type="text"
                        name="confirm-email-input"
                        defaultValue=''
                        {...register("confirm_email", {
                            required: true, validate: async (value) => {
                                //console.log(value);
                                await watch('email');
                                return await value.toLowerCase() === watch('email').toLowerCase();
                            }
                        })}
                        className={`border-2 ${formState.errors.confirm_email && 'border-red-500'}`}
                        placeholder={t('register-input-email-confirm-placeholder')}
                    />
                    {formState.errors.confirm_email &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.confirm_email.type === 'required' && t('register-alert-required-email')}
                            {formState.errors.confirm_email.type === 'validate' && t('register-input-email-confirm-alert')}
                        </span>
                    }
                </div>

                <div id="password-section" className={'mb-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="password">
                        {t('register-input-password')}
                    </label>
                    <input
                        type="password"
                        defaultValue=''
                        {...register("password", { required: true, minLength: 8, onChange: async () => await trigger("confirm_password") })}
                        className={`mb-0 border-2 ${formState.errors.password && 'border-red-500'}`}
                        placeholder={t('register-input-password-placeholder')}
                    />
                    {formState.errors.password &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.password.type === 'required' && t('register-alert-required-password')}
                            {formState.errors.password.type === 'minLength' && t('register-input-password-alert')}
                        </span>
                    }
                </div>

                <div id="password-confirm-section" className={'mb-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="confirm-password-input">
                        {t('register-input-password-confirm')}
                    </label>
                    <input
                        type="password"
                        name="confirm-password-input"
                        defaultValue=''
                        {...register("confirm_password", {
                            required: true, validate: async (value) => {
                                return await value === watch('password')
                            }
                        })}
                        className={`mb-0 border-2 ${formState.errors.confirm_password && 'border-red-500'}`}
                        placeholder={t('register-input-password-confirm-placeholder')}
                    />
                    {formState.errors.confirm_password &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.confirm_password.type === 'required' && t('register-alert-required-password')}
                            {formState.errors.confirm_password.type === 'validate' && t('register-input-password-confirm-alert')}
                        </span>
                    }
                </div>

                <div id="code-section" className={'mb-3'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="ref_code">
                        {t('register-input-code')} (Opcional)
                    </label>
                    <input
                        type="text"
                        defaultValue=''
                        {...register("ref_code", {
                            required: false, 
                            pattern: regexCode,
                            minLength: 6,
                            maxLength: 6,
                            onChange: async (e) => { 
                                setTimeout(async () => {
                                    if(!formState.errors.ref_code && getValues('ref_code')){
                                        let form_data = {ref_code: getValues('ref_code')}
                                        try {
                                            const code = await checkCodeExistence(form_data);

                                        } catch (error) {
                                            setError('ref_code', {type: 'invalid'})
                                        }
                                    }
                                }, 0);
                                
                            }
                        })}
                        className={`border-2 ${formState.errors.email && 'border-red-500'}`}
                    />
                    {formState.errors.ref_code && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                             {formState.errors.ref_code.type === 'pattern' && t('register-input-code-alert-pattern')}
                            {formState.errors.ref_code.type === 'minLength' && t('register-input-code-alert-valid')}
                            {formState.errors.ref_code.type === 'invalid' && t('register-input-code-alert-existence')}
                           
                        </span>
                    }
                </div>

            </div>


            <button disabled={!formState.isValid} type="submit" className={`mb-12 ${!formState.isValid && 'opacity-50 cursor-not-allowed'}`}>
                {t('register-button-register')}
            </button>



        </form>
    );
}

export default Register;