import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css'
import App from './App';


ReactDOM.render(
  <>
    <App className={' bg-whiteLilac-500'}/>
  </>,
  document.getElementById('root')
);

