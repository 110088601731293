import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../services/AuthContext';
import { getInvestorGlobalBalances, getBalances, getInvestorTransactionPending } from '../../services/ApiService';
import Footer from './Footer';
import config from '../../constants/Config';
import i18n from '../../i18n';

const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const childRef = useRef();
    const { mystate } = React.useContext(AuthContext);
    const [balance, setBalance] = useState([])
    const [alert_counter, setAlertCounter] = useState(0);
    const [alert_text_box_is_open, setAlertTextBoxIsOpen] = useState(false);
    const [global_balance, setGlobalBalance] = useState({
        current_balance: 0,
        investments: 0,
        winnings: 0,
        balance: [],
        was_initialized: false,
    });

    useEffect(() => {

        const init = async () => {
            try {
                let getInvestorTransactionResponse = await getInvestorTransactionPending(mystate.user.investor_id, 'PENDING');
                //console.log(getInvestorTransactionResponse.data)
                let count = getInvestorTransactionResponse.data.length;
                //let count = getInvestorTransactionResponse.data.filter(data => data.status === 'PENDING').length;

                let getBalancesResponse = await getBalances(mystate.user.investor_id);
                //console.log(getBalancesResponse.data)
                let getInvestorGlobalBalancesResponse = await getInvestorGlobalBalances(mystate.user.investor_id);
                //console.log(getBalancesResponse.data)
                //console.log(getInvestorGlobalBalancesResponse.data)
                /*getInvestorGlobalBalancesResponse.data.current_balance = getInvestorGlobalBalancesResponse.data.current_balance === 0 && '0.00'

                getInvestorGlobalBalancesResponse.data.investments = getInvestorGlobalBalancesResponse.data.investments === 0 && '0.00' 
                //getInvestorGlobalBalancesResponse.data.investments.toFixed(2);
                
                getInvestorGlobalBalancesResponse.data.winnings = getInvestorGlobalBalancesResponse.data.winnings === 0 && '0.00' */
                let balance_needed = getBalancesResponse.data.map((balance) => {
                    return {
                        id: balance.fund_id,
                        name: i18n.language === 'es' ? balance.fund.name.es : balance.fund.name.en,
                        current_balance: balance.current_balance,
                        investments: balance.investments,
                        winnings: balance.winnings
                    }
                })

                //console.log(balance_needed);

                //setBalance(getBalancesResponse.);
                setAlertCounter(count);
                setGlobalBalance({ ...getInvestorGlobalBalancesResponse.data, balance: getBalancesResponse.data, was_initialized: true });
                //console.log(getBalancesResponse.data);

            } catch (error) {
                //console.log(error);
            }
        }

        init();
        return () => { }
    }, []);

    const goToMenu = () => {
        if (!global_balance.was_initialized) {
            return;
        }
        navigate('/menu', { state: { ...state, balance: global_balance.balance } });
    };

    const goToAllFunds = () => {
        navigate('/home/investor-recommended-funds/know-all-our-funds', { state: { ...state } });
    }

    const goToBlog = () => {
        const win = window.open(config.blogUrl, '_blank');
        win.focus();

    }

    const goToMovements = () => {
        navigate('/movements/history', { state: { ...state } });
    };

    return (
        <div className="flex flex-col">

            <header style={{ backgroundColor: '#f6f6f9' }} className="flex  mb-3  px-4">
                <div className='self-center flex-1'>
                    <img
                        src="/images/flip-logo-color.svg"
                        alt="logo"
                        className="object-contain max-h-16 mt-7 ml-3 mb-3"
                    />


                </div>

                <div className='flex mt-4'>

                    <div className='flex self-center mr-4 relative '>
                        {alert_counter > 0 &&
                            <div onClick={() => setAlertTextBoxIsOpen(!alert_text_box_is_open)} className={`cursor-pointer flex self-center bg-sherpaBlue-500 rounded-full ${alert_counter > 9 ? 'h-1 py-1.5 px-0.5' : 'h-1 py-1.5 px-1'} absolute top-0 left-3.5 border-white border-2`}>
                                <div style={{ fontSize: '9px' }} className='self-center text-white'>
                                    {alert_counter > 9 ? '9+' : alert_counter}
                                </div>
                            </div>
                            /* <div className='flex self-center h-0.5 bg-red-500 rounded-full p-0.5 absolute top-0.5 left-4 border-white border-2'>
                            </div> */
                        }

                        {alert_text_box_is_open > 0 &&
                            <>
                                {alert_counter === 0 &&
                                    <div className={`flex bg-white absolute w-72  top-10 right-0 p-3 shadow rounded-xl`}>
                                        {t('home-alert-box-case-cero')}
                                    </div>
                                }

                                {alert_counter === 1 &&
                                    <div className={`flex flex-col bg-white absolute w-72  top-10 right-0 p-3 shadow rounded-xl`}>

                                        {t('home-alert-box-case-one')}
                                        <div onClick={() => goToMovements()} className=' underline cursor-pointer'>
                                            {t('home-alert-box-see-more')}
                                        </div>

                                    </div>


                                }

                                {alert_counter > 1 &&
                                    <div className={`flex flex-col bg-white absolute w-72  top-10 right-0 p-3 shadow rounded-xl`}>

                                        {t('home-alert-box-case-more-than-one')}
                                        {alert_counter}
                                        {t('home-alert-box-case-more-than-one-2')}
                                        <div onClick={() => goToMovements()} className=' underline cursor-pointer'>
                                            {t('home-alert-box-see-more')}
                                        </div>

                                    </div>
                                }
                            </>

                            /* <div className='flex self-center h-0.5 bg-red-500 rounded-full p-0.5 absolute top-0.5 left-4 border-white border-2'>
                            </div> */
                        }

                        <svg onClick={() => setAlertTextBoxIsOpen(!alert_text_box_is_open)} className="h-7 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                        </svg>
                    </div>


                    <img
                        onClick={() => goToMenu()}
                        src="/images/icon-menu.svg"
                        alt="icon-credential"
                        className={`object-contain h-7 self-center mr-4 cursor-pointer ${!global_balance.was_initialized && 'opacity-50'}`}
                    />
                </div>

            </header>

            <div onClick={() => setAlertTextBoxIsOpen(false)} className="flex flex-col px-4">
                <h1 className="ml-5 mb-3">{t('home-title') + mystate.investor.name}</h1>

                <h3 className="subtitle text-sm ml-6 mb-3">{t('home-total-balance')}</h3>

                <h1 className="ml-5">USD {global_balance.current_balance === 0 ? '0.00' : Number(global_balance.current_balance).toLocaleString('en-US', { maximumFractionDigits: 2 })}</h1>

                <img
                    src="/images/icon-home-graph.svg"
                    alt="icon-credential"
                    className="object-contain self-center w-full "
                />

                <div className="flex flex-row text-xs justify-between mt-4 px-2">
                    <h5>{t('home-has-invested')}</h5>
                    <h5>{t('home-your-performance')}</h5>
                </div>

                <div className="flex flex-row text-xs justify-between px-2 mb-5">
                    <h5><b>USD {global_balance.investments === 0 ? '0.00' : Number(global_balance.investments).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b></h5>
                    <h5><b>USD {global_balance.winnings === 0 ? '0.00' : Number(global_balance.winnings).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b></h5>
                </div>

                <div className="flex  mb-16 self-end cursor-pointer" onClick={() => childRef.current.goToOperationsFromHome()}>
                    <h5 className='font-normal underline'>{t('home-see-details')}</h5>
                </div>

                <div className="flex flex-row justify-between cursor-pointer home-primary-button  p-6 rounded-2xl tracking-wide" onClick={goToAllFunds}>
                    <h2 className="grow self-center">{t('home-know-our-independent-mutual-fund')}</h2>
                    <img className="grow-0 object-contain px-2 h-10 self-center" src="/images/icon-arrow-right.svg" />
                </div>

                <h1 className=" mt-10 text-lg ml-2">{t('home-investment-guide')}</h1>

                <div className="flex flex-col mb-44 cursor-pointer home-secondary-button mt-4 p-6 rounded-2xl tracking-wide" onClick={goToBlog}>
                    <h2 className="home-secondary-button-title">{t('home-learn-the-basics')}</h2>
                    {/*<h5 className="home-secondary-button-subtitle">{t('home-know-more-here')}</h5>*/}
                </div>

            </div>

            <Footer ref={childRef} />

        </div>
    );
}

export default Home;