import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import { getInvestorPepRelative,storeInvestorPepRelative,deleteInvestorPepRelative } from '../../../services/ApiService'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../i18n';


const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const FamilyOfPoliticalExposedPerson = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [family, setFamily] = useState([]);
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [disableButton, setDisableButton] = useState({add:false,delete:false});
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    //console.log(watch())

    useEffect(() => {
        
        const init = async ()=>{
            //console.log(mystate)
            let ApiResponse = await getInvestorPepRelative(mystate.user.investor_id,mystate.pep_id)
            Object.keys(ApiResponse.data).length !== 0 && setFamily(ApiResponse.data)
        }

        init();
        return () => {}
    }, [])

    const onSubmit = async () => {
        try {
            setDisableButton({...disableButton,add:true});
            let data = {
                consanguinity_degree: getValues('relative'),
                job: getValues('charge'),
                institution: getValues('institution'),
                name: getValues('name'),
            }
    
            let ApiResponse = await storeInvestorPepRelative(mystate.user.investor_id,mystate.pep_id, data)
            //console.log(ApiResponse.data);
            let new_family = family.length === 0 ? [{...ApiResponse.data}] : [...family,{...ApiResponse.data}];
            //console.log(new_family)
            setFamily(new_family);

        } catch (error) {
            //console.log(error);
            Toast.fire({
                icon: 'error',
                title: t('family-of-political-exposed-person-alert-error-submit'),
            })
        }

        setDisableButton({...disableButton,add:false});
        reset();
    }

    const deleteFamily = async (i,id) => {
        
        if(disableButton.delete){
            return;
        }
        setDisableButton({...disableButton,delete:true});
        try {
            let ApiResponse = await deleteInvestorPepRelative(mystate.user.investor_id,mystate.pep_id,id);
            //console.log(ApiResponse.data);

            let new_family_array = [...family];
            new_family_array.splice(i, 1);
            setFamily(new_family_array);

        } catch (error) {
            //console.log(error)
            Toast.fire({
                icon: 'error',
                title: t('family-of-political-exposed-person-alert-error-submit'),
            })
        }
        setDisableButton({...disableButton,delete:false});
    }

    const goNext = () => {
        navigate('/onboarding/congratulations', { state: { from: 'pep-family' } });
    }

    const Back = () => {
        navigate('/your-security-is-first', { state: {...state} });
/*
		if(state.from === 'pep-participations'){
			navigate('/onboarding/additional-information/political-exposed-person/pep-information/participations', { state: {...state} });
			return;
		}

		if(state.from === 'validate-pep-participation'){
			navigate('/onboarding/additional-information/political-exposed-person/pep-information', { state: {...state} });
			return;
		}

        if(state.from === 'go-to-pep-family'){
            navigate('/onboarding/additional-information/political-exposed-person/go-to-pep-family', { state: {...state} });
        }
*/
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('family-of-political-exposed-person-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('family-of-political-exposed-person-description-label-2')}</p>
            </div>

            <div className='card-information-pep-participation-section flex flex-col mb-32'>
                
                <div id="relative-section" className={'mb-5 flex-1'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="relative">
                        {t('family-of-political-exposed-person-input-relative-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('family-of-political-exposed-person-input-relative-placeholder')}
                        defaultValue=''
                        {...register("relative", { required: true })}
                        className={`mb-0 border-2 ${formState.errors.relative && 'border-red-500'}`}
                    />
                    {formState.errors.relative && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.relative.type === 'required' && t('family-of-political-exposed-person-alert-relative-required')}
                        </span>
                    }
                </div>

                {/**  
                <div id="relative-section" className={'mb-5'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="document">
                        {t('family-of-political-exposed-person-input-relative-label')}
                    </label>

                    <select {...register("relative")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">

                        {/*Object.entries(maritalData).map((item, i) => {
                            return (<option key={i} className="mt-3" value={item[0]}>{item[1]}</option>)
                        })

                    </select>

                </div>*/}

                <div id="charge-section" className={'mb-5 flex-1'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="charge">
                        {t('family-of-political-exposed-person-input-charge-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('family-of-political-exposed-person-input-charge-placeholder')}
                        defaultValue=''
                        {...register("charge", { required: true })}
                        className={`mb-0 border-2 ${formState.errors.charge && 'border-red-500'}`}
                    />
                    {formState.errors.charge && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.charge.type === 'required' && t('family-of-political-exposed-person-alert-charge-required')}
                        </span>
                    }
                </div>

                <div id="institution-section" className={'mb-5 flex-1'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="institution">
                        {t('family-of-political-exposed-person-input-institution-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('family-of-political-exposed-person-input-institution-placeholder')}
                        defaultValue=''
                        {...register("institution", { required: true })}
                        className={`mb-0 border-2 ${formState.errors.institution && 'border-red-500'}`}
                    />
                    {formState.errors.institution && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.institution.type === 'required' && t('family-of-political-exposed-person-alert-institution-required')}
                        </span>
                    }
                </div>

                <div id="name-section" className={'mb-5 flex-1'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="name">
                        {t('family-of-political-exposed-person-input-name-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('family-of-political-exposed-person-input-name-placeholder')}
                        defaultValue=''
                        {...register("name", { required: true })}
                        className={`mb-0 border-2 ${formState.errors.name && 'border-red-500'}`}
                    />
                    {formState.errors.name && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs">
                            {formState.errors.name.type === 'required' && t('family-of-political-exposed-person-alert-name-required')}
                        </span>
                    }
                </div>
                
                <button type="submit"  disabled={!formState.isValid || disableButton.add} className={`my-10 information-pep-save-business-button self-end mt-5 w-1/2 ${(!formState.isValid || disableButton.add) && 'opacity-50 cursor-not-allowed'}`} >
                    {t('family-of-political-exposed-person-button-save-family')}
                </button>
            </div>


            <div className="flex justify-center mb-8">
                <div className='flex-1  self-center pr-2'>
                    <hr />
                </div>

                <div className='flex-1'>
                    <div className='flex justify-center'>
                        {t('family-of-political-exposed-person-label-related')}
                    </div>
                </div>

                <div className='flex-1 self-center pl-2'>
                    <hr />
                </div>

            </div>

            {family.length > 0 &&
                <div className='mb-3'>
                    {family.map((item, i) => {
                        return (
                            <div key={i} className="flex mb-3 pep-information-business-shadow bg-white rounded-xl py-2 px-3 ">
                                <div className='flex-1'>
                                    <div className='mb-2'> {item.name}</div>
                                    <div className='text-gray-400 text-sm'>{item.consanguinity_degree}</div>
                                </div>
                                <div className='flex-1'>
                                    <div className='flex h-full justify-end items-center'>
                                        <div  className={`cursor-pointer ${disableButton.delete && 'opacity-50 cursor-not-allowed'}`} onClick={() => deleteFamily(i,item.id)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }


            <button disabled={family.length === 0} type="button" onClick={()=>goNext()} className={`my-10 ${family.length === 0 && 'opacity-50 cursor-not-allowed'}`} >
                {t('family-of-political-exposed-person-submit-button')}
            </button>

        </form>
    );
}

export default FamilyOfPoliticalExposedPerson;