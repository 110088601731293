import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { getDeclaration } from '../../services/ApiService';

const WrongFund = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [declaration, setDeclaration] = useState('');

    useEffect(() => {

        const init = async () => {
            let ApiResponse = await getDeclaration(i18n.language === 'es' ? 'es' : 'en');
            //console.log(ApiResponse.data)
            setDeclaration(ApiResponse.data.value);
        }

        init();

        return () => {

        }
    }, [])

    const goNext = () => {
        navigate('/operations/subscription/transfer-to-flip', { state: { ...state } });
    }

    const goBack = () => {
        navigate('/operations/subscription/new', { state: { ...state } });
    };

    return (
        <div className="flex flex-col px-7" >

            <div style={{marginLeft:'-5px'}} className="flex mt-10">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => goBack()} className="h-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-5">
                <h1 className="text-2xl font-bold ">{t('wrong-fund-title')}</h1>
            </div>

            <div className="mb-8">
                <label className="mt-11 text-sm text-gray-700">{t('wrong-fund-label-description')}</label>
            </div>

            <div className="mb-5 self-center">
                <h1 className="font-bold text-sm">{t('wrong-fund-title-sworn-declaration')}</h1>
            </div>

            <div className="mb-20">
                <p className="text-sm text-gray-700">{declaration}</p>
            </div>

            <button className="mb-20" onClick={goNext} >
                {t('new-subscription-button-submit')}
            </button>
        </div>
    );
}

export default WrongFund;