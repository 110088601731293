import React, { useEffect } from 'react';
import MyRouter from './components/router/router';
import Spinner from './components/tailwind-components/Spinner';
import { BrowserRouter } from "react-router-dom";
import { AuthContext, AuthContextInitialState, AuthContextReducer, GetTokenAndValidate } from "./services/AuthContext";
import './i18n';


function App() {

	const [isLoadingComplete, setLoadingComplete] = React.useState(false);
	const [mystate, dispatch] = React.useReducer(AuthContextReducer, AuthContextInitialState);
	

	useEffect(() => {
		const initApp = async() =>{
			await GetTokenAndValidate(dispatch);
			setTimeout(() => {
				setLoadingComplete(true);
			}, 1000);
		}
		initApp();
		return () => {}
	}, [])


	if (!isLoadingComplete) {
		return (
			<Spinner type={''}/>
		)

	} else {
		return (
			<BrowserRouter>
				<AuthContext.Provider value={{ mystate, dispatch }}>
					<MyRouter/>
				</AuthContext.Provider>
			</BrowserRouter>
		);
	}
}



export default App;

