import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ValidatedEmail = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();

	useEffect(() => {
		//console.log(state);
		return () => { }
	}, [])

	const goNext = (user) => {
		navigate('/register', { state: { ...state } });
	}

	return (
		<div className="flex flex-col px-6 mt-9" >

			<div className="mt-9 mb-5 ml-2">
				<h1 className="text-3xl font-bold ">{t('validated-email-title')}</h1>
			</div>

			<div className="mb-16">
				<p className="text-gray-700 text-sm ml-2">{t('validated-email-label')}</p>
			</div>

			<div className="mb-32 self-center">
				<img
					src="/images/email-sent.svg"
					alt="email-sent"
					className="object-contain h-20 w-full"
				/>
			</div>

			<button onClick={() => goNext()} type="button" >
				{t('validated-email-button')}
			</button>


		</div>
	);
}

export default ValidatedEmail;