import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactCodeInput from "react-code-input";
import { resetPasswordRequest,ForgotPasswordRequest,checkUserPinAdvancedAccess } from "../../services/ApiService";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)
const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Toast = MySwal.mixin({
	toast: true,
	position: 'bottom',
	showConfirmButton: false,
	timer: 2500,
	timerProgressBar: true,
	width: '90%',
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})


const props = {
    inputStyle: {
        fontFamily: 'monospace',
        margin: '3px',
        MozAppearance: 'textfield',
        //display: 'inline-flex',
        borderRadius: '7px',
        height: '45px',
        padding: '5px',
        textAlign: 'center',
        fontSize: '25px',
        borderWidth: 1,

    },
    inputStyleInvalid: {
        fontFamily: 'monospace',
        margin: '3px',
        MozAppearance: 'textfield',
        //display: 'inline-flex',
        borderRadius: '7px',
        height: '45px',
        padding: '5px',
        textAlign: 'center',
        fontSize: '25px',
        color: 'rgb(185, 74, 72)',
        backgroundColor: 'rgb(242, 222, 222)',
        borderWidth: 1,
    },
}

const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPinCodeValid, setIsPinCodeValid] = useState(true);
    const [pinCode, setPinCode] = useState("");
    const { state } = useLocation();
    const { email } = state;
	const { register, handleSubmit, setValue,watch, trigger, getValues, formState } = useForm({
		mode: "onChange"
	});

    useEffect(() => {
        setValue('email', email, { shouldValidate: true })
        return () => { }
    }, [])

    const handlePinChange = pinCode => {
        setPinCode(pinCode);
    };
    
    const onSubmit = async () => {
        try {

            let data = {...getValues(),pin: pinCode }
            //console.log(data)
            //let data = { email: inputEmail, password: inputPassword, password_confirmation: inputConfirmPassword, pin: pinCode }
            let apiResponse = await resetPasswordRequest(data);
            //console.log(apiResponse);

            setIsPinCodeValid(true);

            /*Toast.fire({
                icon: 'success',
                title: t('reset-password-alert-success-change-password'),
            })

            setTimeout(() => {
                navigate('/login')
            }, 2500);*/
            
            navigate('/login')

        } catch (error) {


            if(error.response.data.hasOwnProperty('key')){

                if(error.response.data.key === 'USER_NOT_FOUND'){
                    Toast.fire({
                        icon: 'error',
                        title: t('reset-password-alert-user-not-found'),
                    })  
                }

                if(error.response.data.key === 'INVALID_PIN'){
                    Toast.fire({
                        icon: 'error',
                        title:  t('reset-password-alert-wrong-pin'),
                    })  
                }

            }

            
            if(error.response.data.hasOwnProperty('errors')){

                for(let i=0;i< error.response.data.errors.password.length;i++){

                    Toast.fire({
                        icon: 'error',
                        title: error.response.data.errors.password[i],
                        /*showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        }*/
                    })               
                }
            }

            setIsPinCodeValid(false);

        }

    };

    const reSend = async () => {
        try {
            //await checkUserPinAdvancedAccess(email);
            await ForgotPasswordRequest({ email: getValues('email') }); 
            Toast.fire({
                icon: 'success',
                title:  t('reset-password-alert-success-email-resended'),
            })

        } catch (error) {

            Toast.fire({
                icon: 'error',
                title:  t('reset-password-alert-error-email-resended'),
            })
            


        }

    }

    const Back = (user) => {
        navigate('/register')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-7 mt-4" >

			<div className="mb-4">
				<img
					src="/images/flip-logo-color.svg"
					alt="logo"
					className="object-contain h-32 w-full"
				/>
			</div>

            <div className="self-center mb-2">
                <h1 className="text-xl font-bold">{t('reset-password-label-password-recovery')}</h1>
            </div>

            <div className="mb-3">
                <label className="block ml-2 text-gray-500 text-sm mb-2" htmlFor="email">
                    {t('reset-password-input-email')}
                </label>
                <input
                    type="text"
                    placeholder={t('reset-password-input-email-placeholder')}
                    defaultValue=''
                    {...register("email", {
                        required: true, pattern: regex,
                        onChange: async () => await trigger("confirm_email")
                    })}
                    className={`border-2 ${formState.errors.email && 'border-red-500'}`}
                //disabled={true}
                />

                {formState.errors.email && !formState.isValid &&
                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                        {formState.errors.email.type === 'required' && t('reset-password-alert-required-email')}
                        {formState.errors.email.type === 'pattern' && t('reset-password-alert-valid-email')}
                    </span>
                }
            </div>

            <div className="mb-2">
                <label className="text-xl  block ml-2 text-gray-400" htmlFor="email-input">
                    {t('reset-password-label-code-verification')}
                </label>
            </div>

            <div className="mb-2 flex">
                <ReactCodeInput
                    id="pinCode"
                    type="number"
                    className='flex-1 code-input'
                    isValid={isPinCodeValid}
                    fields={6}
                    onChange={handlePinChange}
                    value={pinCode}
                    {...props}
                />
            </div>

            <div className="mb-1">
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="email-input">
                    {t('reset-password-label-did-not-reach-you')}
                    <button onClick={() => reSend()} type="button" className="underline inline font-normal bg-whiteLilac-500 text-gray-400  hover:bg-transparent py-1 px-1 text-sm  focus:outline-none">
                        {t('reset-password-resend')}
                    </button>
                </label>
            </div>

            <div className="mb-3">
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="password">
                    {t('reset-password-input-password')}
                </label>
                <input
                    placeholder={t('reset-password-input-password-placeholder')}
                    defaultValue=''
                    {...register("password", { required: true, minLength: 8, onChange: async () => await trigger("password_confirmation") })}
                    className={`mb-0 border-2 ${formState.errors.password && 'border-red-500'}`}
                    type={'password'}
                />
                {formState.errors.password &&
                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                        {formState.errors.password.type === 'required' && t('reset-password-alert-required-password')}
                        {formState.errors.password.type === 'minLength' && t('reset-password-alert-valid-password')}
                    </span>
                }
            </div>



            <div className="mb-5">
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="password_confirmation">
                    {t('reset-password-input-password-confirm')}
                </label>
                <input
                    defaultValue=''
                    {...register("password_confirmation", {
                        required: true, validate: async (value) => {
                            return await value === watch('password')
                        }
                    })}
                    className={`border-2 ${formState.errors.password_confirmation && 'border-red-500'}`}
                    placeholder={t('reset-password-input-password-confirm-placeholder')}
                    type={'password'}
                />
                {formState.errors.password_confirmation &&
                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                        {formState.errors.password_confirmation.type === 'required' && t('reset-password-alert-required-confirm-password')}
                        {formState.errors.password_confirmation.type === 'validate' && t('reset-password-alert-valid-confirm-password')}
                    </span>
                }
            </div>

            <button disabled={!formState.isValid && pinCode.length !== 6} type="submit" className={pinCode.length === 6 && formState.isValid ? ' mb-0.5' : 'opacity-50 cursor-not-allowed mb-0.5'}>
                {t('reset-password-button')}
            </button>

        </form>
    );
}

export default ResetPassword;