import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Webcam from "react-webcam";
import { registerDocumentIdBack } from "../../../services/ApiService";
import { AuthContext } from "../../../services/AuthContext";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';



const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})


function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

const BackDocumentIdRegistration = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [numberOfErrors, setNumberOfErrors] = useState(0);
    const { mystate } = React.useContext(AuthContext);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        //console.log(numberOfErrors);
        return () => { }
    }, [numberOfErrors])

    const restart = () => {
        setSelectedImage(null)
    }

    const Back = () => {
        navigate('/onboarding/validate-identity/document-registration')
    }

    const webcamRef = React.useRef(null);

    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setSelectedImage(imageSrc);
        },
        [webcamRef]
    );

    const videoConstraints = {
        aspectRatio: 16 / 9,
        facingMode: "environment"
    };

    const saveFaceId = async () => {
        try {
            let formData = new FormData();
            formData.append('document_id_image_back', dataURLtoFile(selectedImage, 'document-id-' + mystate.user.id));

            let apiResponse = await registerDocumentIdBack(mystate.user.id, formData);
            //console.log(apiResponse.data)
            navigate('/advanced-access/face/requirements', { state: { from: 'document-id-registration' } });

        } catch (error) {
            //console.log(error.response.data.key);
            //console.log(`${numberOfErrors} >= 3    =  ${numberOfErrors >= 3}`)

            if (numberOfErrors >= 3) {
                navigate('/advanced-access/face/errors');
            }

            if (error.response.data.key === 'ERROR_ON_FACE_REGISTRATION ') {
                Toast.fire({
                    icon: 'error',
                    title: t('back-document-id-registration-alert-error-on-face-registration'),
                })
            }

            if (error.response.data.key === 'DOCUMENT_ID_IMAGE_IS_NOT_A_DOCUMENT_ID') {
                Toast.fire({
                    icon: 'error',
                    title: t('back-document-id-registration-alert-document-id-image-is-not-dni'),
                })
            }

            if (error.response.data.key === 'USER_DNI_NOT_FOUND_IN_DOCUMENT_ID') {
                Toast.fire({
                    icon: 'error',
                    title: t('back-document-id-registration-alert-document-id-image-dont-match'),
                })
            }

            setNumberOfErrors(numberOfErrors + 1);
        }

    }


    return (
        <div className="flex flex-col px-5 webcam-container pb-80" >


            <div className="my-6 flex flex-row content-center">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 text-white cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>

                <p className=" text-white text-sm ml-2">{t('back-document-id-registration-label-back')}</p>
            </div>

            <h2 className="w-full text-white text-center mt-3 text-base font-thin">{t('back-document-id-registration-title')}</h2>

            <div className="flex flex-row mx-20 mt-10 self-center ">
                {!selectedImage &&
                    <Webcam audio={false}
                        // width={1280}
                        // height={720}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />
                }
                {selectedImage && <img className="m-1 " src={selectedImage} alt="" />}
            </div>



            <h3 className={'w-full text-white text-center mt-8 mb-16 text-base font-thin'}>{t('back-document-id-registration-label-description')}</h3>

            {!selectedImage &&
                <button className="place-self-end w-full text-center mb-20" onClick={capture}>
                    {t('back-document-id-registration-button-photo-capture')}
                </button>
            }

            {selectedImage &&
                <>
                    <button className="place-self-end w-full text-center mb-0.5" onClick={saveFaceId}>
                        {t('back-document-id-registration-button-send')}
                    </button>

                    <button onClick={restart} type='button' className="bg-transparent text-asparagus-500  hover:bg-transparent place-self-end w-full text-center mb-6">
                        {t('back-document-id-registration-button-restart')}
                    </button>
                </>
            }
            {<div className='mt-10'></div>}
        </div>
    );
}

export default BackDocumentIdRegistration;