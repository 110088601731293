import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { storeInvestorProfilerDetermine, getProfileRiskImage,showInvestor } from '../../services/ApiService';
import { AuthContext } from '../../services/AuthContext';
import i18n from '../../i18n';

const InvestorFitsWith = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [content, setContent] = useState([]);
    const { mystate, dispatch } = React.useContext(AuthContext);

    useEffect(() => {


        const init = async () => {
            //console.log(state)

            //let example_sate = { points:3 } buho
            //let example_sate = { points:-9 }
            //let ApiResponse = await storeInvestorProfilerDetermine(example_sate);
            let ApiResponse = await storeInvestorProfilerDetermine({points:state.points},mystate.user.investor_id);
            //console.log(ApiResponse.data);

            let ApiResponse2 = await getProfileRiskImage(ApiResponse.data.image);
            //console.log(ApiResponse2.data);
            ApiResponse.data.my_image = ApiResponse2.data.url;
            ApiResponse.data.my_description = i18n.language === 'es' ? ApiResponse.data.description.es : ApiResponse.data.description.en;
            ApiResponse.data.my_name = i18n.language === 'es' ? ApiResponse.data.name.es : ApiResponse.data.name.en;

            
            let show_investor_response = await showInvestor(mystate.user.id, mystate.user.investor_id);
            dispatch({ type: 'UPDATE_INVESTOR', user: mystate.user, investor: show_investor_response.data });

            setContent(ApiResponse.data);
        }

        init();

        return () => { }
    }, [])

    const goNext = () => {
        navigate('/know-your-investor-profile/investor-recommended-funds', { state: { ...state,fund:content.fund,fund2:content.fund2 } });
    }

    const Back = () => {
        navigate('/know-your-investor-profile')
    }

    return (
        <div className="flex flex-col px-6 mt-9" >

            {/* <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div> */}

            <div className="mt-5 mb-8 ml-2">
                <h1 className="text-xl font-bold ">{t('investor-fits-with-title')} {content.my_name}</h1>
            </div>

            <img
                src={content.my_image}
                alt=""
                className="object-contain mb-20 h-36 w-full"
            />


            <div className="mb-16">
                <p className="text-gray-500 text-sm ml-2">{content.my_description}</p>
            </div>

            <button onClick={() => goNext()} className="" type="button" >
                {t('investor-fits-with-button-submit')}
            </button>

        </div>
    );
}

export default InvestorFitsWith;