import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { getTransactionMinimunAmount } from '../../services/ApiService';
import { AuthContext } from "../../services/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


const MySwal = withReactContent(Swal)
const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const TypeSelector = () => {
    const { t } = useTranslation();
    const [withdrawalType, setWithdrawalType] = React.useState('PARTIAL');
    const navigate = useNavigate();
    const { state } = useLocation();
    const [minimunTransactionValue, setMinimunTransactionValue] = useState('');

    useEffect(() => {
        //console.log(state);

        const init = async () => {
            let ApiResponse = await getTransactionMinimunAmount();
            setMinimunTransactionValue(ApiResponse.data)
        }

        init();

        return () => { }
    }, []);

    const goBack = () => {
        navigate('/home', { state: { ...state } });
    };

    const goToWithdrawalScreen = () => {
        navigate('/operations/withdrawal/' + withdrawalType.toLowerCase() + '/new', { state: { ...state } });
    }

    return (
        <div className="flex flex-col">

            <div className="flex mt-10 ml-6">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => goBack()} className="h-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="flex flex-col px-8">
                <h1 className="mt-6">{t('type-selector-title')}</h1>

                <p className="mt-20 text-sm text-gray-700 mb-5">{t('type-selector-label-description')}</p>

                <div className='px-1 mb-8'>
                    <div className="withdrawal-box mt-2 p-3 cursor-pointer" onClick={() => setWithdrawalType('PARTIAL')}>
                        <input type="radio" checked={withdrawalType === 'PARTIAL'} onChange={() => { }} /> <span className='ml-3'>{t('type-selector-label-partial-withdrawal')}</span>
                    </div>

                    <div className="withdrawal-box mt-2 p-3 cursor-pointer" onClick={() => setWithdrawalType('TOTAL')}>
                        <input type="radio" checked={withdrawalType === 'TOTAL'} onChange={() => { }} /> <span className='ml-3'>{t('type-selector-label-total-withdrawal')}</span>
                    </div>
                </div>

                <div id="type-selector-suggest-section" className="suggest flex mb-14 mt-5">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-ming-600  " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <label className="block ml-2 mb-2 flex-1 text-ming-600 font-semibold">
                        {t('type-selector-label-suggest')} {minimunTransactionValue.value}
                    </label>
                </div>

                <button className="w-full mb-10  font-normal text-sm" onClick={goToWithdrawalScreen}>{t('type-selector-button-next')}</button>
            </div>

        </div>
    );
}

export default TypeSelector;