import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../../services/AuthContext';
import { storeInvestorPEPInformation } from '../../../services/ApiService'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../i18n';


const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const PepInformation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const { register, handleSubmit, trigger, watch, reset, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });
    //console.log(watch())

    const onSubmit = async () => {
        try {

            let data = {
                job: getValues('public_charge'),
                institution: getValues('institution'),
            }

            /*let ApiResponse = await storeInvestorPEPInformation(mystate.user.investor_id, data)
            console.log(ApiResponse);*/

            /*Toast.fire({
                icon: 'success',
                title: t('occupation-alert-success-submit'),
            })

            setTimeout(() => {
                navigate('/onboarding/additional-information/political-exposed-person/pep-information/validate-user-participation',
                {state:{...data}}
                );
            }, 2500);*/ 
            navigate('/onboarding/additional-information/political-exposed-person/pep-information/validate-user-participation',
            {state:{...data}}
            );
        } catch (error) {

            Toast.fire({
                icon: 'error',
                title: t('occupation-alert-error-submit'),
            })
        }

    }

    const Back = () => {
        navigate('/onboarding/additional-information/political-exposed-person/go-to-pep-information')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-5 mt-9" >

            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('pep-information-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('pep-information-description-label')}</p>
            </div>

            <div id="public-charge-section" className={'mb-3'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="public_charge">
                    {t('pep-information-input-public-charge-label')}
                </label>
                <input
                    type="text"
                    placeholder={t('pep-information-input-public-charge-placeholder')}
                    defaultValue=''
                    {...register("public_charge", { required: true })}
                    className={`mb-0 border-2 ${formState.errors.public_charge && 'border-red-500'}`}
                />
                {formState.errors.public_charge && !formState.isValid &&
                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                        {formState.errors.public_charge.type === 'required' && t('pep-information-alert-public-charge-required')}
                    </span>
                }
            </div>

            <div id="institution-section" className={'mb-32'}>
                <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="institution">
                    {t('pep-information-input-institution-label')}
                </label>
                <input
                    type="text"
                    placeholder={t('pep-information-input-institution-placeholder')}
                    defaultValue=''
                    {...register("institution", { required: true })}
                    className={`mb-0 border-2 ${formState.errors.institution && 'border-red-500'}`}
                />
                {formState.errors.institution && !formState.isValid &&
                    <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                        {formState.errors.institution.type === 'required' && t('pep-information-alert-institution-required')}
                    </span>
                }
            </div>


            <button disabled={!formState.isValid} type="submit" className={formState.isValid ? ' mb-10' : 'opacity-50 cursor-not-allowed mb-10'} >
                {t('pep-information-button')}
            </button>

        </form>
    );
}

export default PepInformation;