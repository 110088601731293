
import React, { useEffect, useState } from 'react';


const Spinner = ({type}) => {
    useEffect(() => {
        //console.log(type);

      return () => {};
    }, []);
    
    return (
        <>
            {type === 'small' ?
                <div className="flex h-96 mt-38 justify-center self-center items-center">

                    <div style={{ borderTopColor: 'transparent' }} 
                    className="w-24 h-24 border-8  border-asparagus-500 border-solid rounded-full animate-spin" />

                </div>
                
                :

                <div className="flex h-screen justify-center self-center items-center">

                    <div style={{ borderTopColor: 'transparent' }} 
                    className="w-32 h-32 border-8  border-asparagus-500 border-solid rounded-full animate-spin" />

                </div>
            }
        </>

    );
}

export default Spinner;
