import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../services/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Modal from '../tailwind-components/Modal';
const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})

const SendedConstancy = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);
    const [modalData, setModalData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {

        //console.log(state)

        return () => { }
    }, [])

    /* const goNext = () => {
        navigate('/home');
    } */

    const goToMovements = () => {
        navigate('/movements/history');
    }

    const modalIsOpen = (value) => {

        setIsOpen(value);
        if(!value){
            //console.log(value);
            navigate('/home');
        }
    }

    return (
        <div className="flex flex-col px-6 mt-9" >


            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-2xl font-bold ">{t('sended-constancy-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('sended-constancy-label-description')}</p>
            </div>

            <div className={`mb-20 card-withdrawal-process flex flex-col`}>
                <div className="font-bold text-lg mb-2 text-gray-600">
                    {t('sended-constancy-label-detail')}
                </div>
                <div className="font-bold text-sm mb-2 text-gray-500">
                    {t('sended-constancy-label-flip-code')}
                </div>
                <div className="font-bold mb-2 text-gray-600">
                    {state.flip_code}
                </div>
                <div className="text-sm mb-2">
                    {t('sended-constancy-label-use-your-code')}
                </div>
                <div className="font-bold text-sm text-gray-500 mb-2">
                    {t('sended-constancy-label-amount-of-withdrawal')}
                </div>
                <div className="font-bold text-sm mb-2 text-gray-600">
                    {Number(state.amount).toLocaleString('en-US', { maximumFractionDigits: 2 })} {t('sended-constancy-label-usd')}
                </div>
            </div>
            
            <Modal isOpen={isOpen} isFrom={'sended-constancy'} modalData={modalData} modalIsOpen={modalIsOpen} />

            <button onClick={() => modalIsOpen(true)} className={'font-thin text-sm'}>
                {t('sended-constancy-button-understood')}
            </button>

            <button onClick={() => goToMovements()} className="bg-whiteLilac-500 text-asparagus-500 mb-12  hover:bg-transparent">
                {t('sended-constancy-button-go-to-movements')}
            </button>
        </div>
    );
}

export default SendedConstancy;