import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTermsSignIn, listInvestorDocumentType, getInvestorFacturactivaData, storeInvestor } from '../../services/ApiService';
import { AuthContext } from '../../services/AuthContext';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import Modal from '../tailwind-components/Modal';
import i18n from '../../i18n';
import config from '../../constants/Config';
import useWindowDimensions from '../../useWindowDimensions';

const MySwal = withReactContent(Swal)
const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Toast = MySwal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    width: '90%',
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
})


const RegisterDNIData = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [maritalData, setMaritalData] = useState([]);
    const [submitButtonValidations, setSubmitButtonValidations] = useState({ sending_data: false });
    const [documentValue, setDocumentValue] = useState('');
    const [modalData, setModalData] = useState([]);
    const { mystate, dispatch } = React.useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const { height, width } = useWindowDimensions();
    const { state } = useLocation();
    const { register, handleSubmit, trigger, watch, reset, control, setValue, getValues, formState } = useForm({
        mode: "onChange"
    });

    watch();
    /*useEffect(() => {

        console.log(formState.errors.document_number)
        return () => {

        }
    }, [formState.errors.document_number])*/

    useEffect(() => {

        if (getValues('birthdate') === '' || getValues('birthdate') === null) {
            return;
        }
        /*console.log('entre aqui');
        if(flagForBirthdate.input_clicked){
            setFlagForBirthdate(false)
        }*/

        let splitted_text = watch('birthdate').split('-')
        let text = splitted_text[2] + '-' + splitted_text[1] + '-' + splitted_text[0];

        document.getElementById("datepickerId2").value = text;
        return () => {
        }
    }, [watch('birthdate')]);


    useEffect(async () => {

        let ApiResponse = await listInvestorDocumentType(i18n.language === 'es' ? 'es' : 'en');

        Object.entries(ApiResponse.data).map((item, i) => {
            if (i === 0) {
                //console.log(item)
                setValue('document_type', item[0]);
                setDocumentValue(item[0]);
            }
        })
        let ApiResponse2 = await getTermsSignIn(i18n.language === 'es' ? 'es' : 'en');
        //console.log(ApiResponse2.data)
        setMaritalData(ApiResponse.data);
        setModalData(ApiResponse2.data);

        if (state !== null) {
            reset(state);
            //trigger();
        }
        return () => { }
    }, [])

    const goToCalendly = () => {
        const win = window.open(config.calendlyUrl, '_blank');
        win.focus();

    }

    const modalIsOpen = (value) => {
        if (modalData.length === 0) {
            return;
        }

        setIsOpen(value)
    }

    const goToAgreement = () => {
        const win = window.open(config.agreementUrl, '_blank');
        win.focus();

    }

    const searchFacturativaData = async () => {
        if ((getValues('document_number') === '' || (getValues('document_type') === 'CE' && getValues('birthdate') === '') || (formState.errors.document_number))) {
            return;
        }

        try {
            let data_for_investor_facturativa = {
                birth_date: getValues('birthdate').replaceAll('-', ''),
                document_number: getValues('document_number'),
                document_type: getValues('document_type'),
            }
            /*let data_for_investor_facturativa = {
                birth_date:'19820414',
                document_number: '72526346',
                document_type: 'DNI',
            }
     
            let data_for_investor_facturativa = {
                birth_date:'19820414',
                document_number: '002325360',
                document_type: 'CE',
            }*/

            data_for_investor_facturativa.birth_date = data_for_investor_facturativa.document_type !== 'CE' ? null : data_for_investor_facturativa.birth_date;

            //console.log(data_for_investor_facturativa)
            let getInvestorFacturactivaDataResponse = await getInvestorFacturactivaData(data_for_investor_facturativa);

            let maternal_last_name = ''

            if (data_for_investor_facturativa.document_type === 'CE') {
                maternal_last_name = getInvestorFacturactivaDataResponse.data.data.apellidos.split(' ').length > 1 ? getInvestorFacturactivaDataResponse.data.data.apellidos.split(' ')[1] : ''
            }

            setValue('name', getInvestorFacturactivaDataResponse.data.data.nombres);
            setValue('last_name', data_for_investor_facturativa.document_type === 'CE' ? getInvestorFacturactivaDataResponse.data.data.apellidos.split(' ')[0] : getInvestorFacturactivaDataResponse.data.data.apellidoPaterno);
            setValue('maternal_last_name', data_for_investor_facturativa.document_type === 'CE' ? maternal_last_name : getInvestorFacturactivaDataResponse.data.data.apellidoMaterno);

        } catch (error) {
            //console.log(error.response)

            if (error.response.status === 404) {
                Toast.fire({
                    icon: 'error',
                    title: t('register-dni-data-alert-error-dni-not-found'),
                })
                return;
            }
            if (error.response.status === 500) {
                Toast.fire({
                    icon: 'error',
                    title: t('register-dni-data-alert-error-search-error'),
                })
                return;
            }

        }

    }

    const onSubmit = async () => {


        setSubmitButtonValidations({ sending_data: true });
        try {

            let investor_data = {
                birth_date: getValues('birthdate'),
                ...getValues()
            }

            delete investor_data.birthdate;
            //console.log(investor_data);


            let apiResponse = await storeInvestor(mystate.user.id, investor_data);
            dispatch({ type: 'UPDATE_INVESTOR', user: apiResponse.data.user, investor: apiResponse.data })
            navigate('/congratulations');

            /*Toast.fire({
                icon: 'success',
                title: t('register-dni-data-alert-success-user-registered'),
            })*/



        } catch (error) {
            //console.log(error.response)

            if (error.response.data.key === 'INVESTOR_ALREADY_REGISTERED') {
                // await requestAdvancedAccessPin(data.email);
                Toast.fire({
                    icon: 'error',
                    title: t('register-dni-data-alert-error-user-registered'),
                })
                return;
            } else {

                if (error.response.data.key === 'ERROR_I') {
                    // await requestAdvancedAccessPin(data.email);
                    Toast.fire({
                        icon: 'error',
                        title: t('register-dni-data-alert-error-could-not-register'),
                    })
                    return;
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: t('register-dni-data-alert-error-dni-duplicated'),
                    })
                }
            }


        } finally {
            setSubmitButtonValidations({ sending_data: false });
        }


    };

    const Back = () => {
        navigate('/login')
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col px-6 mt-9" >


            <div className="mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => Back()} className="h-5 w-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className="mb-3 ml-2">
                <h1 className="text-2xl font-bold">{t('register-dni-data-title')}</h1>
            </div>

            <div className="mb-5">
                <p className="text-gray-500 text-sm ml-2">{t('register-dni-data-description-label')}</p>
            </div>

            <div className="ml-1 mb-8">

                <div id="register-dni-data-document-section" className={'mb-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="document">
                        {t('register-dni-data-input-document-label')}
                    </label>
                    <div className='flex'>
                        <div className='flex-1 mr-1'>
                            <select {...register("document_type")} className=" rounded-2xl border w-full py-3.5 px-1 text-gray-700 border-gray-300 leading-tight text-xs">

                                {Object.entries(maritalData).map((item, i) => {
                                    return (<option key={i} className="mt-3" value={item[0]}>{item[1]}</option>)
                                })}

                            </select>

                            {/*maritalData.dropdown.length > 0 &&
                                <Dropdown marital={maritalData.dropdown}  changeInputStatus={changeInputDocument}/>
                            */}
                        </div>

                        <div className='relative flex-1'>
                            <input
                                type="text"
                                defaultValue=''
                                name="document"
                                {...register("document_number", { required: true, minLength: 8, pattern: /^[0-9]+$/, })}
                                className={` border-2 ${formState.errors.document_number && 'border-red-500'}`}
                                placeholder={t('register-dni-data-input-document-input')}
                            />
                            <div onClick={() => searchFacturativaData()} className='cursor-pointer' style={{ position: 'absolute', zIndex: 2, right: 12, top: 10 }}  >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>

                            </div>
                            {formState.errors.document_number && !formState.isValid &&

                                <div className="ml-3 leading-tight">
                                    <span className="leading-tight text-red-500 mt-0 text-xs" htmlFor="document">
                                        {formState.errors.document_number.type === 'required' && t('register-dni-data-alert-document-required')}
                                        {formState.errors.document_number.type === 'minLength' && t('register-dni-data-alert-document-max-length')}
                                        {formState.errors.document_number.type === 'pattern' && t('register-dni-data-alert-document-only-numbers')}
                                    </span>
                                </div>
                            }
                        </div>

                    </div>




                </div>

                <div id="register-dni-birthdate-section" className={'my-3'}>

                    <label className="block ml-2 text-gray-400 text-sm mb-2 mt-3" htmlFor="document" >
                        {t('register-dni-data-input-birthdate-label')}
                    </label>
                    <div style={{ position: 'relative', height: '45px' }}>

                        <input
                            style={{ position: 'absolute', zIndex: 1 }}
                            type="text"
                            id="datepickerId2"
                            onKeyDown={(e) => { e.preventDefault() }}
                            defaultValue={''}
                            placeholder={t('register-dni-data-input-birthdate-placeholder')}
                            className='focus:outline-none rounded-2xl border w-full py-3.5 px-3 text-gray-700 border-gray-300 leading-tight text-xs'
                        />

                        <div style={{ position: 'absolute', zIndex: 2, right: 12, top: 10 }}  >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>

                        {/** this input is invisible over the first one */}
                        <input
                            onKeyDown={(e) => { e.preventDefault() }}
                            //onClick={()=>setFlagForBirthdate({...flagForBirthdate,input_clicked:true})}
                            type="date"
                            id="datepickerId"
                            style={{ zIndex: 3, position: 'absolute', top: 0, left: '-12px', right: 0, width: width > 705 ? width < 1024 ? '103%' : '118%' : '105%' }}
                            //placeholder={t('personal-input-birth-date-placeholder')}
                            //defaultValue={(new Date().getFullYear() - 18) + "-01-01"}
                            defaultValue={''}
                            min='1900-01-01'
                            max={(new Date().getFullYear() - 18) + "-12-31"}
                            className='focus:outline-none opacity-0 bg-blue-500 rounded-2xl border w-full py-3 px-3 '
                            {...register("birthdate", { required: true })}
                        />

                    </div>
                    {getValues('document_type') === 'CE' &&
                        <div className="leading-tight ml-2">
                            {getValues('birthdate') === '' &&
                                <span className="leading-tight text-red-500 mt-0 text-xs" htmlFor="document">
                                    {t('register-dni-data-alert-birthdate-required')}
                                </span>
                            }
                        </div>
                    }
                </div>

                <div id="register-dni-data-suggest-section" className="suggest flex my-4">
                    <svg className="h-5 w-5 text-ming-400 " viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 7H11V5H9V7ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z" fill="#0D4C57" />
                    </svg>

                    <label className="block ml-2 flex-1 text-xs  ">
                        {t('register-dni-data-input-suggest-label')}
                    </label>
                </div>


                <div id="register-dni-data-name-section" className={'mb-3'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="name">
                        {t('register-dni-data-input-name-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('register-dni-data-input-name-placeholder')}
                        defaultValue=''
                        {...register("name", { required: true })}
                        className={`mb-0 border-2  ${formState.errors.name && 'border-red-500'}`}
                        disabled
                    //style={{...styles.inputBorder}}
                    />
                    {formState.errors.name && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.name.type === 'required' && t('register-dni-data-alert-name-required')}
                        </span>
                    }
                </div>

                <div id="register-dni-data-last-name-section" className={'mb-3'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="last_name">
                        {t('register-dni-data-input-last-name-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('register-dni-data-input-last-name-placeholder')}
                        defaultValue=''
                        {...register("last_name", { required: true })}
                        className={`mb-0 border-2 ${formState.errors.last_name && 'border-red-500'}`}
                        //style={{...styles.inputBorder}}
                        disabled
                    />
                    {formState.errors.last_name && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.last_name.type === 'required' && t('register-dni-data-alert-last-name-required')}
                        </span>
                    }
                </div>

                <div id="register-dni-data-maiden-name-section" className={'mb-3'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="maternal_last_name">
                        {t('register-dni-data-input-maiden-name-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('register-dni-data-input-maiden-name-placeholder')}
                        defaultValue=''
                        {...register("maternal_last_name", { required: true })}
                        className={`mb-0 border-2 ${formState.errors.maternal_last_name && 'border-red-500'}`}
                        //style={{...styles.inputBorder}}
                        disabled
                    />
                    {formState.errors.maternal_last_name && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.maternal_last_name.type === 'required' && t('register-dni-data-alert-maiden-name-required')}
                        </span>
                    }
                </div>

                <div id="register-dni-data-phone-section" className={'mb-5'}>
                    <label className="block ml-2 text-gray-400 text-sm mb-2" htmlFor="phone">
                        {t('register-dni-data-input-phone-label')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('register-dni-data-input-phone-placeholder')}
                        defaultValue=''
                        {...register("phone", { required: true })}
                        className={`mb-0 border-2 ${formState.errors.phone && 'border-red-500'}`}
                    //style={{...styles.inputBorder}}
                    />
                    {formState.errors.phone && !formState.isValid &&
                        <span className="text-red-500 mt-0 ml-2 text-xs mb-2">
                            {formState.errors.phone.type === 'required' && t('register-dni-data-alert-phone-required')}
                        </span>
                    }
                </div>

                <div id="register-dni-data-sugget-2-section" style={{ background: 'none' }} className="text-ming-600 text-xs mb-8">
                    <div className="text-ming-600 text-xs mb-2 ml-2">
                        <label className="font-bold">
                            {t('register-dni-data-input-suggest-1')}
                        </label>
                        <div className="inline-block ml-1 pr-2.5 font-bold cursor-pointer underline" onClick={() => goToCalendly()}>
                            {t('register-dni-data-input-suggest-here')}
                        </div>
                    </div>
                    <div className="text-ming-600 text-xs mb-2 ml-2">
                        <label className="font-bold">
                            {t('register-dni-data-input-suggest-2')}
                        </label>
                        <div className="inline-block ml-1 pr-2.5 font-bold cursor-pointer underline" onClick={() => goToCalendly()}>
                            {t('register-dni-data-input-suggest-here')}
                        </div>
                    </div>

                    <div className="text-ming-600 text-xs ml-2">
                        <label className="font-bold">
                            {t('register-dni-data-input-suggest-3')}
                        </label>
                        <div className="inline-block ml-1 pr-2.5 font-bold cursor-pointer underline" onClick={() => goToCalendly()}>
                            {t('register-dni-data-input-suggest-here')}
                        </div>
                    </div>
                </div>



                <div id="register-dni-data-terms-section" className='mb-3 flex '>
                    <div className="flex-2  self-center">
                        <input style={{ appearance: 'auto' }}
                            {...register("terms", { required: true })}
                            className={`mb-0 flex-2 w-5 h-6 ${formState.errors.terms && 'border-red-500'}`}
                            type="checkbox"
                        />
                    </div>
                    <label className='flex-10 ml-2 text-xs self-center'>
                        {`${t('register-dni-data-terms-label')}`} <label className={`underline cursor-pointer ${modalData.length === 0 && 'cursor-not-allowed opacity-50'}`} onClick={() => modalIsOpen(true)}> {t('register-dni-data-terms-label-2')} </label>
                    </label>
                </div>



                <div id="register-dni-data-policy-section" className='mb-2 flex '>
                    <div className="flex-2 ">
                        <input style={{ appearance: 'auto' }}
                            {...register("policy", { required: true })}
                            className={`mb-0 border-2 w-5 h-6 self-center ${formState.errors.policy && 'border-red-500'}`}
                            type="checkbox"
                        />
                    </div>
                    <div style={{ cursor: 'pointer' }} >
                        <label className='flex-10 ml-2 text-xs self-center'>
                            {`${t('register-dni-data-policy-label')}`} <label className='underline cursor-pointer' onClick={() => goToAgreement()} > {t('register-dni-data-policy-label-2')} </label>
                        </label>
                    </div>
                </div>
            </div>

            <Modal isOpen={isOpen} isFrom={'register-dni-data'} modalData={modalData} modalIsOpen={modalIsOpen} />

            <button disabled={(!formState.isValid || submitButtonValidations.sending_data)} type="submit" className={` mb-10 ${(!formState.isValid || submitButtonValidations.sending_data) && 'opacity-50 cursor-not-allowed'}`} >
                {t('register-dni-data-button')}
            </button>



        </form>
    );
}


export default RegisterDNIData;


