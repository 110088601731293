import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react'
import Description from './Description'
import CostEffectiveness from './CostEffectiveness'
import Terms from './Terms'
import { showFunds } from '../../services/ApiService';
import i18n from '../../i18n';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const FundInformationIndex = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [funds, setFunds] = useState(null);

    useEffect(() => {

        const init = async () => {
            let ApiResponse = await showFunds(state.id);
            //console.log(ApiResponse.data);

            let aux_fund = {
                fund_id: state.id,
                name: i18n.language === 'es' ? ApiResponse.data.name.es : ApiResponse.data.name.en,
                how_it_works: i18n.language === 'es' ? ApiResponse.data.how_it_works.es : ApiResponse.data.how_it_works.en,
                description: JSON.parse(i18n.language === 'es' ? ApiResponse.data.description_es : ApiResponse.data.description_en),
                links: JSON.parse(i18n.language === 'es' ? ApiResponse.data.links_es : ApiResponse.data.links_en),
                profitability_description: i18n.language === 'es' ? ApiResponse.data.profitability_description.es : ApiResponse.data.profitability_description.en,
                profitability: JSON.parse(i18n.language === 'es' ? ApiResponse.data.profitability_es : ApiResponse.data.profitability_en),
                profitability_title: i18n.language === 'es' ? ApiResponse.data.profitability_title.es : ApiResponse.data.profitability_title.en,
                terms: JSON.parse(i18n.language === 'es' ? ApiResponse.data.terms_es : ApiResponse.data.terms_en),
                ruc: ApiResponse.data.ruc,
                account_number: ApiResponse.data.account_number,
                cci: ApiResponse.data.cci,
                transactions_enabled: ApiResponse.data.transactions_enabled,
                description_extra_content: i18n.language === 'es' ? ApiResponse.data.description_extra_content.es : ApiResponse.data.description_extra_content.en,
            };

            setFunds({ ...aux_fund });

        }

        init();

        return () => { }
    }, [])

    const goBack = () => {
        navigate('/home', { state: { ...state } });
    };

    const goToNewSubscription = () => {
        navigate('/operations/subscription/new', { state: { ...funds } });
    };

    return (
        <div className="flex flex-col">


            <div className="flex mt-10 ml-7">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => goBack()} className="h-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            {funds !== null &&
                <div className="flex flex-col px-3">

                    <h1 className="text-2xl mt-6 ml-5">{funds.name}</h1>

                    <div className="w-full my-6 sm:px-0">
                        <Tab.Group>
                            <Tab.List className="flex p-1 space-x-1 rounded-xl">
                                <Tab key={1} className={({ selected }) =>
                                    classNames(
                                        'w-full rounded-full font-normal', selected ? 'tab-selected' : 'tab-not-selected'
                                    )}>{t('fund-information-tab-description')}</Tab>
                                <Tab key={2} className={({ selected }) =>
                                    classNames(
                                        'w-full rounded-full font-normal', selected ? 'tab-selected' : 'tab-not-selected'
                                    )}>{t('fund-information-tab-profitability')}</Tab>

                                <Tab key={3} className={({ selected }) =>
                                    classNames(
                                        'w-full rounded-full font-normal', selected ? 'tab-selected' : 'tab-not-selected'
                                    )}>{t('fund-information-tab-conditions')}</Tab>

                            </Tab.List>
                            <Tab.Panels className="mt-2">
                                <Tab.Panel className={classNames('px-4 py-2')}>
                                    <Description fund={funds} />
                                </Tab.Panel>

                                <Tab.Panel className={classNames('px-4 py-2')}>
                                    <CostEffectiveness fund={funds} />
                                </Tab.Panel>

                                <Tab.Panel className={classNames('px-4 py-2')}>
                                    <Terms fund={funds} />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>

                    {!funds.transactions_enabled &&
                        <h1 className='text-base px-4'>
                            {t('fund-information-alert')}
                        </h1>
                    }

                    <button disabled={!funds.transactions_enabled} className={`w-full my-8 self-center font-normal text-base ${!funds.transactions_enabled && 'opacity-50 cursor-not-allowed'}`} onClick={goToNewSubscription}>{t('fund-information-button-next')}</button>
                </div>

            }
        </div>
    );
}

export default FundInformationIndex;