import axios from 'axios';
import Config from '../constants/Config';

const client = axios.create({
	baseURL: Config.BaseUrl,
	responseType: "json"
});

client.interceptors.request.use(async (config) => {
	//console.log(config)
	let token = localStorage.getItem('userToken');

	if (token) {
		config.headers.Authorization = "Bearer " + token;
	}

	return config;

}, error => {
	// handle the error
	return Promise.reject(error);
});

client.interceptors.response.use(async (response) => {
	//console.log(response)
	//console.log('disabled')
	return response;
}, error => {
	// handle the error
	//console.log(error.response)
	if(error.response.data.key === "FLIP_APP_MAINTENANCE_MODE_ENABLED"){
		//console.log(window.location.pathname);
		setTimeout(() => {
			//console.log('1seg  '+window.location.pathname);
			if(window.location.pathname !== '/maintenance'){
				window.location.reload();
				//window.location.href='/maintenance';
			}	
		}, 300);

	}
	return Promise.reject(error);
});


/*************************************** Maintenance ***************************************/
export function getMaintenanceStatus() {
	return client.get('api/app/maintenance');
}

/*************************************** Auth ***************************************/


export function GetLoggedUser(token) {

	if (token) {
		return client.get('api/users/me', {
			headers: {
				"Authorization": "Bearer " + token
			}
		});
	}

	return client.get('api/users/me');
}

export function LogInRequest(user) {
	return client.post('api/auth/token', { ...user });
}

export function ForgotPasswordRequest(email) {
	return client.post('api/auth/forgot-password', { ...email });
}

export function resetPasswordRequest(data) {
	return client.post('api/auth/reset-password', { ...data });
}

export function getUsersById(id) {

	return client.get('users/' + id);

}

export function registerUser(data) {
	return client.post('api/users', { ...data });
}

export function maritalStatuses(id) {

	return client.get('api/marital-statuses/');

}

/*************************************** Advanced access ***************************************/


export function checkUserPinAdvancedAccess(email) {
	return client.get(`api/auth/${email}/advanced-access/pin/valid`);
}

export function requestAdvancedAccessPin(email) {
	return client.get(`api/auth/${email}/advanced-access/pin/request`);
}

export function grantAdvancedAccessPin(email, pin) {
	return client.post(`api/auth/${email}/advanced-access/pin/grant`, { pin: pin });
}

export function checkFaceAdvancedAccess(userId) {
	return client.get(`api/auth/${userId}/advanced-access/face/valid`);
}


/*************************************** User Face Authentication ***************************************/

export function registerFaceId(user, data) {
	return client.post(`api/auth/${user}/advanced-access/face/register`, data);
}

export function registerDocumentIdBack(user, data) {
	return client.post(`api/auth/${user}/advanced-access/face/register-back`, data);
}

export function grantFaceAdvancedAccess(user, data) {
	return client.post(`api/auth/${user}/advanced-access/face/grant`, data);
}

/*************************************** Reference Code Validation ***************************************/

export function checkCodeExistence(refCode) {
	return client.post(`api/refcode/validate`, refCode);
}

/*************************************** Add bank Account ***************************************/
export function getPeruvianBanks() {
	return client.get(`api/peruvian-banks`);
}


export function storeInvestorBankAccount(investor, data) {
	return client.post(`api/investor/${investor}/bank-accounts`, { ...data });
}

export function getInvestorBankAccount(investor_id) {
	return client.get(`api/investor/${investor_id}/bank-accounts`);
}

export function showInvestorBankAccount(investor_id, bank_account_id) {
	return client.get(`api/investor/${investor_id}/bank-accounts/${bank_account_id}`);
}

export function deleteInvestorBankAccount(investor_id, bank_account_id) {
	return client.delete(`api/investor/${investor_id}/bank-accounts/${bank_account_id}`);
}

export async function getBankLogo(logo) {

	/*let token = localStorage.getItem('userToken');

	let client2 = axios.create({
		baseURL: Config.imgBaseUrl,
		responseType: "json",
		headers: { 'Authorization': `Bearer ${token}` }
	});

	client2.defaults.baseURL = Config.imgBaseUrl;*/
	return client.get('api/storage/image', { params: { path: logo } });;
}


/*************************************** Investor ***************************************/
export function showInvestor(user_id, id, token) {

	if (token) {
		return client.get(`api/users/${user_id}/investors/${id}`, {
			headers: {
				"Authorization": "Bearer " + token
			}
		});
	}

	return client.get(`api/users/${user_id}/investors/${id}`);
}

export function storeInvestor(user, data) {
	return client.post(`api/users/${user}/investors`, { ...data });
}


export function getInvestorFacturactivaData(data) {
	return client.get(`api/facturactiva/search?document_type=${data.document_type}&document_number=${data.document_number} ${data.birth_date !== null ? `&birth_date=${data.birth_date}`:''}`);
}

export function updateInvestor(user_id, id, data) {
	return client.put(`api/users/${user_id}/investors/${id}`, { ...data });
}

export function patchInvestor(user_id, id, data) {
	return client.patch(`api/users/${user_id}/investors/${id}`, { ...data });
}

/*************************************** Transfer to Flip ***************************************/

export function getTransferFunds(fund_id, id) {
	return client.get(`api/funds/${fund_id}/bank-account?peruvian_bank_account_id=${id}`);
}

/*************************************** Static Data ***************************************/
export function getGender(locale) {
	return client.get(`api/genders`, { headers: { Locale: locale } });
}

export function getBankAccountTypes(locale) {
	return client.get(`api/bank-account-types`, { headers: { Locale: locale } });
}


export function listInvestorDocumentType(locale) {
	return client.get(`api/investor-document-types`, { headers: { Locale: locale } });
}

export function getMaritalStatuses(locale) {
	return client.get(`api/marital-statuses`, { headers: { Locale: locale } });
}

export function getInvestorPepCompanyDocumentTypes(locale) {
	return client.get(`api/investor-pep-company-document-types`, { headers: { Locale: locale } });
}


export function getTransactionMinimunAmount(locale) {
	return client.get(`api/terms/transaction-min-amount`, { headers: { Locale: locale } });
}





/*************************************** Countries ***************************************/

export function getCountries() {
	return client.get(`api/countries`);
}
/*************************************** Investor Address ***************************************/

export function getInvestorAddress(investor_id) {
	return client.get(`api/investor/${investor_id}/address`);
}

export function storeInvestorAddress(investor_id, data) {
	return client.post(`api/investor/${investor_id}/address`, { ...data });
}

/*************************************** Investor Occupation ***************************************/

export function storeInvestorOccupation(investor, data) {
	return client.post(`api/investor/${investor}/occupation`, { ...data });
}

export function getAverageIncomes() {
	return client.get('api/average-incomes');
}

/*************************************** Occupation ***************************************/
export function getOccupation() {
	return client.get(`api/occupations`);
}

export function getInvestorOccupation(investor_id) {
	return client.get(`api/investor/${investor_id}/occupation`);
}

/*************************************** Peruvian States ***************************************/

export function getPeruvianStates() {
	return client.get(`api/peruvian-states`);
}

export function getPeruvianProvinces(state) {
	return client.get(`api/peruvian-states/${state}/provinces`);
}

export function getPeruvianDistricts(state,province) {
	return client.get(`api/peruvian-states/${state}/provinces/${province}/districts`);
}

/*************************************** Economic Activities ***************************************/

export function getEconomicActivities() {
	return client.get(`api/economic-activities`);
}

export function getSourceFunds() {
	return client.get(`api/source-funds`);
}


/*************************************** Investor PEP ***************************************/

export function getInvestorPEPInformation(investor_id, token) {

	if (token) {
		return client.get(`api/investor/${investor_id}/pep`, {
			headers: {
				"Authorization": "Bearer " + token
			}
		});
	}

	return client.get(`api/investor/${investor_id}/pep`);
}

export function storeInvestorPEPInformation(investor_id, data) {
	return client.post(`api/investor/${investor_id}/pep`, { ...data });
}

export function getInvestorPEPCompany(investor_id, pep_id) {
	return client.get(`api/investor/${investor_id}/pep/${pep_id}/companies`);
}

export function storeInvestorPEPCompany(investor_id, pep_id, data) {
	return client.post(`api/investor/${investor_id}/pep/${pep_id}/companies`, { ...data });
}

export function deleteInvestorPEPCompany(investor_id, pep_id, id) {
	return client.delete(`api/investor/${investor_id}/pep/${pep_id}/companies/${id}`);
}

export function getInvestorPepRelative(investor_id, pep_id) {
	return client.get(`api/investor/${investor_id}/pep/${pep_id}/relatives`);
}

export function storeInvestorPepRelative(investor_id, pep_id, data) {
	return client.post(`api/investor/${investor_id}/pep/${pep_id}/relatives`, { ...data });
}

export function deleteInvestorPepRelative(investor_id, pep_id, id) {
	return client.delete(`api/investor/${investor_id}/pep/${pep_id}/relatives/${id}`);
}
/*************************************** Investor ***************************************/

export function getInvestorTransaction(investor_id, type) {
	return client.get(`api/investor/${investor_id}/transactions?${type && `type=${type}`}`);
}

export function getInvestorTransactionPending(investor_id, status) {
	return client.get(`api/investor/${investor_id}/transactions?${status && `status=${status}`}`);
}

export function showInvestorTransaction(investor_id, id) {
	return client.get(`api/investor/${investor_id}/transactions/${id}`);
}

export function storeInvestorTransaction(investor_id, data) {
	return client.post(`api/investor/${investor_id}/transactions`, { ...data });
}

export function listInvestorTransactionLogs(investor_id, id) {
	return client.get(`api/investor/${investor_id}/transactions/${id}/logs`);
}

export function getInvestorTransactionStatus(locale) {
	return client.get(`api/investor-transaction-statuses`);
}

/*************************************** Investor Balance ***************************************/

export function getInvestorGlobalBalances(investor_id) {
	return client.get(`api/investor/${investor_id}/balances/global`);
}

export function getBalances(investor_id) {
	return client.get(`api/investor/${investor_id}/balances`);
}

/*************************************** Investor Questions ***************************************/

export function getQuestions() {
	return client.get(`api/profiler-questions`);
}

/*************************************** Investor Funds ***************************************/

export function storeInvestorProfilerDetermine(data,investor_id) {
	return client.post(`api/investor/${investor_id}/profile/determine`, { ...data });
}


/*************************************** Investor Funds ***************************************/

export function getFunds() {
	return client.get(`api/funds`);
}

export function showFunds(id) {
	return client.get(`api/funds/${id}`);
}

export function getProfileRiskImage(logo) {
	return client.get('api/storage/image', { params: { path: logo } });;
}

/*************************************** Terms ***************************************/
export function getTerms(locale) {
	return client.get(`api/terms/transactions`, { headers: { Locale: locale } });
}

export function getTermsSignIn(locale) {
	return client.get(`api/terms/sign-in`, { headers: { Locale: locale } });
}

export function getDeclaration(locale) {
	return client.get(`api/terms/different-fund`, { headers: { Locale: locale } });
}
/*************************************** Contact ***************************************/
export function getContactInformation(locale) {
	return client.get(`api/flip-contact-information`);
}

/**change password */

export function storeNewPassword(data) {
	return client.post(`/api/users/me/change-password`, { ...data });
}

/*************************************** Administrator Agreement ***************************************/

export function storeAgreement(investor_id) {
	return client.get(`api/investor/${investor_id}/onboarding-completed`);
}

/*************************************** Investor ***************************************
Peruvian States
export function getInvitations(id,page,size,search){

  return client.get(`invitations?user=${id}&page=${page}&size=${size}&search=${search}`);

}

export function getInvitationsById(id){

  return client.get(`invitations/${id}`);

}

export function sendInvitationsEmail(id){

  return client.get(`invitations/${id}/send-email`);

}

export function addInvitation(invitation){

  return client.post('invitations',{...invitation});

}

export function addAllInvitations(user_id,invitations){

  return client.post(`invitations/add-all/${user_id}`,invitations);
  

}

export function updateGuestEmail(id,guest_email){

  return client.patch('invitations/'+id+'/change-guest',{...guest_email});

}

export function updateStatus(id,status){
  
  return client.patch('invitations/'+id+'/change-status',{...status});

}


export function deleteInvitation(id){

  return client.delete('invitations/'+id);

}
*/