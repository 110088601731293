import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from '../../constants/Config';
import { AuthContext } from "../../services/AuthContext";

const IncompleteValidations = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { mystate } = React.useContext(AuthContext);

	useEffect(() => {
		return () => { }
	}, [])

	const goNext = () => {
		navigate('/your-security-is-first');
	}

	const goToCalendly = () => {
		const win = window.open(config.calendlyUrl, '_blank');
		win.focus();

	}

	return (
		<div className="flex flex-col px-6 mt-9" >

			<div className="mt-11 mb-5 ml-2">
				<h1 className="text-3xl font-bold ">{t('incomplete-validations-title')}</h1>
			</div>

			<div className="mb-16">
				<p className="text-gray-500 text-sm ml-2">{t('incomplete-validations-label-description')}</p>
			</div>

			<div className="mb-24 self-center">
				<svg className='object-contain h-32 w-full' viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.9167 0.666504H26.3334V19.9165H19.9167V0.666504Z" fill="#0D4C57" />
					<path d="M71.25 0.666504H77.6667V19.9165H71.25V0.666504Z" fill="#0D4C57" />
					<path d="M58.4167 91.1418C58.4167 90.5002 58.4167 90.5002 58.4167 91.1418L7.08341 90.5002V39.1668H90.5001V46.8668C93.0667 47.5085 94.9917 48.7918 96.9167 50.7168V7.0835H84.0834V26.3335H64.8334V7.0835H32.7501V26.3335H13.5001V7.0835H0.666748V96.9168H58.4167V91.1418Z" fill="#0D4C57" />
					<path d="M96.9167 64.8333C96.9167 71.9237 91.1737 77.6667 84.0833 77.6667C76.9929 77.6667 71.25 71.9237 71.25 64.8333C71.25 57.7429 76.9929 52 84.0833 52C91.1737 52 96.9167 57.7429 96.9167 64.8333Z" fill="#0D4C57" />
					<path d="M89.8582 77.6665H78.3083C71.2499 77.6665 64.8333 83.4415 64.8333 91.1415V103.333H103.333V91.1415C103.333 83.4415 97.5582 77.6665 89.8582 77.6665Z" fill="#0D4C57" />
				</svg>
			</div>



			<div className="mb-8">
				<p className="text-gray-500 text-sm ml-2">{t('incomplete-validations-label-click-here')}</p>
			</div>

			<button onClick={() => goToCalendly()} type="button" >
				{t('incomplete-validations-button-schedule-here')}
			</button>

			<button onClick={() => goNext()} type="button" className='bg-whiteLilac-500 text-asparagus-500  hover:bg-transparent' >
				{t('incomplete-validations-button-go-back')}
			</button>


		</div>
	);
}

export default IncompleteValidations;