import * as React from 'react';
import { GetLoggedUser, getInvestorPEPInformation, showInvestor } from './ApiService';
import { useLocation } from 'react-router-dom';


export const AuthContext = React.createContext();

export const AuthContextInitialState = {
    isLoading: true,
    isSignOut: false,
    userToken: null,
    user: null,
    pep_id: null,
    investor: null,
};

//console.log(AuthContextInitialState);

export function AuthContextReducer(state, action) {


    switch (action.type) {

        case 'RESTORE_TOKEN':
            return {
                ...state,
                userToken: action.token,
                user: action.user,
                pep_id: action.pep_id,
                investor: action.investor,
            };
        case 'SIGN_IN':

            localStorage.setItem('userToken', action.token);

            return {
                ...state,
                userToken: action.token,
                user: action.user,
                pep_id: action.pep_id,
                investor: action.investor,
            };
        case 'UPDATE_INVESTOR':

            //console.log(action.investor);
            return {
                ...state,
                user: action.user,
                investor: action.investor,
            };
        case 'UPDATE_PEP_ID':

            //console.log(action.id);
            return {
                ...state,
                pep_id: action.id,
            };
        case 'SIGN_OUT':

            localStorage.clear();

            return {
                isLoading: true,
                isSignOut: false,
                userToken: null,
                user: null,
                pep_id: null,
            };
        default:
            console.log('context no matched key');
            return state;
    }
};


export function GetTokenAndValidate(dispatch) {

    return new Promise(async (resolve, reject) => {

        let userToken = null;
        let user = null;
        let pep_id = null;
        let investor = null;

        try {

            userToken = localStorage.getItem('userToken');
            //console.log('userToken',userToken)
            if (!(userToken === null)) {
                let apiResponse = await GetLoggedUser();
                user = apiResponse.data;

                if (apiResponse.data.investor_id !== null) {
                    let getInvestorPEPInformationResponse = await getInvestorPEPInformation(user.investor_id);
                    let showInvestorResponse = await showInvestor(user.id, user.investor_id);
                    investor = showInvestorResponse.data;
                    //console.log('investor',investor)
                    //console.log('getInvestorPEPInformationResponse', getInvestorPEPInformationResponse.data)
                    pep_id = Object.keys(getInvestorPEPInformationResponse.data).length === 0 ? null : getInvestorPEPInformationResponse.data.id;
                }
                //console.log('GetLoggedUser', user)

            }


        } catch (e) {

            console.log(e);
            dispatch({
                type: 'SIGN_OUT',

            });

            resolve();
            return;
        }


        dispatch({
            type: 'RESTORE_TOKEN',
            token: userToken,
            user: user,
            pep_id: pep_id,
            investor: investor,
        });

        resolve();
    });

}
