import { LogInRequest } from './ApiService';
import { GetLoggedUser,getInvestorPEPInformation,showInvestor } from './ApiService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../i18n';
const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
  toast: true,
  position: 'bottom',
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  width: '90%',
  didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
  
export async function SignIn(data,dispatch){

    try{

        let loginResponse = await LogInRequest(data);
        let userResponse = await GetLoggedUser(loginResponse.data.token);
        //console.log(userResponse.data)
        let pep_id = null;
        let investor = null;


        if(userResponse.data.investor_id !== null){
            let getInvestorPEPInformationResponse = await getInvestorPEPInformation(userResponse.data.investor_id,loginResponse.data.token);
            let showInvestorResponse = await showInvestor(userResponse.data.id, userResponse.data.investor_id,loginResponse.data.token);
            investor = showInvestorResponse.data;
            //console.log('investor',investor)
            //console.log('getInvestorPEPInformationResponse', getInvestorPEPInformationResponse.data)
            pep_id =  Object.keys(getInvestorPEPInformationResponse.data).length === 0 ? null : getInvestorPEPInformationResponse.data.id;
        }


        //console.log(userResponse.data)
        dispatch({ 
            type: 'SIGN_IN',
            token: loginResponse.data.token,
            user:userResponse.data,
            pep_id: pep_id,
            investor:investor,
        })

        /*Toast.fire({
            icon: 'success',
            title: i18n.t('login-alert-success-sign-in'),
        })*/

        let data2 = {user:{...userResponse.data},investor: investor === null ? null : {...investor} }
        return {...data2};
        
    }catch (error) {
        if(error.response.data.key === "LOGIN_LOCKOUT"){
            throw (error.response);  
        }   

        Toast.fire({
            icon: 'error',
            title: i18n.t('login-alert-error-sign-in'),
        })
        throw (error.response)
    }
}



export function SignOut(dispatch) {

    dispatch({type: 'SIGN_OUT'})
}

