import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FundInformationDescription = ({ fund }) => {
    const { t } = useTranslation();
    const [fundDescriptionTable, setFundDescriptionTable] = useState([]);

    useEffect(() => {
        //console.log(fund);

        let fund_description_table = fund.description.map((item) => {
            let items = item.attributes.rows.map((row) => {
                return { title: row.attributes.title, percentage: row.attributes.percentage }
            })

            return {
                title: item.attributes.title, description: item.attributes.description, percentage: item.attributes.percentage,
                items
            }
        })
        //console.log(fund_description_table);
        setFundDescriptionTable(fund_description_table);
        return () => { }
    }, [])

    const goToLink = (url) => {
        const win = window.open(url, '_blank');
        if (win != null) {
          win.focus();
        }
        //navigate('/subscription/new', { state: { ...state } });
    };

    /*
        const fundDescriptionTable = [
            {
                title: 'Efectivo:', description: 'Oportunidades de inversión', percentage: '5.0%',
                items: [
                    { title: 'Depositos en dólares', percentage: '5.0%' }
                ]
            },
            {
                title: 'Bonos:', description: 'Estabilidad', percentage: '50.0%',
                items: [
                    { title: 'Bonos empresas EEUU', percentage: '15.0 %' },
                    { title: 'Bonos Gobierno EEUU corto plazo', percentage: '15.0 %' },
                    { title: 'Bonos Gobierno EEUU indexados inflación', percentage: '10.0 %' },
                    { title: 'Bonos Gobierno EEUU largo plazo', percentage: '10.0 %' },
                ]
            },
            {
                title: 'Acciones:', description: 'Crecimiento', percentage: '30.0%',
                items: [
                    { title: 'Acciones empresas EEUU', percentage: '3.0 %' },
                ]
            },
            {
                title: 'Diversificadores', description: 'Protección', percentage: '15.0%',
                items: [
                    { title: 'Oro', percentage: '7.5 %' },
                    { title: 'Índice del dólar', percentage: '7.5 %' },
                ]
            },
        ]*/


    return (
        <>
            <p className="mt-0 text-base text-gray-700">{fund.how_it_works}</p>

            <div className="p-2 mb-5 bg-white rounded-lg mt-5 shadow-lg">
                <table className="fund-information-description w-full text-left">
                    <tbody>

                        {fundDescriptionTable.map((row, index) => {

                            return (
                                <React.Fragment key={row.title + '-' + index}>
                                    <tr className={(index % 2 === 0) ? 'fund-information-stripped-row' : ''}>
                                        <td className="pl-2 not-italic font-bold text-sm leading-2 text-sherpaBlue-500">{row.title}</td>
                                        <td className="not-italic font-medium text-sm leading-2 text-gray-600">{row.description}</td>
                                        <td className="not-italic font-bold text-lg leading-2 text-sherpaBlue-500">{row.percentage}</td>
                                    </tr>

                                    {row.items.map((item, index) => {
                                        return (
                                            <tr key={item.title+'_'+index} className={(index % 2 === 0) ? 'fund-information-stripped-row'  : ''}>
                                                <td colSpan="2" className="pl-2 not-italic font-light text-xs leading-2 text-gray-600">{item.title}</td>
                                                <td className="not-italic font-light text-xs leading-2 text-gray-600">{item.percentage}</td>
                                            </tr>
                                        )
                                    })}

                                </React.Fragment>
                            )

                        })}
                    </tbody>
                </table>
            </div>

            {/*<h3 className="mt-8 text-sm font-semibold text-sherpaBlue-500">{t('fund-information-description-label-this-bonus')}</h3>*/}

            <div dangerouslySetInnerHTML={{__html: fund.description_extra_content}}></div>
            
            {fund.links.map((item, index) => {
                return (
                    <h2 key={index} onClick={()=>goToLink(item.attributes.url)} className="text-gray-700 font-normal text-base underline mt-5 cursor-pointer">
                        {item.attributes.linkText}
                    </h2>
                )
            })}

        </>

    );
}

export default FundInformationDescription;