import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';


const Modal = (data) => {
	const { t } = useTranslation();
	const [modalText, setModalText] = useState({ title: '', description: '' });


	useEffect(() => {

		const init = async () => {
			if (data.isFrom === 'register-dni-data') {
				setModalText({ title: 'register-dni-data-modal-title', description: data.modalData.value });
				return;
			}

			if (data.isFrom === 'subscription-new') {
				setModalText({ title: 'register-dni-data-modal-title', description: data.modalData.value });
				return;
			}

			if (data.isFrom === 'send-constancy') {
				setModalText({ title: 'send-constancy-modal-title', description: data.modalData.value });
				return;
			}

			if (data.isFrom === 'do-you-have-related-pep') {
				setModalText({ description: 'do-you-have-related-pep-modal-description' });
				return;
			}

			if (data.isFrom === 'sended-constancy') {
				setModalText({ description: t('sended-constancy-modal-description') });
				return;
			}

			if (data.isFrom === 'validate-identity') {
				setModalText({
					title: 'validate-identity-modal-main-title',
					description: [
						{ image: 'icon-credential-2.svg', title: 'validate-identity-modal-description-title-1', body: 'validate-identity-modal-description-body-1' },
						{ image: 'icon-environment-light.svg', title: 'validate-identity-modal-description-title-2', body: 'validate-identity-modal-description-body-2' },
						{ image: 'icon-prepare-selfie.svg', title: 'validate-identity-modal-description-title-3', body: 'validate-identity-modal-description-body-3' }
					]
				});
				return;
			}

			/* if (data.isFrom === 'register-dni-data') {
				//setModalText({ title: 'register-dni-data-modal-title', description: 'register-dni-data-modal-description' });
				return;
			} */
		}
		init();


		return () => { }
	}, [data.isOpen])

	const closeModal = () => {
		data.modalIsOpen(false);
	}

	return (
		<Transition appear show={data.isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				onClose={closeModal}
			>
				<div className="min-h-screen text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="inline-block h-screen align-bottom"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						{/*modal content starts here */}
						<div className="inline-block w-full px-6 py-4  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
							<div className='grid grid-cols-3 '>
								<div></div>
								<div className=" justify-self-center  cursor-pointer">
									<div className='mt-1 border-2 bg-gray-200 w-8 rounded-lg '></div>
								</div>
								<div className="justify-self-end  cursor-pointer align-self-bottom">
									<svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</div>
							</div>

							{data.isFrom !== 'do-you-have-related-pep' && data.isFrom !== 'sended-constancy' ?
								<Dialog.Title
									as="h3"
									className={data.isFrom === 'send-constancy' ? "text-sm mt-3" : "text-lgleading-6 text-center mb-8 mt-2 text-sherpaBlue-500 font-bold"}
								>
									{t(modalText.title)}
								</Dialog.Title>
								:

								<div className='flex flex-col'>
									<svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14 self-center text-sherpaBlue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
										<path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</div>
							}

							<div className="mt-4">
								<div className='my-5 px-1'>


									{(data.isFrom === 'register-dni-data' || data.isFrom === 'sended-constancy') &&
										<div className='mb-16'>
											{modalText.description}
										</div>
									}

									{data.isFrom === 'subscription-new' &&
										<>
											{modalText.description}
										</>
									}


									{data.isFrom === 'send-constancy' &&
										<div className="grid mt-4 justify-items-center">
											<img
												src="/images/Fmiv.png"
												alt="user-created"
												className="object-contain h-96"
											/>
										</div>

									}

									{data.isFrom === 'do-you-have-related-pep' &&
										<div className="text-center text-gray-700 text-lg">
											{t(modalText.description)}
										</div>
									}


									{data.isFrom === 'validate-identity' &&
										<div className="flex flex-col">

											{modalText.description !== '' &&
												<>
													{modalText.description.map((item, i) => {
														return (
															<div className='flex flex-row' key={i}>
																<div className='flex w-10 my-4'>
																	<img
																		src={`/images/${item.image}`}
																		alt={`${item.image}`}
																		className="flex object-contain h-10 w-full"
																	/>
																</div>

																<div className='ml-2 mb-4 flex-1'>
																	<h3 className='text-base ml-5 text-sherpaBlue-500 font-bold'>
																		{t(item.title)}
																	</h3>
																	<div className='text-base my-1 ml-5'>{t(item.body)}</div>
																</div>

															</div>
														)
													})}
												</>

											}
										</div>

									}
								</div>

								<button
									className={`w-full my-14 text-xs p-3 
									${(data.isFrom === 'register-dni-data' || data.isFrom === 'do-you-have-related-pep' || data.isFrom === 'sended-constancy' || data.isFrom === 'validate-identity') && 'bg-sherpaBlue-500 hover:bg-sherpaBlue-600 mt-0 mb-5 p-4 text-sm  !important'}
									${data.isFrom === 'subscription-new' && 'mb-10 font-thin p-4 text-sm'}
									${data.isFrom === 'send-constancy' && 'mb-10 font-medium p-4'}
									${data.isFrom === 'register-dni-data' && 'mb-10 font-thin'} 
									`}//bg-sherpaBlue-500 hover:bg-sherpaBlue-600
									onClick={closeModal}
								>
									{data.isFrom === 'validate-identity' ?
										<>
											{t('validate-identity-modal-button')}
										</>
										:
										<>
											{t('political-exposed-person-modal-button')}
										</>
									}

								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}

export default Modal;