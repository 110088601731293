import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getInvestorTransaction } from '../../services/ApiService';
import { AuthContext } from '../../services/AuthContext';

const WithdrawalProcess = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mystate } = React.useContext(AuthContext);

    useEffect(() => {
        const init = async () => {
            try {
                /*let ApiResponse = await getInvestorTransaction(mystate.user.investor_id);
                console.log(ApiResponse.data);*/
                console.log(state)
            } catch (error) {
                //console.log(error)
            }
        }
        init();
        return () => { }
    }, [])

    const goNext = () => {
        navigate('/home');
    }

    const goToMovements = () => {
        navigate('/movements/history');
    }

    return (
        <div className="flex flex-col px-6 mt-9" >

            <div className="mt-5 mb-6 ml-2">
                <h1 className="text-3xl font-bold ">{t('withdrawal-process-title')}</h1>
            </div>

            <div className="mb-8">
                <p className="text-gray-500 text-sm ml-2">{t('withdrawal-process-label-description')}</p>
            </div>

            <div className={`mb-20 card-withdrawal-process flex flex-col`}>
                <div className="font-bold text-lg mb-3 text-gray-700">
                    {t('withdrawal-process-label-detail')}
                </div>
                <div className="font-bold text-sm mb-3 text-gray-500">
                    {t('withdrawal-process-label-flip-code')}
                </div>
                <div className="font-bold mb-3 text-gray-700">
                    {state.flip_code}
                </div>
                <div className="text-xs mb-3 font-semibold text-sherpaBlue-500">
                    {t('withdrawal-process-label-use-your-code')}
                </div>
                <div className="font-bold text-sm text-gray-500 mb-2">
                    {t('withdrawal-process-label-amount-of-withdrawal')}
                </div>

                <div className="font-bold text-sm mb-3 text-gray-700">
                    {state.type === "TOTAL_RESCUE" ?
                    <>
                         {t('withdrawal-process-label-total-rescue')}
                    </>
                    :
                    <>
                       {t('withdrawal-process-label-usd')} {Number(state.amount).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                    </>
                    }
                </div>
            </div>

            <button onClick={() => goNext()} className={''}>
                {t('withdrawal-process-button-understood')}
            </button>

            <button onClick={() => goToMovements()} className="bg-whiteLilac-500 text-asparagus-500 mb-12  hover:bg-transparent">
                {t('withdrawal-process-button-go-to-movements')}
            </button>

        </div>
    );
}

export default WithdrawalProcess;