import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../home/Footer';
import i18n from '../../i18n';


const Operations = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [balance, setBalance] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            //console.log(state)
            let balance_needed = state.map((balance) => {
                return {
                    id: balance.fund_id,
                    name: i18n.language === 'es' ? balance.fund.name.es : balance.fund.name.en,
                    current_balance: balance.current_balance,
                    investments: balance.investments,
                    winnings: balance.winnings
                }
            })
            //console.log(balance_needed);
            setBalance(balance_needed);

            /* try {
                 //console.log(type)
                 let ApiResponse = await getInvestorTransaction(mystate.user.investor_id,type);
                 setInvestment(ApiResponse.data)
                 setLoadingComplete(true);
                 console.log(ApiResponse.data)
                 //console.log(new Date(ApiResponse.data[0].created_at).toLocaleDateString())
                 //console.log(new Date(ApiResponse.data[0].created_at));
             } catch (error) {
                 console.log(error);
             }*/
        }

        init();
        return () => { }
    }, []);

    const goBack = () => {
        navigate('/home', { state: { ...state } });
    };

    const goToOperationInvestmentSummary = (fund) => {
        navigate('/operations/investment-summary', { state: { ...fund } });
    }


    return (
        <div className="flex flex-col" >

            <div className="flex mt-10 ml-6">
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => goBack()} className="h-5 cursor-pointer " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </div>

            <div className='flex flex-col px-8'>
                <h1 className="mt-3 mb-14">{t('operations-title')}</h1>
                {state.map((fund, i) => {
                    return (
                        <button key={i} onClick={() => goToOperationInvestmentSummary(fund)} className={`flex px-4 py-4 mb-5 bg-white hover:bg-white text-black card-option-operations`}>
                            
                            <div className='flex flex-col flex-1'>
                                <div className="flex-1 mr-2">
                                    <p className="font-bold text-left text-sm">{i18n.language === 'es' ? fund.fund.name.es : fund.fund.name.en}</p>
                                </div>
                                <hr style={{ borderTop: '1px solid #ffffff' }} className="m-0 w-full border-2  my-3" />

                                <div className="flex flex-row text-xs justify-between mb-1">
                                    <h5>{t('home-has-invested')}</h5>
                                    <h5>{t('home-your-performance')}</h5>
                                </div>


                                <div className="flex flex-row text-xs justify-between">
                                    <h5>USD {fund.investments === 0 ? '0.00' : Number(fund.investments).toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>
                                    <h5>USD {fund.winnings === 0 ? '0.00' : Number(fund.winnings).toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>
                                </div>
                            </div>

                            <div className="self-center ml-3">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-sherpaBlue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                            </div>

                        </button>
                    )
                })
                }

            </div>

            <Footer />

        </div>

    );
}

export default Operations;